/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchOutlined } from '@ant-design/icons'
import {
  EBillStatus,
  IFilterGetExportBill,
} from 'app/api/accounting/model/export-bill'

import R from 'app/assets/R'
import { SvgExcelIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { notificationController } from 'app/controllers/notification-controller'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { Dayjs } from 'dayjs'
import { t } from 'i18next'
import { Dates, FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { ButtonAction } from '../../../styles'
import { AdvanceFilterLayout } from './AdvanceFilter'

interface IProps {
  onChange?: (value: IFilterGetExportBill) => void
  formData?: IFilterGetExportBill
  totalItem?: number
  setIsLoading: Dispatch<SetStateAction<boolean>>
  handleExportExcel: (
    body: IFilterGetExportBill,
    setLoading: Dispatch<SetStateAction<boolean>>,
  ) => Promise<void>
  exportPermission: boolean
}

export const FilterLayout: React.FC<IProps> = ({
  formData,
  onChange,
  handleExportExcel,
  setIsLoading,
  exportPermission,
}) => {
  const onChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    onChange && onChange({ ...formData, keyword: value })
  }

  const onChangeStartDate = (day?: Dayjs) => {
    if (day && formData?.toDate && day.isAfter(formData.toDate)) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.start_date_must_be_before_end_date),
      })
      return
    }

    const value = day ? day?.toISOString() : undefined
    onChange && onChange({ ...formData, fromDate: value })
  }

  const onChangeEndDate = (day?: Dayjs) => {
    if (day && formData?.fromDate && day.isBefore(formData.fromDate)) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.end_date_must_be_after_start_date),
      })
      return
    }

    const value = day ? day?.toISOString() : undefined
    onChange && onChange({ ...formData, toDate: value })
  }

  return (
    <BaseForm>
      <RootWrapper gutter={[16, 8]} align={'middle'}>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BaseInput
              prefix={<SearchOutlined rev={undefined} />}
              value={formData?.keyword}
              placeholder={t(R.strings.enter_keyword)}
              onChange={onChangeKeyword}
              allowClear
            />
          </BaseFormItem>
        </BaseCol>

        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <Picker
              $prefix={t(R.strings.from_date)}
              $prefixWidth={120}
              picker="date"
              value={
                formData?.fromDate ? Dates.getDate(formData?.fromDate) : null
              }
              onChange={day => onChangeStartDate(day as Dayjs)}
              allowClear
              format={'DD-MM-YYYY'}
              placeholder={t(R.strings.all)}
              showTime
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol style={{ padding: 0 }}>{`-`}</BaseCol>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <Picker
              $prefix={t(R.strings.to_date)}
              $prefixWidth={140}
              value={formData?.toDate ? Dates.getDate(formData?.toDate) : null}
              picker="date"
              onChange={day => onChangeEndDate(day as Dayjs)}
              allowClear
              format={'DD-MM-YYYY'}
              placeholder={t(R.strings.all)}
              showTime
            />
          </BaseFormItem>
        </BaseCol>

        <BaseCol
          style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}
        >
          <BaseRow align={'middle'}>
            <BaseCol>
              <AdvanceFilterLayout
                filter={formData}
                onChangeFilter={onChange}
              />
            </BaseCol>
            {exportPermission && (
              <BaseCol>
                <ButtonAction
                  onClick={() =>
                    handleExportExcel(
                      { ...formData, status: EBillStatus?.PENDING },
                      setIsLoading,
                    )
                  }
                >
                  <SvgExcelIcon />
                </ButtonAction>
              </BaseCol>
            )}
          </BaseRow>
        </BaseCol>
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseRow)``

const Picker = styled(BaseDatePicker)<{
  $prefix?: string
  $prefixWidth?: number
}>`
  width: 100%;

  &:hover {
    border: 1px solid ${convertedVariables.neutralBlack9Color};
  }
  .ant-picker-input::before {
    content: '${props => props?.$prefix ?? '-'}:';
    width: ${props => props?.$prefixWidth ?? 40}px;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
  }
  .ant-picker-input > input {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
