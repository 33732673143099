import { BaseButton } from 'app/components/common/BaseButton'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { FONT_SIZE, FONT_WEIGHT, PADDING, colors } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: 0px ${PADDING.md};
`
export const ProfileContainer = styled.div`
  cursor: pointer;
`
export const TextJobTitleInProfileName = styled.div`
  color: ${colors.primary};
  font-weight: ${FONT_WEIGHT.regular};
  font-size: ${FONT_SIZE.xxs};
`
export const Divider = styled(BaseDivider)`
  margin: 12px 0;
`
export const AddButton = styled(BaseButton)`
  background: linear-gradient(90deg, #fd7b59, #f75428);
  gap: 0;
  color: var(--white);
  font-weight: ${FONT_WEIGHT.medium};
  font-size: ${FONT_SIZE.xs};
  &:hover {
    color: var(--white) !important;
  }
  .svg {
    color: var(--white);
  }
`
export const PopoverButton = styled.div`
  color: ${colors.primary};
  font-weight: ${FONT_WEIGHT.medium};
  font-size: ${FONT_SIZE.xxs};
  opacity: 0.7;
  cursor: pointer;

  &:hover {
    color: ${colors.blue};
  }
`
