import { UploadFile } from 'antd'
import { IIndustry } from 'app/api/degree/model/management'
import { requestGetAllDegreeClassificationManagement } from 'app/api/degreeclassification'
import { DegreeClassificationMessageCode } from 'app/api/degreeclassification/constant'
import { IDegreeClassification } from 'app/api/degreeclassification/model/management'
import { requestGetAllIndustryManagement } from 'app/api/industry'
import { IndustryMessageCode } from 'app/api/industry/constant'
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { Dayjs } from 'dayjs'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StatusDegreeType } from '.'
import { IFormDegreeModalData, IFormDegreeModalRule } from './type'

interface IPropsUseModalHook {
  handleSubmit: (values) => boolean
}

export const useModalHook = ({ handleSubmit }: IPropsUseModalHook) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm()
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState<StatusDegreeType | undefined>()
  const [industries, setIndustry] = useState<IIndustry[]>()
  const [degreeClassifications, setDegreeClassifications] =
    useState<IDegreeClassification[]>()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinish = async (values: IFormDegreeModalData[]) => {
    handleSubmit(values)

    form.resetFields()
    setVisible(false)
  }

  const getAllIndustry = async () => {
    try {
      const res: ResponseType<IIndustry[]> =
        await requestGetAllIndustryManagement()

      if (
        isEqual(
          res?.msgcode,
          IndustryMessageCode.IndustryManagement.successGetData,
        )
      ) {
        const data: IIndustry[] = res?.data || []
        setIndustry(data)
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  const getAllDegreeClassification = async () => {
    try {
      const res: ResponseType<IDegreeClassification[]> =
        await requestGetAllDegreeClassificationManagement()

      if (
        isEqual(
          res?.msgcode,
          DegreeClassificationMessageCode.DegreeClassificationManagement
            .successGetData,
        )
      ) {
        const data: IDegreeClassification[] = res?.data || []
        setDegreeClassifications(data)
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  const onChangeStartDate = (day?: Dayjs) => {
    if (
      day &&
      form?.getFieldValue('end') &&
      day.isAfter(form?.getFieldValue('end'))
    ) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.start_date_must_be_before_end_date),
      })
      return
    }

    form?.setFieldValue('start', day)
  }

  const onChangeEndDate = (day?: Dayjs) => {
    if (
      day &&
      form?.getFieldValue('start') &&
      day.isBefore(form?.getFieldValue('start'))
    ) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.end_date_must_be_after_start_date),
      })
      return
    }

    form?.setFieldValue('end', day)
  }

  const onSetImage = (document: UploadFile) => {
    form?.setFieldValue('document', document)
  }

  useEffect(() => {
    visible && getAllIndustry()
    visible && getAllDegreeClassification()
  }, [visible])

  const rules: IFormDegreeModalRule = useMemo(() => {
    return {
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.degree_field_name),
          }),
        },
      ],
      degree: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.degree_field_degree),
          }),
        },
      ],
      degree_classification: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.degree_field_degree_classification),
          }),
        },
      ],
      start: [
        {
          required: true,
          message: t(R.strings.choose_date),
        },
      ],
      end: [],
      document: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.document),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    showModal,
    onFinish,
    handleCancel,
    rules,
    setStatus,
    status,
    industries,
    degreeClassifications,
    onChangeStartDate,
    onChangeEndDate,
    onSetImage,
  }
}
