import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseTableReport } from '../../styles'
import { FilterLayout } from './Filter'
import { useTable } from './hook'
import { IFilterGetExportBill } from 'app/api/accounting/model/export-bill'
import { Dispatch, SetStateAction } from 'react'
interface IProps {
  reloadTable: number
  setReloadTable: React.Dispatch<React.SetStateAction<number>>
  handleExportExcel: (
    body: IFilterGetExportBill,
    setLoading: Dispatch<SetStateAction<boolean>>,
  ) => Promise<void>
  exportPermission: boolean
}

export const WaitingForExport = (props: IProps) => {
  const {
    filter,
    handleChangeFilter,
    data,
    columns,
    isLoading,
    isLoadingTable,
    handleTableChange,
    pagination,
    setIsLoading,
  } = useTable({
    reloadTable: props?.reloadTable,
    setReloadTable: props?.setReloadTable,
  })

  return (
    <BaseSpace>
      <FilterLayout
        formData={filter}
        onChange={handleChangeFilter}
        handleExportExcel={props?.handleExportExcel}
        setIsLoading={setIsLoading}
        exportPermission={props?.exportPermission}
      />

      <BaseTableReport
        columns={columns}
        dataSource={data}
        pagination={pagination}
        loading={isLoading || isLoadingTable}
        onChange={handleTableChange}
        rowClassName="row-overwrite-style"
        scroll={{
          x: true,
        }}
      />
    </BaseSpace>
  )
}
