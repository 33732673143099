/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dayjs } from 'dayjs'
import { useCreateCampaignContext } from '../../context'
import { isEqual } from 'lodash'
import { usePagination } from 'app/hook'
import { useGetCampaignGroupAll } from 'app/react-query/hook/campaign'
import { useMemo } from 'react'
import { ResponseType } from 'parkway-web-common'
import { ICampaignGroup } from 'app/api/marketing/discount-campaign/model'

export const useHook = () => {
  const {
    areaList,
    isLoadingArea,
    filterTreeNode,
    keywordSearchArea,
    setKeywordSearchArea,
    onChangeClinic,
    selectedClinic,
    campaignType,
    onChangeCampaignType,
    onChangeNameInfo,
    info,
    setIsApplyWithOtherCampaign,
    isApplyWithOtherCampaign,
    stackedWithCampaigns,
    campaignApplyCommon,
    onChangeCampaignApplyCommon,
    onChangeStackedWithCampaigns,
    onChangeApplyPaymentDiscount,
    onChangeApplyWithOtherCampaign,
    isApplyPaymentDiscount,
  } = useCreateCampaignContext()

  const { flattenDataList } = usePagination()

  const { data: campaignGroupAllData, isLoading: loadingCampaignGroupAll } =
    useGetCampaignGroupAll()

  const campaignGroupAll = useMemo(() => {
    const res: ResponseType<ICampaignGroup[]> =
      flattenDataList(campaignGroupAllData)

    return res?.data
  }, [campaignGroupAllData])

  const onChangeName = (name?: string) => {
    onChangeNameInfo?.(name, 'name')
  }

  const onChangeStart = (start?: Dayjs | null) => {
    onChangeNameInfo?.(start, 'startDate')
  }

  const onChangeEnd = (end?: Dayjs | null) => {
    onChangeNameInfo?.(end, 'endDate')
  }

  const onDeleteStackedWithCampaign = (id?: string) => {
    const newStackedWithCampaigns =
      stackedWithCampaigns?.filter(item => !isEqual(item?._id, id)) ?? []
    onChangeStackedWithCampaigns?.(newStackedWithCampaigns)
  }

  return {
    areaList,
    isLoadingArea,
    filterTreeNode,
    keywordSearchArea,
    setKeywordSearchArea,
    onChangeClinic,
    selectedClinic,
    campaignType,
    onChangeCampaignType,
    onChangeNameInfo,
    info,
    setIsApplyWithOtherCampaign,
    isApplyWithOtherCampaign,
    onChangeName,
    onChangeStart,
    onChangeEnd,
    stackedWithCampaigns,
    campaignApplyCommon,
    onChangeCampaignApplyCommon,
    onDeleteStackedWithCampaign,
    campaignGroupAll,
    loadingCampaignGroupAll,
    onChangeApplyPaymentDiscount,
    onChangeApplyWithOtherCampaign,
    isApplyPaymentDiscount,
  }
}
