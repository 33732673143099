import { ApiClientTreatment } from 'app/service/api-service'
import { ItemEndPoint } from './constant'
import {
  IParamGetItemPrices,
  IPayloadCreateItemPrice,
  IPayloadUpdateItemPrice,
  IResponseCreateItemPrice,
  IResponseGetItemPrice,
  IResponseGetItemPrices,
} from './model'

export const requestGetItemPricesManagement = (
  params: IParamGetItemPrices,
): Promise<IResponseGetItemPrices> => {
  return ApiClientTreatment.Get({
    url: ItemEndPoint.ItemPriceManagement.request,
    params: { params },
  })
}

export const requestGetItemPriceById = (
  id: string,
): Promise<IResponseGetItemPrice> =>
  ApiClientTreatment.Get({
    url: `${ItemEndPoint.ItemPriceManagement.request}/${id}`,
    params: {},
  })

export const requestCreateItemPrice = (
  body: IPayloadCreateItemPrice,
): Promise<IResponseCreateItemPrice> =>
  ApiClientTreatment.Post({
    url: `${ItemEndPoint.ItemPriceManagement.request}`,
    body,
  })

export const requestUpdateItemPriceById = (
  id: string,
  body: IPayloadUpdateItemPrice,
): Promise<IResponseGetItemPrice> =>
  ApiClientTreatment.Put({
    url: `${ItemEndPoint.ItemPriceManagement.request}/${id}`,
    body,
  })

export const requestGetItemPriceTemplate = () =>
  ApiClientTreatment.Get({
    url: `${ItemEndPoint.ItemPriceManagement.request}/template`,
    params: {},
  })

export const requestItemPriceImport = (body: FormData) =>
  ApiClientTreatment.Post({
    url: `${ItemEndPoint.ItemPriceManagement.request}/import`,
    body,
  })

export const requestItemPriceExport = (id: string) =>
  ApiClientTreatment.Get({
    url: `${ItemEndPoint.ItemPriceManagement.request}/${id}/export`,
    params: {},
  })

export const requestDeleteItemPrice = (id: string) =>
  ApiClientTreatment.Delete({
    url: `${ItemEndPoint.ItemPriceManagement.request}/${id}`,
    body: {},
  })
