import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { IProfileUser } from 'app/api/profile/model/profile'
import { useTranslation } from 'react-i18next'
import {
  Divider,
  ManagerLabel,
  SectionBaseWrapper,
  SectionDescription,
  SectionTitle,
} from './styles'
import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { getJobTitleName } from 'app/utils/getJobTitleName'

interface IProps {
  profile?: IProfileUser
}

export const DirectManager = (props: IProps) => {
  const { profile } = props

  const officeManager = profile?.administrativeManager
  const techManager = profile?.professionalManager
  const responseManager = profile?.responsibleManager
  const { t } = useTranslation()

  return (
    <SectionBaseWrapper>
      <SectionTitle>
        {t(R.strings.job_title_content_job_title_management)}
      </SectionTitle>
      <SectionDescription>
        {t(R.strings.direct_manager_description)}
      </SectionDescription>
      <Divider />
      <BaseRow>
        <BaseCol xl={8}>
          <ManagerLabel>{t(R.strings.office_manager)}</ManagerLabel>
          <BaseAvatarProfile
            name={officeManager?.name}
            jobTitle={getJobTitleName(officeManager?.workinfos)}
            src={officeManager?.avatar}
          />
        </BaseCol>
        <BaseCol xl={8}>
          <ManagerLabel>{t(R.strings.tech_manager)}</ManagerLabel>
          <BaseAvatarProfile
            name={techManager?.name}
            jobTitle={getJobTitleName(techManager?.workinfos)}
            src={techManager?.avatar}
          />
        </BaseCol>
        <BaseCol xl={8}>
          <ManagerLabel>{t(R.strings.responsibility_manager)}</ManagerLabel>
          <BaseAvatarProfile
            name={responseManager?.name}
            jobTitle={getJobTitleName(responseManager?.workinfos)}
            src={responseManager?.avatar}
          />
        </BaseCol>
      </BaseRow>
    </SectionBaseWrapper>
  )
}
