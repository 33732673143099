import { useHookTable } from './hook'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import R from 'app/assets/R'
import { FilterLayout } from './layouts'
import styled from 'styled-components'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS } from 'parkway-web-common'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useEffect } from 'react'

const PricingManagementContainer = () => {
  const {
    data,
    columns,
    pagination,
    loading: isLoading,
    handleTableChange,
    filter,
    onChangeFilter,
  } = useHookTable()

  const { user, isHavePermissionFunctionAndGoBack } =
    useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.LIST_ITEM_PRICE_READ,
      R.strings.list_item_price,
    )
  }, [user])

  return (
    <S.BaseManagementWrapper>
      <RootWrapper size={7}>
        <FilterLayout filter={filter} onChangeFilter={onChangeFilter} />
        <BaseTableWrapper
          columns={columns}
          dataSource={data}
          pagination={pagination}
          loading={isLoading}
          onChange={handleTableChange}
          rowClassName="row-overwrite-style"
        />
      </RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default PricingManagementContainer

const RootWrapper = styled(BaseSpace)`
  // background-color: ${convertedVariables.backgroundColor};
  // border-radius: ${BORDER_RADIUS};
`
const BaseTableWrapper = styled(BaseTableManagement)`
  th.ant-table-cell.header-column-center {
    text-align: center !important;
  }

  th.ant-table-cell.header-column-right {
    text-align: right !important;
  }

  th.ant-table-cell.header-status {
    padding-right: 50px;
  }

  tr.ant-table-expanded-row > td {
    background: ${convertedVariables.backgroundColor};
  }

  tr.ant-table-expanded-row:hover > td {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell {
    border: 0px !important;
    border-bottom: 0.5px solid ${convertedVariables.neutralBlack2Color} !important;
  }

  .all-column:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell-row-hover:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor} !important;
  }

  .ant-table-tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed
    > .ant-table-wrapper
    .ant-table {
    margin-block: -16px;
    margin-inline: 32px -16px;
  }

  .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-left-radius: 0px !important;
  }

  .ant-table-thead > tr > th {
    color: rgba(0, 34, 68, 0.7) !important;
    font-weight: 600 !important;
  }

  .small-column {
    min-width: 10px !important;
  }
`
