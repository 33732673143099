import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import CreateItemPriceContainer from 'app/containers/Marketing/PricingManagement/CreateItemPrice'

const CreateItemPricePageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.add_item_price)}</PageTitle>
      <CreateItemPriceContainer />
    </>
  )
}
const CreateItemPricePage = memo(CreateItemPricePageCpn, isEqual)

export default CreateItemPricePage
