import * as S from './styles'
import { IFormWorkInfoAkaModalData } from './WorkInfoAkaModal/type'
import WorkInfoAkaItem from './WorkInfoAkaItem'
import { IJobTitle } from 'app/api/jobtitle/model/job-title-management'
import { groupBy } from 'lodash'
import { IUnit } from 'app/model/unit.model'

interface IProps {
  jobTitleOptions: IJobTitle[]
  workInfo?: IFormWorkInfoAkaModalData[]
  onRemove: (id: string) => void
  onAdd: (values: IFormWorkInfoAkaModalData) => void
  unitOptions?: IUnit[]
}

export const WorkInfoAkaList = ({
  workInfo,
  jobTitleOptions,
  onRemove,
  onAdd,
  unitOptions,
}: IProps) => {
  return (
    <S.WorkInfoAkaWrapper>
      {Object.entries(groupBy(workInfo, 'jobtitleid')).map(
        ([jobtitleid, items], index) => {
          const jobTitleName =
            jobTitleOptions?.find(
              jobTitleOption => jobTitleOption._id === jobtitleid,
            )?.name || ''

          return (
            <WorkInfoAkaItem
              data={items}
              jobTitleName={jobTitleName}
              unitOptions={unitOptions}
              key={index}
              index={index}
              onRemove={onRemove}
              onAdd={onAdd}
            />
          )
        },
      )}
    </S.WorkInfoAkaWrapper>
  )
}
