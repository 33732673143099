import {
  requestCreateCampaign,
  requestDeActivatedCampaign,
  requestFindCampaign,
  requestGetDiscountCampaign,
  requestGetDiscountCampaignAvailableSelect,
  requestGetDiscountCampaignGroup,
  requestGetDiscountCampaignGroupAll,
  requestUpdateCampaign,
  requestUpdateEndDateWithInsertOnlyCampaign,
  requestUpdateStackedWithInsertOnlyCampaign,
  requestUpdateWithInsertOnlyCampaign,
} from 'app/api/marketing/discount-campaign'
import { DiscountCampaignMessageCode } from 'app/api/marketing/discount-campaign/constant'
import {
  ICampaignGroup,
  IDiscountCampaign,
  IParamsGetDiscountCampaign,
  IParamsGetFindCampaign,
  IPayloadCreateCampaign,
  IPayloadUpdateCampaign,
  IPayloadUpdateEndDateInsertOnly,
  IPayloadUpdateWithInsertOnly,
} from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { _DEV_ } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { usePagination } from 'app/hook'
import { getToken } from 'app/service/storage/storage-service'
import { t } from 'i18next'
import { isEmpty, isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { useNavigate } from 'react-router'
import { CampaignKeys } from '../query-key/campaign'

export function useGetCampaignList(
  params?: IParamsGetDiscountCampaign,
  enabled = true,
) {
  const token = getToken()
  const { getNextPageParam } = usePagination()
  return useInfiniteQuery<ResponseType<IDiscountCampaign[]>, undefined>(
    CampaignKeys.listCampaigns(params),
    async data => {
      const res: ResponseType<IDiscountCampaign[]> =
        await requestGetDiscountCampaign({
          ...params,
          ...data?.pageParam,
        })

      if (
        isEqual(
          res?.msgcode,
          DiscountCampaignMessageCode.DiscountCampaign.successGetData,
        )
      ) {
        return res
      }

      return Promise.reject(res)
    },
    {
      enabled: !!token && enabled,
      getNextPageParam,
    },
  )
}

export function useGetCampaignListAvailableSelect(
  params?: IParamsGetDiscountCampaign,
  enabled = true,
) {
  const token = getToken()
  const { getNextPageParam } = usePagination()
  return useInfiniteQuery<ResponseType<IDiscountCampaign[]>, undefined>(
    CampaignKeys.listCampaigns(params),
    async data => {
      const res: ResponseType<IDiscountCampaign[]> =
        await requestGetDiscountCampaignAvailableSelect({
          ...params,
          ...data?.pageParam,
        })

      if (
        isEqual(
          res?.msgcode,
          DiscountCampaignMessageCode.DiscountCampaign.successGetData,
        )
      ) {
        return res
      }

      return Promise.reject(res)
    },
    {
      enabled: !!token && enabled,
      getNextPageParam,
    },
  )
}

export function useGetCampaignGroupList(
  params?: IParamsGetDiscountCampaign,
  enabled = true,
  campaignAvailable?: {
    params: IParamsGetDiscountCampaign
    isFetch: boolean
  },
) {
  const token = getToken()
  const { getNextPageParam } = usePagination()
  return useInfiniteQuery<ResponseType<ICampaignGroup[]>, undefined>(
    CampaignKeys.listCampaignGroups(params),
    async data => {
      const res: ResponseType<ICampaignGroup[]> =
        await requestGetDiscountCampaignGroup({
          ...params,
          ...data?.pageParam,
        })

      if (
        isEqual(
          res?.msgcode,
          DiscountCampaignMessageCode.DiscountCampaign.successGetData,
        )
      ) {
        if (!campaignAvailable?.isFetch) return res

        const responseCampaignAvailable: ResponseType<IDiscountCampaign[]>[] =
          await Promise.all(
            res?.data?.map(item =>
              requestGetDiscountCampaignAvailableSelect({
                ...campaignAvailable.params,
                campaignGroupCode: item?.code,
              }),
            ),
          )

        return {
          ...res,
          data: res?.data?.map((item, index) => {
            const listCampaigns = responseCampaignAvailable?.[index]?.data ?? []
            return {
              ...item,
              totalCampaign: listCampaigns?.length,
              campaignAvailable: listCampaigns,
            }
          }),
        }
      }

      return Promise.reject(res)
    },
    {
      enabled: !!token && enabled,
      getNextPageParam,
    },
  )
}

export function useGetCampaignGroupAll(enabled = true) {
  const token = getToken()
  return useInfiniteQuery<ResponseType<ICampaignGroup[]>, undefined>(
    CampaignKeys.listCampaignGroup(),
    async () => {
      const res: ResponseType<ICampaignGroup[]> =
        await requestGetDiscountCampaignGroupAll()

      if (
        isEqual(
          res?.msgcode,
          DiscountCampaignMessageCode.DiscountCampaign.successGetData,
        )
      ) {
        return res
      }

      return Promise.reject(res)
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export const useDeActivatedDiscountCampaign = () => {
  const query = useQueryClient()
  return useMutation<ResponseType<unknown>, unknown, { id: string }, unknown>(
    async (body: { id: string }) => {
      return await requestDeActivatedCampaign(body?.id)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: CampaignKeys.allCampaigns,
        })
      },
    },
  )
}

export const useUpdateDiscountCampaign = () => {
  const query = useQueryClient()
  const navigate = useNavigate()
  return useMutation<unknown, unknown, IPayloadUpdateCampaign, unknown>(
    async (body: IPayloadUpdateCampaign) => {
      await requestUpdateCampaign(body)
      notificationController?.success({
        message: t(R.strings.update_campaign_success),
      })
      navigate(-1)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: CampaignKeys.allCampaigns,
        })
      },
    },
  )
}

export const useUpdateWithInsertOnlyDiscountCampaign = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadUpdateWithInsertOnly, unknown>(
    async (body: IPayloadUpdateWithInsertOnly) => {
      await requestUpdateWithInsertOnlyCampaign(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: CampaignKeys.allCampaigns,
        })
      },
    },
  )
}

export const useUpdateStackedWithInsertOnlyDiscountCampaign = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadUpdateWithInsertOnly, unknown>(
    async (body: IPayloadUpdateWithInsertOnly) => {
      await requestUpdateStackedWithInsertOnlyCampaign(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: CampaignKeys.allCampaigns,
        })
      },
    },
  )
}

export const useUpdateEndDateWithInsertOnlyCampaign = () => {
  const query = useQueryClient()
  return useMutation<
    unknown,
    unknown,
    IPayloadUpdateEndDateInsertOnly,
    unknown
  >(
    async (body: IPayloadUpdateEndDateInsertOnly) => {
      await requestUpdateEndDateWithInsertOnlyCampaign(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: CampaignKeys.allCampaigns,
        })
      },
    },
  )
}

export const useFinishDiscountCampaign = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadUpdateCampaign, unknown>(
    async (body: IPayloadUpdateCampaign) => {
      return await requestUpdateCampaign(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: CampaignKeys.allCampaigns,
        })
      },
    },
  )
}

export const useCreateDiscountCampaign = () => {
  const query = useQueryClient()
  const navigate = useNavigate()
  return useMutation<unknown, unknown, IPayloadCreateCampaign, unknown>(
    async (body: IPayloadCreateCampaign) => {
      try {
        await requestCreateCampaign(body)
        notificationController?.success({
          message: t(R.strings.create_campaign_success),
        })
        navigate(-1)
      } catch (error) {
        _DEV_ && console.log('error', error)
      }
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: CampaignKeys.allCampaigns,
        })
      },
    },
  )
}

export function useGetFindCampaignList(
  params?: IParamsGetFindCampaign,
  enabled = true,
) {
  const token = getToken()
  return useQuery<ResponseType<IDiscountCampaign> | undefined, undefined>(
    CampaignKeys.listFindCampaigns(params),
    async () => {
      if (isEmpty(params?.code)) return undefined

      const res: ResponseType<IDiscountCampaign> = await requestFindCampaign(
        params,
      )

      if (
        isEqual(
          res?.msgcode,
          DiscountCampaignMessageCode.DiscountCampaign.findCampaignSuccess,
        )
      ) {
        return res
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
