import { IProfileUser } from 'app/api/profile/model/profile'
import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { StatusOptionsCreateEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { Option } from 'app/components/common/selects/BaseSelect'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { GenderList } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { Dayjs } from 'dayjs'
import { isEqual } from 'lodash'
import { Dates } from 'parkway-web-common'
import { useEffect, useState } from 'react'
import { Label, WorkInfoAkaList } from '../components'
import { DegreeList } from '../components/Degree'
import { IFormDegreeModalData as IFormDegreeModalDataEdit } from '../components/Degree/DegreeItem/DegreeEditModal/type'
import { DegreeModal } from '../components/Degree/DegreeModal'
import { IFormDegreeModalData } from '../components/Degree/DegreeModal/type'
import { Skill } from '../components/Skill'
import { SkillModal } from '../components/Skill/SkillModal'
import { IFormSkillModalData } from '../components/Skill/SkillModal/type'
import UploadAvatar from '../components/UploadAvatar'
import { WorkInfoList } from '../components/WorkInfo'
import { WorkInfoModal } from '../components/WorkInfo/WorkInfoModal'
import { IFormWorkInfoModalData } from '../components/WorkInfo/WorkInfoModal/type'
import { WorkInfoAkaModal } from '../components/WorkInfoAka/WorkInfoAkaModal'
import { IFormWorkInfoAkaModalData } from '../components/WorkInfoAka/WorkInfoAkaModal/type'
import { convertToOptionActionAccount } from '../constant'
import { Divider } from '../styles'
import { ActionAccountEnum } from '../type'
import { DirectManager } from './DirectManager'
import { useModalHook } from './hook'
import {
  BaseActionWrapper,
  BaseFormWrapper,
  BaseSelectWrapper,
  BaseTitle,
  ButtonActionGroup,
  ButtonCancel,
  ButtonSubmit,
  CollapseWrapper,
  FormInput,
  FormItem,
  LeftCol,
  Picker,
  SelectBaseStyled,
  SelectSearchBaseStyled,
} from './styles'
import { IFormData } from './type'

export const initValues: IFormData = {
  name: '',
  _id: '',
  workinfos: [],
  skills: [],
}

enum StatusUnitOptionsEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
}

export type StatusUnitType = keyof typeof StatusUnitOptionsEnum

export interface IProfileProps {
  type: 'create' | 'update'
  managedProfiles?: IProfileUser[]
}
export const CreateOrUpdateContainer = (props: IProfileProps) => {
  const {
    form,
    t,
    handleSubmit,
    handleCancel,
    rules,
    jobTitles,
    units,
    accounts,
    skills: skillOptions,
    industries: industryOptions,
    levelSkills: levelSkillOptions,
    degrees: degreeOptions,
    degreeClassifications: degreeClassificationOptions,
    onChangeDob,
    onChangeReferralSource,
    managedProfiles,
  } = useModalHook(props)

  const accountAction =
    form.getFieldValue('action_account') === ActionAccountEnum.select_id
  const [isShowSelectAccount, setIsShowSelectAccount] =
    useState<boolean>(accountAction)

  useEffect(() => {
    setIsShowSelectAccount(accountAction)
  }, [accountAction])

  const optionAccounts = accounts.map(account => ({
    value: account._id,
    label: `${account.code ? `[${account.code}] ` : ''}${account.name}`,
  }))

  const handleAddSkill = (values: IFormSkillModalData) => {
    const skillField = form.getFieldValue('skills') ?? []
    const foundSkill = skillField?.find(
      skill =>
        skill.industry === values.industry && skill.skill === values.skill,
    )

    if (foundSkill) {
      notificationController.error({
        message: t(R.strings.skill_content_add),
        description: t(R.strings.skill_content_exists_skill),
      })

      return false
    }

    const skillPushed = [...skillField, values]

    form.setFieldValue('skills', skillPushed)

    return true
  }

  const handleRemoveSkill = key => {
    const skillField = form.getFieldValue('skills') ?? []
    const skillFiltered = skillField.filter((skill, index) => index !== key)

    form.setFieldValue('skills', skillFiltered)
  }

  const handleActionAccountOnChange = key => {
    switch (key) {
      case ActionAccountEnum.select_id:
        setIsShowSelectAccount(true)
        break
      default:
        setIsShowSelectAccount(false)
        break
    }
  }

  const handleAddDegree = (values: IFormDegreeModalData) => {
    const degreeField = form.getFieldValue('degrees') ?? []
    const degreePushed = [...degreeField, values]
    form.setFieldValue('degrees', degreePushed)
    return true
  }

  const handleEditDegree = (values: IFormDegreeModalDataEdit) => {
    form.setFieldValue('degrees', [values])
  }

  const handleRemoveDegree = key => {
    const degreeField = form.getFieldValue('degrees') ?? []
    const degreeFiltered = degreeField.filter((degree, index) => index !== key)
    form.setFieldValue('degrees', degreeFiltered)
  }

  const handleAddWorkInfo = (values: IFormWorkInfoModalData) => {
    const jobTitleField = form.getFieldValue('workinfos') ?? []
    const jobTitlePushed = [...jobTitleField, values]
    form.setFieldValue('workinfos', jobTitlePushed)
  }

  const handleEditWorkInfo = (values: IFormWorkInfoModalData) => {
    form.setFieldValue('workinfos', [values])
  }

  const handleRemoveWorkInfo = (id: string) => {
    const jobTitleField = form.getFieldValue('workinfos') ?? []
    const jobTitleFiltered = jobTitleField.filter(
      item => item?.jobtitleid !== id,
    )
    form.setFieldValue('workinfos', jobTitleFiltered)
  }

  const handleAddWorkInfoAka = (values: IFormWorkInfoAkaModalData) => {
    const jobTitleField = form.getFieldValue('workinfoakas') ?? []
    const jobTitlePushed = [...jobTitleField, values]
    form.setFieldValue('workinfoakas', jobTitlePushed)
  }

  const handleRemoveWorkInfoAka = (id: string) => {
    const jobTitleField = form.getFieldValue('workinfoakas') ?? []
    const jobTitleFiltered = jobTitleField.filter(
      item => item?.jobtitleid !== id,
    )
    form.setFieldValue('workinfoakas', jobTitleFiltered)
  }

  const handleUpdate = key => {
    const degreeField = form.getFieldValue('degrees') ?? []
    const foundDegree = degreeField.find((degree, index) => index === key)

    return foundDegree
  }

  const handleAvatar = (image: string) => {
    form.setFieldValue('avatar', image)
  }

  return (
    <BaseActionWrapper>
      <HeaderPage titleI18nKey={R.strings.profile_content_add} />
      <BaseFormWrapper
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark="optional"
        initialValues={initValues}
        form={form}
      >
        <CollapseWrapper
          defaultActiveKey={['personal_info']}
          items={[
            {
              key: 'personal_info',
              label: <BaseTitle>{t(R.strings.personal_info)}</BaseTitle>,
              style: {
                alignItems: 'center',
              },
              children: (
                <BaseSpace>
                  <BaseRow gutter={20} align={'top'}>
                    <LeftCol xl={6}>
                      <Label
                        title={t(R.strings.avatar)}
                        description={t(R.strings.avatar_description)}
                        require
                      />
                    </LeftCol>
                    <BaseCol xl={18}>
                      <FormItem name="avatar" hiddenLabel>
                        <UploadAvatar
                          image={form?.getFieldValue('avatar')}
                          onSetAvatar={handleAvatar}
                        />
                      </FormItem>
                    </BaseCol>
                  </BaseRow>
                  <Divider />
                  <BaseRow gutter={20} align={'top'}>
                    <LeftCol xl={6}>
                      <Label
                        title={t(R.strings.personal_info)}
                        description={t(
                          R.strings.personal_info_description_update,
                        )}
                      />
                    </LeftCol>
                    <BaseCol xl={18}>
                      <BaseRow
                        gutter={20}
                        align={'top'}
                        justify={'space-between'}
                      >
                        <BaseCol xl={12}>
                          <FormItem
                            name="lastname"
                            label={t(R.strings.profile_content_lastname)}
                            rules={rules.lastname}
                            required
                          >
                            <FormInput
                              placeholder={t(R.strings.enter_field, {
                                field: t(R.strings.profile_content_lastname),
                              })}
                            />
                          </FormItem>
                        </BaseCol>
                        <BaseCol xl={12}>
                          <FormItem
                            name="firstname"
                            label={t(R.strings.profile_content_firstname)}
                            rules={rules.firstname}
                            required
                          >
                            <FormInput
                              placeholder={t(R.strings.enter_field, {
                                field: t(R.strings.profile_content_firstname),
                              })}
                            />
                          </FormItem>
                        </BaseCol>
                      </BaseRow>
                      <BaseRow
                        gutter={20}
                        align={'top'}
                        justify={'space-between'}
                      >
                        <BaseCol xl={12}>
                          <FormItem
                            name="gender"
                            label={t(R.strings.gender)}
                            rules={rules.gender}
                            required
                          >
                            <BaseSelectWrapper
                              placeholder={t(R.strings.choose)}
                              allowClear
                              onChange={id =>
                                onChangeReferralSource?.(id as string)
                              }
                              options={GenderList?.map(item => ({
                                value: item.id,
                                label: t(item.value),
                              }))}
                              maxTagCount={0}
                              filterSort={() => 0}
                            />
                          </FormItem>
                        </BaseCol>
                        <BaseCol xl={12}>
                          <FormItem
                            name="dob"
                            label={t(R.strings.birthday)}
                            rules={rules.dob}
                            required
                          >
                            <Picker
                              picker="date"
                              value={
                                form?.getFieldValue('dob')
                                  ? Dates.getDate(form?.getFieldValue('dob'))
                                  : null
                              }
                              onChange={day => onChangeDob(day as Dayjs)}
                              format={'DD-MM-YYYY'}
                              placeholder={t(R.strings.choose_date)}
                            />
                          </FormItem>
                        </BaseCol>
                      </BaseRow>
                      <BaseRow
                        gutter={20}
                        align={'top'}
                        justify={'space-between'}
                      >
                        <BaseCol xl={12}>
                          <FormItem
                            label={t(R.strings.account)}
                            name="action_account"
                            rules={rules.action_account}
                          >
                            <SelectBaseStyled
                              placeholder={t(
                                R.strings.profile_content_please_select_account,
                              )}
                              suffixIcon={<SvgTableCloseIcon />}
                              onChange={handleActionAccountOnChange}
                            >
                              {Object.values(ActionAccountEnum)?.map(
                                (item, index) => {
                                  return (
                                    <Option value={item} key={index}>
                                      {convertToOptionActionAccount(item)}
                                    </Option>
                                  )
                                },
                              )}
                            </SelectBaseStyled>
                          </FormItem>
                        </BaseCol>
                        {isShowSelectAccount && (
                          <BaseCol xl={12}>
                            <FormItem name="accountid">
                              <SelectSearchBaseStyled
                                placeholder={t(R.strings.choose)}
                                suffixIcon={<SvgTableCloseIcon />}
                                options={optionAccounts}
                              />
                            </FormItem>
                          </BaseCol>
                        )}
                        {!isShowSelectAccount && (
                          <BaseCol xl={12}>
                            <FormItem>
                              <FormInput disabled={true} />
                            </FormItem>
                          </BaseCol>
                        )}
                      </BaseRow>
                      <BaseRow
                        gutter={20}
                        align={'top'}
                        justify={'space-between'}
                      >
                        <LeftCol xl={6}>
                          <FormItem
                            name="employeeOldId"
                            label={t(R.strings.profile_content_employee_old_id)}
                          >
                            <FormInput
                              placeholder={t(R.strings.enter_field, {
                                field: t(
                                  R.strings.profile_content_employee_old_id,
                                ),
                              })}
                            />
                          </FormItem>
                        </LeftCol>
                        <LeftCol xl={6}>
                          <FormItem
                            name="employeeid"
                            label={t(R.strings.profile_content_employeeid)}
                            rules={rules.employeeid}
                            required
                          >
                            <FormInput
                              placeholder={t(R.strings.enter_field, {
                                field: t(R.strings.profile_content_employeeid),
                              })}
                            />
                          </FormItem>
                        </LeftCol>

                        <LeftCol xl={12}>
                          <FormItem
                            name="mobile"
                            label={t(R.strings.profile_content_mobile)}
                            rules={rules.mobile}
                            required
                          >
                            <FormInput
                              placeholder={t(R.strings.enter_field, {
                                field: t(R.strings.profile_content_mobile),
                              })}
                            />
                          </FormItem>
                        </LeftCol>
                      </BaseRow>
                      <BaseRow
                        gutter={20}
                        align={'top'}
                        justify={'space-between'}
                      >
                        <BaseCol xl={12}>
                          <FormItem
                            name="email"
                            label={t(R.strings.profile_content_email)}
                            rules={rules.email}
                            required
                          >
                            <FormInput
                              placeholder={t(R.strings.enter_field, {
                                field: t(R.strings.profile_content_email),
                              })}
                            />
                          </FormItem>
                        </BaseCol>
                        <BaseCol xl={12}>
                          <FormItem
                            label={t(R.strings.status)}
                            name="status"
                            rules={rules.status}
                            required
                          >
                            <SelectBaseStyled
                              placeholder={t(R.strings.please_select_status)}
                              suffixIcon={<SvgTableCloseIcon />}
                            >
                              {Object.values(StatusOptionsCreateEnum)?.map(
                                (item, index) => {
                                  return (
                                    <Option value={item} key={index}>
                                      {t(item)}
                                    </Option>
                                  )
                                },
                              )}
                            </SelectBaseStyled>
                          </FormItem>
                        </BaseCol>
                      </BaseRow>
                    </BaseCol>
                  </BaseRow>
                  <Divider />
                  <BaseRow gutter={20} align={'top'}>
                    <LeftCol xl={6}>
                      <Label
                        title={t(R.strings._skill)}
                        description={t(R.strings.skill_description_update)}
                      />
                    </LeftCol>
                    <BaseCol xl={18}>
                      <FormItem
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          !isEqual(prevValues.skills, currentValues.skills)
                        }
                      >
                        {({ getFieldValue }) => (
                          <FormItem hiddenLabel name="skills">
                            <Skill
                              skillOptions={skillOptions}
                              levelSkillOptions={levelSkillOptions}
                              skills={getFieldValue('skills') ?? []}
                              onRemove={handleRemoveSkill}
                            />
                            <SkillModal
                              skillOptions={skillOptions}
                              industryOptions={industryOptions}
                              levelSkillOptions={levelSkillOptions}
                              handleSubmit={handleAddSkill}
                            />
                          </FormItem>
                        )}
                      </FormItem>
                    </BaseCol>
                  </BaseRow>
                  <Divider />
                  <BaseRow gutter={20} align={'top'}>
                    <LeftCol xl={6}>
                      <Label
                        title={t(R.strings.certification)}
                        description={t(R.strings.certification_description)}
                      />
                    </LeftCol>
                    <BaseCol xl={18}>
                      <FormItem
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          !isEqual(prevValues.degrees, currentValues.degrees)
                        }
                      >
                        {({ getFieldValue }) => (
                          <FormItem hiddenLabel name="degrees">
                            <DegreeList
                              degrees={getFieldValue('degrees') ?? []}
                              degreeOptions={degreeOptions}
                              degreeClassificationOptions={
                                degreeClassificationOptions
                              }
                              onRemove={handleRemoveDegree}
                              onUpdate={handleUpdate}
                              onEdit={handleEditDegree}
                            />
                            <DegreeModal
                              degreeOptions={degreeOptions}
                              degreeClassificationOptions={
                                degreeClassificationOptions
                              }
                              handleSubmit={handleAddDegree}
                            />
                          </FormItem>
                        )}
                      </FormItem>
                    </BaseCol>
                  </BaseRow>
                </BaseSpace>
              ),
            },
          ]}
          expandIconPosition="right"
        />

        <CollapseWrapper
          defaultActiveKey={['workinfos']}
          items={[
            {
              key: 'workinfos',
              label: <BaseTitle>{t(R.strings.section_job_title)}</BaseTitle>,
              style: {
                alignItems: 'center',
              },
              children: (
                <BaseSpace>
                  <BaseRow gutter={20} align={'top'}>
                    <LeftCol xl={6}>
                      <Label
                        title={t(
                          R.strings.profile_content_job_title_main_column,
                        )}
                        description={t(R.strings.job_title_main_description)}
                        require
                      />
                    </LeftCol>
                    <BaseCol xl={18}>
                      <FormItem
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          !isEqual(
                            prevValues.workinfos,
                            currentValues.workinfos,
                          )
                        }
                      >
                        {({ getFieldValue }) => (
                          <FormItem hiddenLabel name="workinfos">
                            <WorkInfoList
                              workInfo={getFieldValue('workinfos') ?? []}
                              jobTitleOptions={jobTitles}
                              unitOptions={units}
                              onRemove={handleRemoveWorkInfo}
                              onEdit={handleEditWorkInfo}
                            />
                            {(getFieldValue('workinfos') ?? [])?.length <=
                              0 && (
                              <WorkInfoModal
                                jobTitleOptions={jobTitles}
                                departmentOptions={units}
                                handleSubmit={handleAddWorkInfo}
                              />
                            )}
                          </FormItem>
                        )}
                      </FormItem>
                    </BaseCol>
                  </BaseRow>
                  <Divider />
                  <BaseRow gutter={20} align={'top'}>
                    <LeftCol xl={6}>
                      <Label
                        title={t(
                          R.strings.profile_content_job_title_secondary_column,
                        )}
                        description={t(
                          R.strings.job_title_secondary_description,
                        )}
                      />
                    </LeftCol>
                    <BaseCol xl={18}>
                      <FormItem
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          !isEqual(
                            prevValues.workinfoakas,
                            currentValues.workinfoakas,
                          )
                        }
                      >
                        {({ getFieldValue }) => (
                          <FormItem hiddenLabel name="workinfoakas">
                            <WorkInfoAkaList
                              workInfo={getFieldValue('workinfoakas') ?? []}
                              jobTitleOptions={jobTitles}
                              onRemove={handleRemoveWorkInfoAka}
                              onAdd={handleAddWorkInfoAka}
                              unitOptions={units}
                            />
                            <WorkInfoAkaModal
                              jobTitleOptions={jobTitles}
                              departmentOptions={units}
                              handleSubmit={handleAddWorkInfoAka}
                            />
                          </FormItem>
                        )}
                      </FormItem>
                    </BaseCol>
                  </BaseRow>
                </BaseSpace>
              ),
            },
          ]}
          expandIconPosition="right"
        />
        <DirectManager type={props?.type} managedProfiles={managedProfiles} />
      </BaseFormWrapper>
      <FormItem>
        {props?.type === 'update' ? (
          <ButtonActionGroup>
            <ButtonCancel onClick={handleCancel}>
              {t(R.strings.cancel)}
            </ButtonCancel>
            <ButtonSubmit onClick={form.submit}>
              {t(R.strings.update)}
            </ButtonSubmit>
          </ButtonActionGroup>
        ) : (
          <ButtonActionGroup>
            <ButtonCancel onClick={handleCancel}>
              {t(R.strings.cancel)}
            </ButtonCancel>

            <ButtonSubmit onClick={form.submit}>
              {t(R.strings.profile_content_add)}
            </ButtonSubmit>
          </ButtonActionGroup>
        )}
      </FormItem>
    </BaseActionWrapper>
  )
}
