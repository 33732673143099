import { IDiscountVoucher } from 'app/api/marketing/discount-voucher/model'
import R from 'app/assets/R'
import { SvgDownIcon } from 'app/assets/svg-assets'
import { BasePagination } from 'app/components/common/BasePagination'
import { initPagination } from 'app/constant'
import { Pagination, moment } from 'parkway-web-common'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PaginationRow } from '../../styles'
import { VoucherCard } from '../VoucherCard'
import { EVoucherStatus } from '../VoucherCard/type'
import { CollapseWrapper, ListContainer, SectionTitle } from './styles'

interface IProps {
  data: IDiscountVoucher[]
}

export const ReferralList = (props: IProps) => {
  const { data } = props

  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [pagination, setPagination] = useState<Pagination>(initPagination)

  const ReferralVoucherList = () => {
    const renderItem = (item: IDiscountVoucher, index: number) => {
      return (
        <VoucherCard
          data={item}
          codeTitle={t(R.strings.referral_code)}
          key={`referral-voucher-${index}`}
        />
      )
    }
    // return <ListContainer gutter={[20, 20]}>{data?.map(renderItem)}</ListContainer>
    return (
      <ListContainer gutter={[20, 20]}>
        {Array(15)
          ?.fill({
            status: EVoucherStatus.USED,
            expiredAt: moment()?.toISOString(),
            code: '12345678',
          } as IDiscountVoucher)
          ?.map(renderItem)}
      </ListContainer>
    )
  }
  const onClick = () => {
    setOpen(prev => !prev)
  }

  return (
    <CollapseWrapper
      // defaultActiveKey={['referralList']}
      items={[
        {
          key: 'referralList',
          label: <SectionTitle>{t(R.strings.referral_code)}</SectionTitle>,
          style: {
            alignItems: 'center',
          },
          onClick: onClick,
          children: (
            <>
              <ReferralVoucherList />
              <PaginationRow justify={'end'}>
                <BasePagination
                  defaultCurrent={1}
                  pageSize={pagination?.pageSize}
                  total={data?.length}
                  onChange={(page: number, pageSize?: number) => {
                    setPagination({
                      pageSize,
                    })
                  }}
                  pageSizeOptions={[10, 20, 50, 100]}
                />
              </PaginationRow>
            </>
          ),
        },
      ]}
      expandIconPosition="right"
      expandIcon={() => (
        <SvgDownIcon style={open ? { transform: 'rotate(180deg)' } : {}} />
      )}
    />
  )
}
