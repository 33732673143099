import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  IGetItemPriceTemplate,
  IItemPrice,
  IItemPriceExport,
  IItemPriceImport,
  IPayloadCreateItemPrice,
  IPayloadUpdateItemPrice,
  IResponseGetItemPriceTemplate,
  IResponseItemPriceExport,
  IResponseItemPriceImport,
} from 'app/api/item-price/model'
import {
  requestCreateItemPrice,
  requestDeleteItemPrice,
  requestGetItemPriceTemplate,
  requestItemPriceExport,
  requestItemPriceImport,
  requestUpdateItemPriceById,
} from 'app/api/item-price'
import { ItemPriceKeys } from '../query-key/item-price'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { ItemPriceMessageCode } from 'app/api/item-price/constant'

export const useCreateItemPrice = () => {
  const query = useQueryClient()
  return useMutation<
    IItemPrice | undefined,
    unknown,
    IPayloadCreateItemPrice,
    unknown
  >(
    async (body: IPayloadCreateItemPrice) => {
      const res = await requestCreateItemPrice(body)
      if (
        isEqual(
          res?.msgcode,
          ItemPriceMessageCode.ItemPriceManagement.successPostData,
        )
      ) {
        return res.data
      }
      return undefined
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: ItemPriceKeys.allItemPrices,
        })
      },
    },
  )
}

export const useUpdateItemPrice = () => {
 
  return useMutation<
    IItemPrice | undefined,
    unknown,
    { id: string; body: IPayloadUpdateItemPrice },
    unknown
  >(
    async ({ id, body }: { id: string; body: IPayloadUpdateItemPrice }) => {
      const res = await requestUpdateItemPriceById(id, body)
      if (
        isEqual(
          res?.msgcode,
          ItemPriceMessageCode.ItemPriceManagement.successPutData,
        )
      ) {
        return res.data
      }
      return undefined
    },

  )
}

export function useGetItemPriceTemplate(enabled = true) {
  const token = getToken()
  return useQuery<IGetItemPriceTemplate | undefined, undefined>(
    ItemPriceKeys.itemPricesTemplate(),
    async () => {
      const res: IResponseGetItemPriceTemplate =
        await requestGetItemPriceTemplate()
      if (
        isEqual(
          res?.msgcode,
          ItemPriceMessageCode.ItemPriceManagement.successGetData,
        )
      ) {
        return res.data
      }
      return undefined
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export const useItemPriceImport = () => {
  const query = useQueryClient()
  return useMutation<
    IItemPriceImport[] | undefined,
    unknown,
    FormData,
    unknown
  >(
    async (body: FormData) => {
      const res: IResponseItemPriceImport = await requestItemPriceImport(body)
      if (
        isEqual(
          res?.msgcode,
          ItemPriceMessageCode.ItemPriceManagement.successGetData,
        )
      ) {
        return res.data
      }
      return undefined
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: ItemPriceKeys.itemPricesImport(),
        })
      },
    },
  )
}

export function useItemPriceExport(id: string, enabled = true) {
  const token = getToken()
  return useQuery<IItemPriceExport | undefined, undefined>(
    ItemPriceKeys.itemPricesExport(id),
    async () => {
      const res: IResponseItemPriceExport = await requestItemPriceExport(id)
      if (
        isEqual(
          res?.msgcode,
          ItemPriceMessageCode.ItemPriceManagement.successGetData,
        )
      ) {
        return res.data
      }
      return undefined
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export const useDeleteItemPrice = () => {
  return useMutation<IItemPrice | undefined, unknown, { id: string }, unknown>(
    async data => {
      const res = await requestDeleteItemPrice(data.id)
      if (
        isEqual(
          res?.msgcode,
          ItemPriceMessageCode.ItemPriceManagement.successDeleteData,
        )
      ) {
        return res.data
      }
      return undefined
    },
  )
}
