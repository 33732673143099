import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { Option } from 'app/components/common/selects/BaseSelect'
import { transformDataSelectTree } from 'app/components/common/selects/BaseTreeSelect/helper'
import { ItemCategoryContext } from 'app/context/ItemCategoryContext'
import { t } from 'i18next'
import { useCallback, useContext, useEffect, useState } from 'react'
import { DefineValueTypeItem, TypeItemEnum } from '../../common-model'
import * as S from './styles'
import {
  DefineStatusItemManagement,
  IFilterProps,
  IFilterResponseItemManagement,
  StatusItemManagementEnum,
} from './type'

export const FilterItemManagement = ({ handleChangeFilter }: IFilterProps) => {
  const [filter, setFilter] = useState<IFilterResponseItemManagement>({})
  const { categories } = useContext(ItemCategoryContext)

  useEffect(() => {
    const timer = setTimeout(() => {
      handleChangeFilter?.(filter)
    }, 500)
    return () => clearTimeout(timer)
  }, [filter])

  const onChangeSearch = e => {
    const keyword = e.target.value
    setFilter(prev => {
      return {
        ...prev,
        keyword,
      }
    })
  }

  const onChangeSelectFilter = (status: StatusItemManagementEnum) => {
    setFilter(prev => {
      return {
        ...prev,
        status,
      }
    })
  }

  const onChangeSelectTypeFilter = (type: TypeItemEnum) => {
    setFilter(prev => {
      return {
        ...prev,
        type,
      }
    })
  }

  const onChangeSelectCategory = (categoryid: string) => {
    setFilter(prev => {
      return {
        ...prev,
        categoryid,
      }
    })
  }

  const renderSelectFilter = useCallback(() => {
    return (
      <BaseRow
        style={{ marginLeft: 4, width: '100%' }}
        gutter={[16, 8]}
        align={'middle'}
        onChange={e => onChangeSearch(e)}
      >
        <BaseCol xl={6}>
          <BaseInput
            placeholder={t(R.strings.enter_name_product_service)}
            prefix={<S.SearchOutlinedIcon rev={undefined} />}
          />
        </BaseCol>
        <BaseCol xl={6}>
          <BaseForm.Item name="select">
            <S.SelectBaseStyled
              value={filter}
              mode="multiple"
              placeholder={t(R.strings.please_select_status)}
              suffixIcon={<SvgTableCloseIcon />}
              onChange={type =>
                onChangeSelectFilter(type as StatusItemManagementEnum)
              }
            >
              {Object.keys(StatusItemManagementEnum).map((key, index) => {
                return (
                  <Option key={index} value={StatusItemManagementEnum[key]}>
                    {DefineStatusItemManagement[StatusItemManagementEnum[key]]}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseForm.Item name="categoryid">
            <S.TreeSelectStyled
              onChange={type => onChangeSelectCategory(type as string)}
              placeholder={t(R.strings.item_management_content_select_category)}
              suffixIcon={<SvgTableCloseIcon />}
              treeData={transformDataSelectTree(categories, {
                title: 'name',
                value: '_id',
              })}
              allowClear
            />
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseForm.Item name="type">
            <S.SelectBaseStyled
              value={filter}
              mode="multiple"
              placeholder={t(
                R.strings.treatment_management_content_select_type,
              )}
              suffixIcon={<SvgTableCloseIcon />}
              onChange={type => onChangeSelectTypeFilter(type as TypeItemEnum)}
              allowClear
            >
              <Option>{t(R.strings.all)}</Option>
              {Object.keys(TypeItemEnum).map((key, index) => {
                return (
                  <Option key={index} value={key}>
                    {DefineValueTypeItem[key]}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>
    )
  }, [categories?.length])()

  return (
    <S.HeadWrapper gutter={20} align={'middle'}>
      {renderSelectFilter}
    </S.HeadWrapper>
  )
}
