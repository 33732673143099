import R from 'app/assets/R'
import {
  SvgBuildingIcon,
  SvgMoreIcon,
  SvgPlusIcon,
  SvgTrashIcon,
} from 'app/assets/svg-assets'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { IUnit } from 'app/model/unit.model'
import { t } from 'i18next'
import { DateUtil, FORMAT_DD_MM_YYYY } from 'parkway-web-common'
import React from 'react'
import { IFormWorkInfoAkaModalData } from '../WorkInfoAkaModal/type'
import * as S from './styles'
import { AddUnitModal } from './AddUnitModal'
import { IJobTitle } from 'app/api/jobtitle/model/job-title-management'

interface WorkInfoAkaItemProps {
  data: IFormWorkInfoAkaModalData[]
  jobTitleName?: string
  unitOptions?: IUnit[]
  index: number
  onRemove: (id: string) => void
  onAdd: (values: IFormWorkInfoAkaModalData) => void
}

const WorkInfoAkaItem: React.FC<WorkInfoAkaItemProps> = ({
  data,
  index,
  jobTitleName,
  unitOptions = [],
  onRemove,
  onAdd,
}) => {
  const _onAdd = (values: IFormWorkInfoAkaModalData) => {
    onAdd({
      ...values,
      jobtitleid: data?.[0]?.jobtitleid,
    })
  }
  return (
    <S.BaseRowStyled key={index}>
      <S.WorkInfoItem xl={6}>
        <S.TitleFormList>{jobTitleName}</S.TitleFormList>
        {data?.map((item, idx) => {
          const expiredDate = `${DateUtil.formatDate(
            item?.startdate?.$d,
            FORMAT_DD_MM_YYYY,
          )} - ${DateUtil.formatDate(item?.enddate?.$d, FORMAT_DD_MM_YYYY)}`
          const expiredDateNoEnd = `${DateUtil.formatDate(
            item?.startdate?.$d,
            FORMAT_DD_MM_YYYY,
          )} - ${t(R.strings.no_expired_date)}`

          const unitName =
            unitOptions?.find(i => i?._id === item?.unitid)?.name || ''
          return (
            <>
              {idx !== 0 && <S.DashDivider dashed />}
              <S.UnitContainer key={idx}>
                <BaseRow align={'middle'}>
                  <SvgBuildingIcon />
                  <S.UnitText children={unitName} />
                </BaseRow>
                <BaseRow align={'middle'}>
                  {item?.enddate?.$d ? (
                    <S.DateText children={expiredDate} />
                  ) : (
                    <S.DateText children={expiredDateNoEnd} />
                  )}
                </BaseRow>
              </S.UnitContainer>
            </>
          )
        })}
      </S.WorkInfoItem>
      <BasePopover
        placement="bottomLeft"
        trigger="click"
        content={
          <BaseSpace size={8}>
            <AddUnitModal
              departmentOptions={unitOptions}
              handleSubmit={_onAdd}
            />

            <S.Option onClick={() => onRemove(data[0]?.jobtitleid || '')}>
              <SvgTrashIcon />
              <S.OptionText
                children={t(R.strings.delete)}
                fontWeight="medium"
                opacity="0.7"
              />
            </S.Option>
          </BaseSpace>
        }
      >
        <S.ButtonContainer>
          <SvgMoreIcon />
        </S.ButtonContainer>
      </BasePopover>
    </S.BaseRowStyled>
  )
}

export default WorkInfoAkaItem
