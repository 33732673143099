import { UploadFile } from 'antd/lib'
import { IDegree } from 'app/api/degree/model/management'
import { IDegreeClassification } from 'app/api/degreeclassification/model/management'
import R from 'app/assets/R'
import {
  SvgEyeIcon,
  SvgMinusIcon,
  SvgMoreIcon,
  SvgPlusImageIcon,
  SvgTrashIcon,
} from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { getDegreeClassification } from 'app/utils/degreeClassification.util'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { BORDER_RADIUS, DateUtil, FORMAT_DD_MM_YYYY } from 'parkway-web-common'
import React, { useRef } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { DegreeEditModal } from './DegreeEditModal'
import { IFormDegreeModalData } from './DegreeEditModal/type'
import * as S from './styles'

interface DegreeItemProps {
  degree?: IDegree
  degreeClassification?: IDegreeClassification
  degreeClassificationOptions: IDegreeClassification[]
  degreeOptions: IDegree[]
  start?: Date
  end?: Date
  document?: UploadFile
  index: number
  documentImageUrl: string
  onRemove?: (key: number) => void
  onUpdate?: (key: number) => void
  onEdit: (values: IFormDegreeModalData) => void
}

const DegreeItem: React.FC<DegreeItemProps> = ({
  degree,
  degreeClassification,
  start,
  end,
  documentImageUrl,
  document,
  degreeClassificationOptions,
  degreeOptions,
  index,
  onRemove,
  onEdit,
}) => {
  const modalRef = useRef<IRefModal>(null)
  const handleOpenModalClick = () => {
    modalRef?.current?.open?.()
  }

  return (
    <S.BaseRowStyled>
      <S.DegreeItem xl={22}>
        <S.TitleFormList fontWeight="semibold">{degree?.name}</S.TitleFormList>
        <S.ClassificationWrapper>
          {start && (
            <BaseCol>
              <BaseText
                children={`${t(
                  R.strings.degree_content_start,
                )}: ${DateUtil.formatDate(start, FORMAT_DD_MM_YYYY)}`}
                fontWeight="medium"
                fontSize="xxs"
                opacity="0.7"
              />
            </BaseCol>
          )}
          {end && (
            <BaseCol>
              <BaseText
                children={`${t(
                  R.strings.degree_content_end,
                )}: ${DateUtil.formatDate(end, FORMAT_DD_MM_YYYY)}`}
                fontWeight="medium"
                fontSize="xxs"
                opacity="0.7"
              />
            </BaseCol>
          )}
          <BaseCol>
            <BaseRow align={'middle'}>
              <S.ClassificationText
                children={`${t(R.strings.degree_field_degree_classification)}:`}
                fontWeight="medium"
                fontSize="xxs"
                opacity="0.7"
              />
              <S.Tag className={`tag ${degreeClassification?.key}`}>
                {getDegreeClassification(degreeClassification?.key)}
              </S.Tag>
            </BaseRow>
          </BaseCol>
        </S.ClassificationWrapper>
      </S.DegreeItem>
      <S.ButtonActionGroup>
        <BasePopover
          placement="bottomLeft"
          trigger="click"
          content={
            <BaseSpace size={8}>
              <ModalComponent
                titleModal={t(R.strings.detail_degree)}
                titleOpenModal={t(R.strings.detail_degree)}
                renderContent={
                  <BaseRow>
                    <S.InfoContainer xl={6}>
                      <S.LabelText
                        children={t(R.strings.degree_content_name_create)}
                      />
                      <S.ValueText
                        children={t(R.strings.degree_content_name_create)}
                      />
                      <S.LabelText
                        children={t(
                          R.strings.degree_field_degree_classification,
                        )}
                      />
                      <S.Tag
                        className={`tag ${degreeClassification?.key}`}
                        align={'middle'}
                        $marginBottom={20}
                      >
                        <S.Dot />
                        {getDegreeClassification(degreeClassification?.key)}
                      </S.Tag>
                      <S.LabelText
                        children={t(R.strings.degree_content_start)}
                      />
                      <S.ValueText
                        children={DateUtil.formatDate(
                          start || '',
                          FORMAT_DD_MM_YYYY,
                        )}
                      />
                      <S.LabelText children={t(R.strings.degree_content_end)} />
                      <S.ValueText
                        children={
                          end
                            ? DateUtil.formatDate(end, FORMAT_DD_MM_YYYY)
                            : `${t(R.strings.no_expired_date)}`
                        }
                      />
                    </S.InfoContainer>
                    <S.ImageCol xl={18}>
                      <TransformWrapper initialScale={1}>
                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                          <>
                            <S.ImageContainer>
                              <TransformComponent>
                                <S.DegreeImage
                                  src={documentImageUrl}
                                  alt="image"
                                  preview={false}
                                />
                              </TransformComponent>
                            </S.ImageContainer>
                            <S.ZoomContainer>
                              <S.ZoomButton onClick={() => zoomIn()}>
                                <SvgPlusImageIcon width={20} height={20} />
                              </S.ZoomButton>
                              <S.ZoomButton
                                $marginTop={8}
                                onClick={() => zoomOut()}
                              >
                                <SvgMinusIcon width={20} height={20} />
                              </S.ZoomButton>
                            </S.ZoomContainer>
                          </>
                        )}
                      </TransformWrapper>
                    </S.ImageCol>
                  </BaseRow>
                }
                confirmKeyI18n={R.strings.detail_degree}
                ref={modalRef}
                borderRadiusButtonOpen={BORDER_RADIUS}
                isShowCancel={false}
                isShowConfirm={false}
                isShowLineBottom={false}
                isShowLineTop={false}
                paddingContent={'0'}
                showClose
                buttonOpenCustom={
                  <S.Option onClick={handleOpenModalClick}>
                    <SvgEyeIcon />
                    <S.OptionText
                      children={t(R.strings.see)}
                      fontWeight="medium"
                      opacity="0.7"
                    />
                  </S.Option>
                }
                widthModal={1200}
              />
              <DegreeEditModal
                handleSubmit={onEdit}
                initValue={
                  {
                    degree: degree?._id,
                    degreeClassification: degreeClassification?._id,
                    start: dayjs(start),
                    end: dayjs(end),
                    document, // Chỗ này không biết format từ {url, size, type} sang type UploadFile => làm sau
                  } as IFormDegreeModalData
                }
                degreeOptions={degreeOptions}
                degreeClassificationOptions={degreeClassificationOptions}
              />

              <S.Option onClick={() => onRemove && onRemove(index)}>
                <SvgTrashIcon />
                <S.OptionText
                  children={t(R.strings.delete)}
                  fontWeight="medium"
                  opacity="0.7"
                />
              </S.Option>
            </BaseSpace>
          }
        >
          <S.ButtonContainer>
            <SvgMoreIcon />
          </S.ButtonContainer>
        </BasePopover>
      </S.ButtonActionGroup>
    </S.BaseRowStyled>
  )
}

export default DegreeItem
