import { MoreOutlined } from '@ant-design/icons'
import { requestExportProfile } from 'app/api/profile'
import {
  IParamsGetProfileManagement,
  IProfileManagement,
} from 'app/api/profile/model/management'
import R from 'app/assets/R'
import {
  convertArrayStatusFilterToArrayStatusNumber,
  convertStatusToFilterStatus,
} from 'app/common/helpers'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { ButtonUpdate } from 'app/components/common/ButtonUpdate'
import {
  PROFILE_DETAIL_PATH,
  PROFILE_MANAGEMENT_UPDATE_PATH,
} from 'app/components/router/route-path'
import { initResponseFilterTableManagement } from 'app/components/tables/BaseTableManagement/FilterManagementTable/type'
import {
  RenderValueTable,
  StatusTableEnum,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { initPagination } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { useDebounce, usePagination } from 'app/hook'
import { useGetProfileList } from 'app/react-query/hook/profile-management'
import { IResponseQueryList } from 'app/react-query/model/common'
import { ProfileKeys } from 'app/react-query/query-key/profile-profile'
import { isEmpty, isEqual } from 'lodash'
import { FONT_WEIGHT, Pagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Delete } from './components/Delete'
import * as S from './styles'
import { IFilterProfile, IGeneralProfileManagementTable } from './type'
import { useNavigate } from 'react-router'

export const useHookTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [filter, setFilter] = useState<IFilterProfile>(
    initResponseFilterTableManagement,
  )

  const [pagination, setPagination] = useState(initPagination)

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.HR,
      FunctionPermissionEnum.PROFILE_MANAGEMENT_READ,
      R.strings.profile,
    )
  }, [t])

  const params = useMemo(() => {
    const status = convertArrayStatusFilterToArrayStatusNumber(filter?.status)
    let res: IParamsGetProfileManagement = {
      page: pagination?.current,
      pagesize: pagination?.pageSize,
      keyword: filter?.search,
      group: filter?.group,
      jobtitleid: filter?.jobTitles ?? [],
      unitIds: filter?.units,
    }

    if (status !== undefined) {
      res = {
        ...res,
        status,
      }
    }

    return res
  }, [filter, pagination])

  const paramsDebounce = useDebounce(params, 500)

  const { data, isLoading, refetch } = useGetProfileList(paramsDebounce)

  const { flattenDataList } = usePagination()

  const profileData = useMemo(() => {
    const res: IResponseQueryList<IProfileManagement[]> = flattenDataList(data)

    const newData = res?.data?.map((item, index) => {
      const startIndex =
        ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10)

      return {
        key: index + 1,
        ordinalNumber: startIndex + index + 1,
        name: item?.name ?? '-',
        employeeid: item?.employeeid,
        email: item?.email ?? '-',
        mobile: item?.mobile ?? '-',
        unitTypeId: item?.unitTypeId ?? '-',
        jobTitleNameMain: item?.jobTitleNameMain ?? '-',
        unitFilteredArea: item?.unitFilteredArea ?? '-',
        unitFilteredClinic: item?.unitFilteredClinic ?? '-',
        unitFilteredDepartment: item?.unitFilteredDepartment ?? '-',
        jobTitleAkaName: item?.jobTitleAkaName ?? '-',
        description: item?.description ?? '-',
        _id: item?._id ?? '-',
        status: convertStatusToFilterStatus(item?.status),
        avatar: item?.avatar,
      }
    })

    return {
      total: res?.total ?? 0,
      data: newData,
    }
  }, [data, pagination])

  const handleChangeFilter = (filter: IFilterProfile) => {
    setPagination(prev => ({ ...prev, current: 1 }))
    setFilter(prev => ({ ...prev, ...filter }))
  }

  const handleExport = async () => {
    try {
      const response = await requestExportProfile()
      const url = response?.data?.Url

      if (url) {
        url && window.open(url, '_bank')
        notificationController.success({
          message: t(R.strings.success),
        })
      }
    } catch (error) {
      console.log({ error })
    }
  }

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IGeneralProfileManagementTable>({
        title: t(R.strings.profile_content_name),
        key: 'name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          let jobTitles: string[] = []

          if (record?.jobTitleNameMain && !isEmpty(record?.jobTitleNameMain)) {
            jobTitles = jobTitles.concat([record?.jobTitleNameMain])
          }

          if (record?.jobTitleAkaName && !isEmpty(record?.jobTitleAkaName)) {
            jobTitles = jobTitles.concat([record?.jobTitleAkaName])
          }

          const jobTitle = jobTitles?.length ? jobTitles.join(' & ') : undefined
          return (
            <S.ProfileContainer
              onClick={() => {
                navigate(PROFILE_DETAIL_PATH?.replace(':id', record?._id ?? ''))
              }}
            >
              <BaseRow gutter={[10, 10]} align={'middle'} wrap={false}>
                <BaseCol>
                  <BaseAvatar
                    alt={record?.name}
                    src={record?.avatar}
                    style={{
                      width: 40,
                      height: 40,
                      alignItems: 'center',
                      justifyItems: 'center',
                      fontSize: 16,
                      display: 'flex',
                    }}
                  />
                </BaseCol>

                <BaseCol>
                  <RenderValueTable
                    value={record.name}
                    type="Main"
                    styleText={{ fontWeight: FONT_WEIGHT.bold }}
                  />
                  {jobTitle ? (
                    <S.TextJobTitleInProfileName>
                      {jobTitle}
                    </S.TextJobTitleInProfileName>
                  ) : null}
                </BaseCol>
              </BaseRow>
            </S.ProfileContainer>
          )
        },
      }),
      convertColumnTable<IGeneralProfileManagementTable>({
        title: t(R.strings.id),
        key: 'employeeid',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <RenderValueTable value={record.employeeid} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralProfileManagementTable>({
        title: `${t(R.strings.profile_content_email)}/${t(
          R.strings.profile_content_phone,
        )}`,
        key: 'email',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <BaseSpace size={5}>
            <RenderValueTable value={`${record.email}`} type="Base" />
            <RenderValueTable value={`${record?.mobile}`} type="Base" />
          </BaseSpace>
        ),
      }),
      convertColumnTable<IGeneralProfileManagementTable>({
        title: t(R.strings.profile_content_department),
        key: 'unitFilteredDepartment',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.unitFilteredDepartment ?? '-'}
            type="Base"
          />
        ),
      }),

      convertColumnTable<IGeneralProfileManagementTable>({
        title: t(R.strings.profile_content_area),
        key: 'unitFilteredArea',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable value={record.unitFilteredArea} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralProfileManagementTable>({
        title: t(R.strings.profile_content_clinic),
        key: 'unitFilteredClinic',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <RenderValueTable value={record.unitFilteredClinic} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralProfileManagementTable>({
        title: t(R.strings.status),
        key: 'status',
        className: 'title-right',
        render: (_, record) => (
          <RenderValueTable
            value={record.status}
            type="Status"
            isNotShowThreeDot
          />
        ),
      }),
      convertColumnTable<IGeneralProfileManagementTable>({
        title: '',
        key: 'status',
        className: 'title-right',
        fixed: 'right',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => (
          <>
            <BasePopover
              trigger={'click'}
              content={
                <BaseSpace>
                  <ButtonUpdate
                    title={t(R.strings.update)}
                    path={`${PROFILE_MANAGEMENT_UPDATE_PATH.replace(
                      ':id',
                      record._id ?? '',
                    )}`}
                  />
                  {!isEqual(record?.status, StatusTableEnum.deleted) && (
                    <Delete
                      id={record?._id}
                      fetchTable={() =>
                        refetch({ queryKey: ProfileKeys.allProfiles })
                      }
                      name={record?.name}
                    />
                  )}
                </BaseSpace>
              }
            >
              <BaseButton type="text" icon={<MoreOutlined rev={undefined} />} />
            </BasePopover>
          </>
        ),
      }),
    ]
    return option
  }, [t, refetch])

  const tableData = useMemo(() => {
    return {
      pagination: {
        ...pagination,
        total: profileData?.total,
      },
      isLoading,
      data: profileData?.data,
    }
  }, [pagination, profileData])

  return {
    tableData,
    handleTableChange,
    columns,
    handleChangeFilter,
    filter,
    handleExport,
  }
}
