import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { ExportBillContainer } from 'app/containers/Accounting/ExportBill'

const AccountingExportBillCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.list_export_bill_request)}</PageTitle>
      <ExportBillContainer />
    </>
  )
}
const AccountingExportBillPage = memo(AccountingExportBillCpn, isEqual)

export default AccountingExportBillPage
