/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'
import {
  DefinePaymentMethod,
  EPaymentMethod,
  IParamsGetReport24
} from 'app/api/report/model/report-24'
import R from 'app/assets/R'
import { SvgDownArrowIcon, SvgUpArrowIcon } from 'app/assets/svg-assets'
import { StatusEnum } from 'app/common/enum'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { notificationController } from 'app/controllers/notification-controller'
import { usePagination } from 'app/hook'
import { IClinic } from 'app/model/unit.model'
import { useGetAreaList } from 'app/react-query/hook/location'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { Dayjs } from 'dayjs'
import { t } from 'i18next'
import { Dates, FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { DebtTreeSelect } from './components'
import { requestGetServices } from 'app/api/treatment-group'

interface IProps {
  onChangeFilter?: (value: IParamsGetReport24) => void
  filter?: IParamsGetReport24
}

export const AdvanceFilterLayout: React.FC<IProps> = ({
  filter,
  onChangeFilter,
}) => {
  const [open, setOpen] = useState(false)
  const [filterMore, setFilterMore] = useState<IParamsGetReport24 | undefined>(
    filter,
  )

  const { data: areaApi, isLoading: isLoadingArea } = useGetAreaList({
    typeid: 'clinic',
    status: StatusEnum.ACTIVE,
  })

  const { flattenDataList } = usePagination()

  const clinics: IClinic[] = useMemo(() => {
    return flattenDataList(areaApi) ?? []
  }, [areaApi])

  // action change
  const onChangeClinic = (patientDefaultLocations: string[]) => {
    setFilterMore?.({ ...filterMore, patientDefaultLocations })
  }

  const onChangeBeginOrthodonticStartDate = (day?: Dayjs) => {
    if (
      day &&
      filterMore?.beginOrthodonticEndDate &&
      day.isAfter(filterMore?.beginOrthodonticEndDate)
    ) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.start_date_must_be_before_end_date),
      })
      return
    }

    const value = day ? day?.toISOString() : undefined
    setFilterMore?.(prev => ({ ...prev, beginOrthodonticStartDate: value }))
  }

  const onChangeBeginOrthodonticEndDate = (day?: Dayjs) => {
    if (
      day &&
      filterMore?.beginOrthodonticStartDate &&
      day.isBefore(filterMore?.beginOrthodonticStartDate)
    ) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.start_date_must_be_before_end_date),
      })
      return
    }

    const value = day ? day?.toISOString() : undefined
    setFilterMore?.(prev => ({ ...prev, beginOrthodonticEndDate: value }))
  }

  // end orthodontic date
  const onChangeEndOrthodonticStartDate = (day?: Dayjs) => {
    if (
      day &&
      filterMore?.endOrthodonticEndDate &&
      day.isAfter(filterMore?.endOrthodonticEndDate)
    ) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.start_date_must_be_before_end_date),
      })
      return
    }

    const value = day ? day?.toISOString() : undefined
    setFilterMore?.(prev => ({ ...prev, endOrthodonticStartDate: value }))
  }

  const onChangeEndOrthodonticEndDate = (day?: Dayjs) => {
    if (
      day &&
      filterMore?.endOrthodonticStartDate &&
      day.isBefore(filterMore?.endOrthodonticStartDate)
    ) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.start_date_must_be_before_end_date),
      })
      return
    }

    const value = day ? day?.toISOString() : undefined
    setFilterMore?.(prev => ({ ...prev, endOrthodonticEndDate: value }))
  }

  const [keyword] = useState('')
  const [isTreatmentLoading, setIsTreatmentLoading] = useState(false)
  const [treatments, setTreatments] = useState<
    {
      name: string
    }[]
  >([])

  const getData = async (keyword: string) => {
    setIsTreatmentLoading(true)
    try {
      const response = await requestGetServices({ keyword, pageSize: 100 })
      setTreatments(response?.data)
    } catch (error) {
      return {
        data: [],
      }
    }
    setIsTreatmentLoading(false)
  }

  useEffect(() => {
    getData(keyword)
  }, [])

  const onHide = () => {
    setOpen(false)
  }

  const onPressApply = () => {
    onHide()
    onChangeFilter?.({
      ...filter,
      beginOrthodonticStartDate: filterMore?.beginOrthodonticStartDate,
      beginOrthodonticEndDate: filterMore?.beginOrthodonticEndDate,
      endOrthodonticStartDate: filterMore?.endOrthodonticStartDate,
      endOrthodonticEndDate: filterMore?.endOrthodonticEndDate,
      
      patientDefaultLocations: filterMore?.patientDefaultLocations,
      treatmentIds: filterMore?.treatmentIds,
      paymentMethods: filterMore?.paymentMethods,
    })
  }

  const onPressReset = () => {
    setFilterMore({})
    onChangeFilter?.({
      ...filter,
      patientDefaultLocations: [],
      beginOrthodonticStartDate: undefined,
      beginOrthodonticEndDate: undefined,
      endOrthodonticStartDate: undefined,
      endOrthodonticEndDate: undefined,
      buyOrthodonticsEndDate: undefined,
      buyOrthodonticsStartDate: undefined,
      keyword: undefined,
      treatmentIds: undefined,
      paymentMethods: undefined,
    })
  }

  const onChangeService = (treatmentIds: string[]) => {
    setFilterMore?.(prev => ({ ...prev, treatmentIds }))
  }

  const onChangePaymentMethod = (paymentMethods: EPaymentMethod[]) => {
    setFilterMore?.(prev => ({ ...prev, paymentMethods }))
  }

  return (
    <BasePopoverWrapper
      trigger={'click'}
      placement="bottomRight"
      content={
        <BaseSpace
          split={<BaseDivider style={{ marginBottom: 0, marginTop: 0 }} />}
          size={12}
        >
          <BaseRow gutter={[16, 16]} align={'middle'} justify={'space-between'}>
            <BaseCol>
              <BaseText
                children={'Lọc thêm'}
                fontSize="xs"
                fontWeight="semibold"
              />
            </BaseCol>

            <BaseCol>
              <BaseButton
                icon={<CloseOutlined rev={undefined} />}
                onClick={onHide}
                type="text"
              />
            </BaseCol>
          </BaseRow>

          <BaseForm>
            <BaseSpace style={{ width: 450 }} size={4}>
              {/* // begin orthodontic date */}
              <BaseSpace size={0}>
                <BaseText
                  children={t(
                    R.strings.report_24_content_begin_orthodontic_date,
                  )}
                  fontWeight="semibold"
                  colorText="main2Color"
                />
                <BaseRow gutter={8} align={'middle'}>
                  <BaseCol xl={12}>
                    <BaseFormItem hiddenLabel>
                      <BaseDatePicker
                        picker="date"
                        value={
                          filterMore?.beginOrthodonticStartDate
                            ? Dates.getDate(
                                filterMore?.beginOrthodonticStartDate,
                              )
                            : null
                        }
                        onChange={day =>
                          onChangeBeginOrthodonticStartDate(day as Dayjs)
                        }
                        allowClear
                        format={'DD-MM-YYYY'}
                        placeholder={t(R.strings.from_date)}
                      />
                    </BaseFormItem>
                  </BaseCol>

                  <BaseCol xl={12}>
                    <BaseFormItem hiddenLabel>
                      <BaseDatePicker
                        value={
                          filterMore?.beginOrthodonticEndDate
                            ? Dates.getDate(filterMore?.beginOrthodonticEndDate)
                            : null
                        }
                        picker="date"
                        onChange={day =>
                          onChangeBeginOrthodonticEndDate(day as Dayjs)
                        }
                        allowClear
                        format={'DD-MM-YYYY'}
                        placeholder={t(R.strings.to_date)}
                      />
                    </BaseFormItem>
                  </BaseCol>
                </BaseRow>
              </BaseSpace>

              {/* // end orthodontic date */}
              <BaseSpace size={0}>
                <BaseText
                  children={t(R.strings.report_24_content_end_orthodontic_date)}
                  fontWeight="semibold"
                  colorText="main2Color"
                />
                <BaseRow gutter={8} align={'middle'}>
                  <BaseCol xl={12}>
                    <BaseFormItem hiddenLabel>
                      <BaseDatePicker
                        picker="date"
                        value={
                          filterMore?.endOrthodonticStartDate
                            ? Dates.getDate(filterMore?.endOrthodonticStartDate)
                            : null
                        }
                        onChange={day =>
                          onChangeEndOrthodonticStartDate(day as Dayjs)
                        }
                        allowClear
                        format={'DD-MM-YYYY'}
                        placeholder={t(R.strings.from_date)}
                      />
                    </BaseFormItem>
                  </BaseCol>

                  <BaseCol xl={12}>
                    <BaseFormItem hiddenLabel>
                      <BaseDatePicker
                        value={
                          filterMore?.endOrthodonticEndDate
                            ? Dates.getDate(filterMore?.endOrthodonticEndDate)
                            : null
                        }
                        picker="date"
                        onChange={day =>
                          onChangeEndOrthodonticEndDate(day as Dayjs)
                        }
                        allowClear
                        format={'DD-MM-YYYY'}
                        placeholder={t(R.strings.to_date)}
                      />
                    </BaseFormItem>
                  </BaseCol>
                </BaseRow>
              </BaseSpace>

              <BaseFormItem
                label={t(R.strings.report_24_content_package_service)}
              >
                <DebtTreeSelect
                  isTreatmentLoading={isTreatmentLoading}
                  treatments={treatments}
                  selectedValues={filterMore?.treatmentIds ?? []}
                  setSelectedValues={onChangeService}
                  type={['service']}
                  placeholder={t(
                    R.strings.manage_customer_debt_choose_treatment,
                  )}
                />
              </BaseFormItem>

              <BaseFormItem label={t(R.strings.payment_method)}>
                <BaseSelectWrapper
                  mode="multiple"
                  showSearch
                  placeholder={t(R.strings.all)}
                  allowClear
                  onChange={methods =>
                    onChangePaymentMethod?.(methods as EPaymentMethod[])
                  }
                  value={filterMore?.paymentMethods}
                  options={[
                    {
                      label: DefinePaymentMethod?.[EPaymentMethod.FULL].text,
                      value: EPaymentMethod.FULL,
                    },
                    {
                      label:
                        DefinePaymentMethod?.[EPaymentMethod.INSTALLMENT].text,
                      value: EPaymentMethod.INSTALLMENT,
                    },
                    {
                      label:
                        DefinePaymentMethod?.[EPaymentMethod.INSTALLMENT_CREDIT]
                          .text,
                      value: EPaymentMethod.INSTALLMENT_CREDIT,
                    },
                    {
                      label:
                        DefinePaymentMethod?.[
                          EPaymentMethod.INSTALLMENT_FUNDIIN
                        ].text,
                      value: EPaymentMethod.INSTALLMENT_FUNDIIN,
                    },
                    {
                      label:
                        DefinePaymentMethod?.[EPaymentMethod.INSTALLMENT_PW]
                          .text,
                      value: EPaymentMethod.INSTALLMENT_PW,
                    },
                  ]}
                  maxTagCount={0}
                />
              </BaseFormItem>

              <BaseFormItem label={'Cơ sở'}>
                <BaseSelectWrapper
                  mode="multiple"
                  showSearch
                  placeholder={t(R.strings.all)}
                  allowClear
                  onChange={ids => onChangeClinic?.(ids as string[])}
                  options={clinics?.map(item => ({
                    value: item.oldid,
                    label: item.name,
                  }))}
                  loading={isLoadingArea}
                  maxTagCount={0}
                  value={filterMore?.patientDefaultLocations}
                />
              </BaseFormItem>
            </BaseSpace>
          </BaseForm>

          <BaseRow gutter={[16, 16]} align={'middle'} justify={'space-between'}>
            <BaseCol>
              <BaseButton
                onClick={onPressReset}
                children={t(R.strings.report_24_content_reset)}
              />
            </BaseCol>

            <BaseCol>
              <BaseButton
                onClick={onPressApply}
                type="primary"
                children={t(R.strings.report_24_content_apply)}
              />
            </BaseCol>
          </BaseRow>
        </BaseSpace>
      }
      open={open}
      onOpenChange={setOpen}
    >
      <ButtonOpen
        children={
          <BaseRow align={'middle'} justify={'center'} gutter={[16, 8]}>
            <BaseCol>
              <FilterOutlined rev={undefined} />
            </BaseCol>
            <BaseCol>
              <BaseText
                children={'Lọc thêm'}
                fontWeight="medium"
                fontSize="xs"
                opacity="0.7"
              />
            </BaseCol>
            {!open ? (
              <SvgDownArrowIcon style={{ width: 20, height: 20 }} />
            ) : (
              <SvgUpArrowIcon style={{ width: 20, height: 20 }} />
            )}
          </BaseRow>
        }
      />
    </BasePopoverWrapper>
  )
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`

const BaseSelectWrapper = styled(BaseSelect)`
  width: 100%;
  .ant-select-selection-placeholder {
    padding-left: ${props =>
      props.$prefixWidth ? `${props?.$prefixWidth}px` : 0};
  }

  .ant-select-selection-item {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.9;
  }
`

const ButtonOpen = styled(BaseButton)`
  border-color: #f0f1f3;

  &:hover {
    .anticon {
      color: ${convertedVariables.primaryColor};
      opacity: 0.7;
    }
  }
`

const BasePopoverWrapper = styled(BasePopover)``
