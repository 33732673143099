import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BORDER_RADIUS } from 'parkway-web-common'

export const ProfileServiceComponent = ({
  itm,
}: {
  itm?: { name: string }
}) => {
  return (
    <BaseRow gutter={16} wrap={false} align={'middle'}>
      <BaseCol>
        <BaseAvatar
          alt={itm?.name}
          style={{
            borderRadius: BORDER_RADIUS,
          }}
          size={45}
        />
      </BaseCol>
      <BaseCol>
        <BaseSpace size={4}>
          <BaseText
            children={itm?.name ?? '-'}
            fontWeight="semibold"
            fontSize="xs"
          />
        </BaseSpace>
      </BaseCol>
    </BaseRow>
  )
}
