/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetItemManagement } from 'app/api/item'
import { ItemMessageCode } from 'app/api/item/constant'
import { IItemManagement } from 'app/api/item/model/management'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { useInfiniteQuery } from 'react-query'
import { IResponseQueryListNoTotal } from '../model/common'
import { ItemKeys } from '../query-key/item'
import { ResponseTypeV2 } from 'app/common/model'
import { SupplyKeys } from '../query-key/supply'
import { ItemType } from 'app/containers/Accounting/TreatmentManagement/CreateUpdateProductServiceManagement/type'

export function useGetItemList(params?: any, enabled = true) {
  const token = getToken()
  return useInfiniteQuery<
    IResponseQueryListNoTotal<IItemManagement[]>,
    undefined
  >(
    ItemKeys.listItem(params),
    async () => {
      try {
        const res: ResponseTypeV2<IItemManagement[]> =
          await requestGetItemManagement(params)

        if (
          isEqual(res?.msgcode, ItemMessageCode.ItemManagement.successGetData)
        ) {
          const data: IItemManagement[] = res?.data || []

          return {
            data,
            total: res.metadata.totalItem || 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export function useGetSupplies(params?: any, enabled = true) {
  const token = getToken()
  return useInfiniteQuery<
    IResponseQueryListNoTotal<IItemManagement[]>,
    undefined
  >(
    SupplyKeys.listSupply(),
    async () => {
      try {
        const res: ResponseTypeV2<IItemManagement[]> =
          await requestGetItemManagement({ ...params, type: ItemType.supplies })

        if (
          isEqual(res?.msgcode, ItemMessageCode.ItemManagement.successGetData)
        ) {
          const data: IItemManagement[] = res?.data || []

          return {
            data,
            total: res.metadata.totalItem || 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
