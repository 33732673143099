import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseLabel } from 'app/components/common/forms/BaseLabel'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputPassword } from 'app/components/common/inputs/InputPassword'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'
import { Upload } from 'antd'
import BaseText from 'app/components/common/BaseText'

export const ButtonCreateAction = styled(BaseButton)`
  background-color: var(--states-orange-color);
  color: var(--background-color);
  transition: all 0.5s ease;
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: var(--background-color);
    color: var(--states-orange-color);
    border-color: var(--states-orange-color);
    transition: all 0.5s ease;
  }
`
export const LabelWrapper = styled(BaseRow)`
  color: var(--main-2-color);
`

export const RequireDot = styled.div`
  color: var(--states-red-color);
`
export const DashLine = styled(BaseDivider)``

export const TitleForm = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.bold};
`

export const FormItem = styled(BaseForm.Item)``

export const Label = styled(BaseLabel)``

export const Checkbox = styled(BaseCheckbox)`
  margin: ${PADDING.md} 0;
`

export const FormInput = styled(BaseInput)``

export const DatePicker = styled(BaseDatePicker)`
  width: 100%;
`

export const FormInputPassword = styled(InputPassword)``

export const SelectBaseStyled = styled(BaseSelect)``

// UPload
const { Dragger } = Upload

export const TitleUpload = styled.span`
  color: var(--collapse-background-color);
  font-size: ${PADDING.lg};
`
export const UploadWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DraggerStyled = styled(Dragger)`
  & .ant-upload.ant-upload-btn {
    padding: 26px 0;
  }
`
export const ButtonSkillAction = styled(BaseButton)`
  border-color: transparent;
  transition: all 0.5s ease;
  color: var(--primary-color) !important;
  box-shadow: none;
  border: 1px solid #e1e7ea;
  font-size: ${FONT_SIZE.xxs} !important;
  font-weight: ${FONT_WEIGHT.medium} !important;
  opacity: 0.8;
  padding: 4px 8px;
  gap: 0;
  &:hover {
    background-color: var(--other-blue-color);
    color: var(--background-color) !important;
    transition: all 0.5s ease;
    border-color: transparent !important;
    box-shadow: none;
  }
`
export const Option = styled(BaseRow)`
  background-color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding 8px 0px !important;
`
export const OptionText = styled(BaseText)`
  font-size: 14px;
  color: var(--text-primary2-color);
  margin-left: 8px;
  width: 135px;
`
