import { ApiClient } from 'app/service/api-service'
import { AccountingEndPoint } from './constant'
import { IParamsAccounting, IParamsGetCostPlan } from './model'
import { downloadNotificationController } from 'app/controllers/download-notification-controller'
import prettyBytes from 'pretty-bytes'
import R from 'app/assets/R'
import axios from 'axios'
import {
  EBillStatus,
  IFilterGetExportBill,
  IPayloadCreatedExportBill,
  IPayloadUpdateExportBill,
} from './model/export-bill'
import { EInvoiceStatus } from 'app/containers/CustomerCare/AppointmentPage/type'

export const requestGetInfoByTaxCode = (taxCode: string) =>
  ApiClient.Get({
    url: `${AccountingEndPoint.ExportBill.getBusiness}/${taxCode}`,
    params: {},
  })
export const requestCreateExportBill = (body: IPayloadCreatedExportBill) =>
  ApiClient.Post({
    url: AccountingEndPoint.ExportBill.requestCreateExportBill,
    body,
  })

export const requestExportExcelExportBill = (params: IFilterGetExportBill) =>
  ApiClient.Get({
    url: AccountingEndPoint.ExportBill.exportExcel,
    params: { params },
  })

export const updateBill = ({
  id,
  body,
}: {
  id: string
  body: IPayloadUpdateExportBill
}) =>
  ApiClient.Put({
    url: `${AccountingEndPoint.ExportBill.update}/${id}`,
    body,
  })
export const updateBillStatus = ({
  id,
  status,
}: {
  id: string
  status: EBillStatus
}) =>
  ApiClient.Put({
    url: `${AccountingEndPoint.ExportBill.updateStatus}/${id}`,
    body: { status },
  })

export const checkValidPaymentCode = (params: { paymentCodes: string[] }) =>
  ApiClient.Get({
    url: AccountingEndPoint.ExportBill.checkValidPaymentCode,
    params: { params },
  })
export const requestGetLocationLegalList = () =>
  ApiClient.Get({
    url: AccountingEndPoint.ExportBill.requestGetLocationLegal,
    params: {},
  })
export const getCountExportBillTabs = () =>
  ApiClient.Get({
    url: AccountingEndPoint.ExportBill.getCountTabs,
    params: {},
  })

export const requestGetDataExportBill = (params?: IFilterGetExportBill) =>
  ApiClient.Get({
    url: AccountingEndPoint.ExportBill.requestGetDataExportBill,
    params: { params },
  })
export const requestGetUsedService = (params: {
  paymentCodes: string[]
  tInvoiceStatus: EInvoiceStatus
}) =>
  ApiClient.Get({
    url: `${AccountingEndPoint.ExportBill.getUsedTreatment}`,
    params: { params },
  })

export const requestGetCostPlan = (params: IParamsGetCostPlan) =>
  ApiClient.Get({
    url: AccountingEndPoint.CostPlan.requestGetData,
    params: { params },
  })

export const requestImportCostPlan = (body: FormData) =>
  ApiClient.Post({
    url: AccountingEndPoint.CostPlan.requestImportFile,
    body,
  })

export const requestPatientDebtContract = (body: {
  treatments: string
  params: IParamsAccounting
}) =>
  ApiClient.Post({
    url: AccountingEndPoint.PatientDebtContract.requestGetData,
    body,
  })

export const requestExportPatientDebtContract = (body: {
  treatments: string
  params: IParamsAccounting
}) =>
  ApiClient.Post({
    url: AccountingEndPoint.PatientDebtContract.ExportPatientDebt,
    body,
  })

export const requestBracesTray = (params: IParamsAccounting) =>
  ApiClient.Get({
    url: AccountingEndPoint.BracesTray.requestGetData,
    params: { params },
  })

export const requestExportBracesTray = (params: IParamsAccounting) =>
  ApiClient.Get({
    url: AccountingEndPoint.BracesTray.ExportBracesTray,
    params: { params },
  })

export const requestDailyInvoice = (body: {
  treatments: string
  params: IParamsAccounting
}) =>
  ApiClient.Post({
    url: AccountingEndPoint.DailyInvoice.requestGetData,
    body,
  })

export const requestExportDailyInvoice = (body: {
  treatments: string
  params: IParamsAccounting
}) =>
  ApiClient.Post({
    url: AccountingEndPoint.DailyInvoice.Export,
    body,
  })

export const requestSyncDailyInvoice = (params: IParamsAccounting) =>
  ApiClient.Get({
    url: AccountingEndPoint.DailyInvoice.sync,
    params: { params },
  })

export const requestPatientDebt = (body: {
  treatments: string
  params: IParamsAccounting
}) =>
  ApiClient.Post({
    url: AccountingEndPoint.PatientDebt.requestGetData,
    body,
  })

export const requestExportPatientDebt = (body: {
  treatments: string
  params: IParamsAccounting
}) =>
  ApiClient.Post({
    url: AccountingEndPoint.PatientDebt.Export,
    body,
  })

export const handleFileDownload = async (
  fileUrl: string,
  t,
  title: string,
  key: string,
) => {
  try {
    const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1)

    downloadNotificationController.downloading({
      duration: 0,
      key: key + fileUrl,
      message: `${title} ${fileName}`,
      description: `${t(R.strings.downloading)} ${0}%`,
      cancel: t(R.strings.cancel),
    })

    const response = await axios.get(fileUrl, {
      responseType: 'blob',
      onDownloadProgress: progressEvent => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100,
        )
        downloadNotificationController.downloading({
          duration: 0,
          key: key + fileUrl,
          message: `${title} ${fileName}`,
          description: `${t(R.strings.downloading)} ${progress}%`,
          cancel: t(R.strings.cancel),
        })
      },
    })
    // Create a temporary URL object for download

    downloadNotificationController.downloaded({
      duration: 2,
      key: key + fileUrl,
      message: `${title} ${fileName}`,
      description: prettyBytes(response?.data?.size || 0),
      cancel: t(R.strings.cancel),
    })

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url

    link.setAttribute('download', fileName)

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    // Revoke the object URL after download
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error('File download failed:', error)
    downloadNotificationController.downloadErrored({
      duration: 2,
      key: key + fileUrl,
      message: title,
      description: t(R.strings.download_errored),
      cancel: t(R.strings.cancel),
    })
  }
}
