import { IJobTitle } from 'app/api/jobtitle/model/job-title-management'
import { IUnit } from 'app/model/unit.model'
import { groupBy } from 'lodash'
import WorkInfoItem from './WorkInfoItem'
import { IFormWorkInfoModalData } from './WorkInfoModal/type'
import * as S from './styles'

interface IGroupedWorkInfos {
  id: string
  name: string
  data: IFormWorkInfoModalData[]
}
interface IProps {
  jobTitleOptions: IJobTitle[]
  unitOptions?: IUnit[]
  workInfo?: IFormWorkInfoModalData[]
  onRemove: (id: string) => void
  onEdit: (values: IFormWorkInfoModalData) => void
}

export const WorkInfoList = ({
  workInfo,
  jobTitleOptions,
  unitOptions,
  onRemove,
  onEdit,
}: IProps) => {
  return (
    <S.WorkInfoWrapper>
      {Object.entries(groupBy(workInfo, 'jobtitleid')).map(
        ([jobtitleid, items], index) => {
          const jobTitleName =
            jobTitleOptions?.find(
              jobTitleOption => jobTitleOption._id === jobtitleid,
            )?.name || ''

          return (
            <WorkInfoItem
              data={items}
              jobTitleName={jobTitleName}
              unitOptions={unitOptions}
              jobTitleOptions={jobTitleOptions}
              key={index}
              index={index}
              onRemove={onRemove}
              onEdit={onEdit}
            />
          )
        },
      )}
    </S.WorkInfoWrapper>
  )
}
