/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DefineCampaignReferralEnum,
  DefineDiscountCampaignType,
} from 'app/api/marketing/discount-campaign/constant'
import {
  ApplyTypeEnum,
  CampaignReferralEnum,
  CampaignTypeEnum,
  ICampaignGroup,
} from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { YesNoEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseSwitch } from 'app/components/common/BaseSwitch'
import BaseText from 'app/components/common/BaseText'
import BaseTextEditor from 'app/components/common/BaseTextEditor'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { BaseTreeSelect } from 'app/components/common/selects/BaseTreeSelect'
import { transformDataSelectTree } from 'app/components/common/selects/BaseTreeSelect/helper'
import { usePagination } from 'app/hook'
import { useGetCampaignGroupAll } from 'app/react-query/hook/campaign'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import dayjs, { Dayjs } from 'dayjs'
import { isEqual } from 'lodash'
import { BORDER_RADIUS, PADDING, ResponseType } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useAdditionalServiceCampaignContext } from '../../context'
import { ListCampaignGroupLayout } from './layouts'

export const InfoCampaignLayout = () => {
  const { t } = useTranslation()
  const {
    areaList,
    isLoadingArea,
    filterTreeNode,
    keywordSearchArea,
    setKeywordSearchArea,
    onChangeClinic,
    selectedClinic,
    campaignType,
    isApplyWithOtherCampaign,
    campaignApplyCommon,
    oldEndDate,
    info,
  } = useAdditionalServiceCampaignContext()

  const { flattenDataList } = usePagination()

  const { data: campaignGroupAllData, isLoading: loadingCampaignGroupAll } =
    useGetCampaignGroupAll()

  const campaignGroupAll = useMemo(() => {
    const res: ResponseType<ICampaignGroup[]> =
      flattenDataList(campaignGroupAllData)

    return res?.data
  }, [campaignGroupAllData])

  return (
    <RootWrapper>
      <HeaderWrapper>
        <BaseText
          children={t(R.strings.campaign_info)}
          fontWeight="semibold"
          fontSize="md"
        />
      </HeaderWrapper>
      <ContentWrapper size={4}>
        <BaseRow>
          <BaseCol xl={24}>
            <BaseForm.Item
              name={'name'}
              label={t(R.strings.campaign_name)}
              required
            >
              <BaseInput
                disabled
                placeholder={t(R.strings.enter_campaign_name)}
              />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>
        <BaseRow>
          <BaseCol xl={24}>
            <BaseForm.Item
              name="group_campaign"
              label={t(R.strings.group_campaign)}
              required
            >
              <BaseSelect
                placeholder={t(R.strings.select_group_campaign)}
                options={campaignGroupAll?.map(item => ({
                  label: item?.name,
                  value: item?.code,
                }))}
                loading={loadingCampaignGroupAll}
                disabled
              />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>
        <BaseRow gutter={[16, 8]} align={'top'}>
          <BaseCol xl={12}>
            <BaseForm.Item
              name={'clinics'}
              label={t(R.strings.apply_clinic)}
              required
            >
              <BaseTreeSelect
                placeholder={t(R.strings.select_clinic_apply)}
                loading={isLoadingArea}
                treeData={transformDataSelectTree(areaList ?? [], {
                  title: 'name',
                  value: '_id',
                  children: 'childs',
                })}
                value={selectedClinic}
                multiple
                showSearch
                treeCheckable={true}
                onSearch={keyword => setKeywordSearchArea?.(keyword)}
                filterTreeNode={filterTreeNode}
                searchValue={keywordSearchArea}
                onChange={ids => onChangeClinic?.(ids as string[])}
                allowClear
                disabled
              />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol xl={12}>
            <BaseRow gutter={[16, 8]} align={'top'}>
              <BaseCol xl={12}>
                <BaseForm.Item
                  name={'startDate'}
                  required
                  label={t(R.strings.start_date)}
                >
                  <BaseDatePickerStyled
                    format={'DD-MM-YYYY HH:mm'}
                    placeholder={t(R.strings.start_date)}
                    showTime
                    disabled
                  />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={12}>
                <BaseForm.Item
                  name={'endDate'}
                  required
                  label={t(R.strings.end_date)}
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.require_field, {
                        field: t(R.strings.end_date),
                      }),
                    },

                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const day = value as Dayjs

                        if (!day || !getFieldValue('startDate')) {
                          return Promise.resolve()
                        }

                        if (day.isBefore(oldEndDate)) {
                          return Promise.reject(
                            t(R.strings.end_date_must_be_after_old_end_date, {
                              old: `"${oldEndDate?.format(
                                'DD/MM/YYYY HH:mm',
                              )}"`,
                            }),
                          )
                        }

                        if (day.isBefore(getFieldValue('startDate'))) {
                          return Promise.reject(
                            t(R.strings.end_date_must_be_after_start_date),
                          )
                        }

                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <BaseDatePickerStyled
                    format={'DD-MM-YYYY HH:mm'}
                    placeholder={t(R.strings.end_date)}
                    showTime={{ defaultValue: dayjs('23:59', 'HH:mm') }}
                  />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>
        <BaseRow gutter={[16, 8]} align={'top'}>
          <BaseCol xl={12}>
            <BaseRow gutter={[16, 8]} align={'top'}>
              <BaseCol xl={12}>
                <BaseForm.Item
                  required
                  label={t(R.strings.active_type)}
                  name={'activeType'}
                >
                  <BaseSelect
                    placeholder={t(R.strings.select_active_type)}
                    options={[
                      {
                        label: t(R.strings.voucher),
                        value: ApplyTypeEnum.Voucher,
                      },
                      {
                        label: t(R.strings.auto),
                        value: ApplyTypeEnum.Auto,
                      },
                    ]}
                    disabled
                  />
                </BaseForm.Item>
              </BaseCol>

              <BaseCol xl={12}>
                <BaseForm.Item
                  required
                  label={t(R.strings.apply_with_other_campaign)}
                  name={'applyWithOtherCampaign'}
                >
                  <BaseSelect
                    placeholder={t(R.strings.choose_apply_with_other_campaign)}
                    options={[
                      {
                        label: t(R.strings.yes),
                        value: YesNoEnum.Yes,
                      },
                      {
                        label: t(R.strings.no),
                        value: YesNoEnum.No,
                      },
                    ]}
                    disabled
                  />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>
          </BaseCol>

          <BaseCol xl={12}>
            <BaseForm.Item
              required
              label={t(R.strings.type_of_offer)}
              name={'campaignType'}
            >
              <BaseSelect
                placeholder={t(R.strings.select_type_of_offer)}
                disabled
                value={campaignType}
                defaultValue={campaignType}
                options={[
                  {
                    label: t(
                      DefineDiscountCampaignType[CampaignTypeEnum.Treatments]
                        .keyI18n,
                    ),
                    value: CampaignTypeEnum.Treatments,
                  },
                  {
                    label: t(
                      DefineDiscountCampaignType[CampaignTypeEnum.BuyOneGetOne]
                        .keyI18n,
                    ),
                    value: CampaignTypeEnum.BuyOneGetOne,
                  },
                  {
                    label: t(
                      DefineDiscountCampaignType[CampaignTypeEnum.Voucher]
                        .keyI18n,
                    ),
                    value: CampaignTypeEnum.Voucher,
                  },
                  {
                    label: t(
                      DefineDiscountCampaignType[CampaignTypeEnum.Bundle]
                        .keyI18n,
                    ),
                    value: CampaignTypeEnum.Bundle,
                  },
                  {
                    label: t(
                      DefineDiscountCampaignType[CampaignTypeEnum.TotalBill]
                        .keyI18n,
                    ),
                    value: CampaignTypeEnum.TotalBill,
                  },
                ]}
              />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={[16, 8]} align={'top'}>
          <BaseCol xl={6}>
            <BaseForm.Item
              required
              label={t(R.strings.priority)}
              name={'priority'}
            >
              <BaseInput disabled />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol xl={18}>
            <BaseForm.Item
              required
              label={t(R.strings.type_of_offer)}
              name={'campaignType'}
            >
              <BaseSelect
                placeholder={t(R.strings.select_type_of_offer)}
                disabled
                value={campaignType}
                defaultValue={campaignType}
                options={[
                  {
                    label: t(
                      DefineDiscountCampaignType[CampaignTypeEnum.Treatments]
                        .keyI18n,
                    ),
                    value: CampaignTypeEnum.Treatments,
                  },
                  {
                    label: t(
                      DefineDiscountCampaignType[CampaignTypeEnum.BuyOneGetOne]
                        .keyI18n,
                    ),
                    value: CampaignTypeEnum.BuyOneGetOne,
                  },
                  {
                    label: t(
                      DefineDiscountCampaignType[CampaignTypeEnum.Voucher]
                        .keyI18n,
                    ),
                    value: CampaignTypeEnum.Voucher,
                  },
                  {
                    label: t(
                      DefineDiscountCampaignType[CampaignTypeEnum.Bundle]
                        .keyI18n,
                    ),
                    value: CampaignTypeEnum.Bundle,
                  },
                  {
                    label: t(
                      DefineDiscountCampaignType[CampaignTypeEnum.TotalBill]
                        .keyI18n,
                    ),
                    value: CampaignTypeEnum.TotalBill,
                  },
                ]}
              />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={[16, 8]} align={'top'}>
          <BaseCol xl={6}>
            <BaseForm.Item
              name="isApplyPaymentDiscount"
              label={t(R.strings.apply_payment_discount)}
            >
              <BaseSwitch
                disabled={isEqual(isApplyWithOtherCampaign, YesNoEnum.Yes)}
                // checked={isApplyPaymentDiscount}
                // onChange={onChangeApplyPaymentDiscount}
              />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol xl={18}>
            <BaseForm.Item name="referral" label={t(R.strings.campaign_type)}>
              <BaseSelect
                placeholder={t(R.strings.select_campaign_type)}
                options={[
                  {
                    label: t(
                      DefineCampaignReferralEnum[CampaignReferralEnum.Normal]
                        ?.keyI18n,
                    ),
                    value: CampaignReferralEnum.Normal,
                  },
                  {
                    label: t(
                      DefineCampaignReferralEnum[CampaignReferralEnum.Patient]
                        ?.keyI18n,
                    ),
                    value: CampaignReferralEnum.Patient,
                  },
                  {
                    label: t(
                      DefineCampaignReferralEnum[CampaignReferralEnum.Staff]
                        ?.keyI18n,
                    ),
                    value: CampaignReferralEnum.Staff,
                  },
                  {
                    label: t(
                      DefineCampaignReferralEnum[CampaignReferralEnum.OnlyUser]
                        ?.keyI18n,
                    ),
                    value: CampaignReferralEnum.OnlyUser,
                  },
                ]}
                disabled
              />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>

        {isEqual(isApplyWithOtherCampaign, YesNoEnum.Yes) ? (
          <BaseRow>
            <BaseCol xl={24}>
              <BaseForm.Item
                name="campaign_apply_common"
                label={t(R.strings.campaign_apply_common)}
                required
              >
                <BaseSelect
                  placeholder={t(R.strings.select_campaign_apply_common)}
                  options={[
                    {
                      label: t(R.strings.all),
                      value: R.strings.all,
                    },
                    {
                      label: t(R.strings.campaign_list),
                      value: R.strings.campaign_list,
                    },
                  ]}
                  filterSort={() => 0}
                  disabled
                />
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>
        ) : null}

        <BaseSpace>
          {/* {isEqual(campaignApplyCommon, R.strings.campaign_list) &&
          isEqual(isApplyWithOtherCampaign, YesNoEnum.Yes) ? (
            <BaseSpace>
              <BaseRow
                gutter={[16, 16]}
                align={'middle'}
                justify={'space-between'}
              >
                <BaseCol>
                  <BaseText
                    children={t(R.strings.campaign_list)}
                    fontWeight="semibold"
                    fontSize="xs"
                  />
                </BaseCol>

                <BaseCol>
                  <ModalAddCampaign />
                </BaseCol>
              </BaseRow>

              <ListCampaignWrapper>
                {stackedWithCampaigns?.length ? (
                  stackedWithCampaigns?.map((item, index) => {
                    return (
                      <ItemWrapper
                        key={index}
                        align={'middle'}
                        justify={'space-between'}
                      >
                        <BaseCol>
                          <BaseText children={item?.name} fontWeight="medium" />
                        </BaseCol>

                        {item?.isInit ? (
                          <BaseCol>
                            <BaseButton type="text" />
                          </BaseCol>
                        ) : (
                          <BaseCol>
                            <BaseButton
                              icon={<SvgDeleteDesignIcon />}
                              type="text"
                              onClick={() =>
                                onDeleteStackedWithCampaign?.(item?._id)
                              }
                            />
                          </BaseCol>
                        )}
                      </ItemWrapper>
                    )
                  })
                ) : (
                  <BaseEmpty />
                )}
              </ListCampaignWrapper>
            </BaseSpace>
          ) : null} */}

          {isEqual(campaignApplyCommon, R.strings.campaign_list) &&
          isEqual(isApplyWithOtherCampaign, YesNoEnum.Yes) &&
          !!info?.endDate &&
          !!info?.startDate &&
          !!info?.clinics?.length ? (
            <BaseSpace>
              <BaseRow
                gutter={[16, 16]}
                align={'middle'}
                justify={'space-between'}
              >
                <BaseCol>
                  <BaseText
                    children={t(R.strings.campaign_list)}
                    fontWeight="semibold"
                    fontSize="xs"
                  />
                </BaseCol>
              </BaseRow>

              <ListCampaignWrapper>
                <ListCampaignGroupLayout />
              </ListCampaignWrapper>
            </BaseSpace>
          ) : null}

          <BaseRow>
            <BaseCol xl={24}>
              <BaseForm.Item
                label={t(R.strings.description)}
                name="description"
              >
                <BaseTextEditor disabled />
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>
        </BaseSpace>
      </ContentWrapper>
    </RootWrapper>
  )
}

const RootWrapper = styled.div`
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.backgroundColor};
`
const HeaderWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
  border-bottom: 0.75px solid ${convertedVariables.neutralBlack9Color};
`

const ContentWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
  background-color: #fcfcfc;
  border-end-end-radius: ${BORDER_RADIUS};
`

const BaseDatePickerStyled = styled(BaseDatePicker)`
  width: 100%;
`

const ListCampaignWrapper = styled(BaseSpace)`
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.neutralBlack9Color};
  padding: ${PADDING.md};
`
