import { IProfileUser } from 'app/api/profile/model/profile'
import { convertStatusToFilterStatus } from 'app/common/helpers'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { getJobTitleName } from 'app/utils/getJobTitleName'
import { useTranslation } from 'react-i18next'
import {
  AvatarProfile,
  Dot,
  NameCol,
  SectionBaseWrapper,
  StatusChip,
  TextJobTitleProfile,
  TextNameProfile,
  AvatarCol,
  Container,
} from './styles'

interface IProps {
  profile?: IProfileUser
}

export const Avatar = (props: IProps) => {
  const { profile } = props
  const { t } = useTranslation()
  return (
    <Container>
      <SectionBaseWrapper>
        <BaseRow gutter={20} align={'bottom'} justify={'start'}>
          <AvatarCol>
            <AvatarProfile
              src={profile?.avatar}
              alt={`${profile?.firstname ?? ''} ${profile?.lastname ?? ''} `}
            />
          </AvatarCol>
          <NameCol>
            <BaseRow gutter={[20, 20]} align={'middle'}>
              <BaseCol>
                <TextNameProfile>
                  {`${profile?.lastname ?? ''} ${profile?.firstname ?? ''} `}
                </TextNameProfile>
              </BaseCol>
              <BaseCol>
                <StatusChip align={'middle'}>
                  <Dot />
                  {t(convertStatusToFilterStatus(profile?.status))}
                </StatusChip>
              </BaseCol>
            </BaseRow>
            <TextJobTitleProfile>
              {getJobTitleName(profile?.workinfos)}
            </TextJobTitleProfile>
          </NameCol>
        </BaseRow>
      </SectionBaseWrapper>
    </Container>
  )
}
