import R from 'app/assets/R'
import { SvgChevronRightIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { DASHBOARD_PATH } from 'app/components/router/route-path'
import { isEmpty, isEqual } from 'lodash'
import { FONT_SIZE, LAYOUT } from 'parkway-web-common'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'
import { ProfileDropdown } from '../components/profile/ProfileDropdown'
import * as S from '../styles'
import { NotificationsDropdown } from '../components/NotificationsDropdown'

const SPACE_PATH = ' > '

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({
  isTwoColumnsLayout,
}) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const path = useMemo(() => {
    if (isEqual(pathname, DASHBOARD_PATH)) {
      return {
        lastPath: t(R.strings.dashboard),
        middlePathName: '',
        middlePath: '',
      }
    }

    const convertedPath = pathname.replace(/-/g, '_')
    const pathComponents = convertedPath
      .split('/')
      .filter(component => component !== '')

    if (pathComponents.length > 1) {
      const splitIndex = pathComponents.length - 1
      const middleArray = pathComponents.slice(0, splitIndex)
      const lastArray = pathComponents.slice(splitIndex)
      return {
        lastPath: lastArray
          .map(component => `${t(`${component}`)}`)
          .join(SPACE_PATH),
        middlePathName: middleArray
          .map(component => `${t(`${component}`)}`)
          .join(SPACE_PATH),
        middleArray,
        middlePath: middleArray.map(component => component).join(SPACE_PATH),
      }
    }

    const translatedPath = pathComponents
      .map(component => `${t(`${component}`)}`)
      .join(SPACE_PATH)

    return {
      lastPath: translatedPath,
      middlePathName: '',
      middlePath: '',
    }
  }, [pathname, t])

  const renderPath = (() => {
    let prefix = ''
    return (
      <S.CurrentPath gutter={6} align={'middle'}>
        <BaseCol>
          <S.HomeText onClick={() => navigate(DASHBOARD_PATH)}>{`${t(
            R.strings.home,
          )}`}</S.HomeText>
        </BaseCol>
        <SvgChevronRightIcon />
        {!isEmpty(path?.middleArray) &&
          path?.middleArray?.map((pathChild, index) => {
            prefix += `/${pathChild.replace(/_/g, '-')}`
            const navigationPath = prefix

            return (
              <>
                <BaseCol>
                  <S.HomeText
                    key={index}
                    onClick={() => navigate(navigationPath)}
                  >{`${t(pathChild)}`}</S.HomeText>
                </BaseCol>

                <SvgChevronRightIcon />
              </>
            )
          })}
        <BaseCol style={{fontSize: FONT_SIZE.xxs}}>
          {path?.lastPath}
        </BaseCol>
      </S.CurrentPath>
    )
  })()

  const renderPathHeader = useMemo(() => {
    const path = pathname?.split('/')

    // chỉ lấy phần tử cuối cùng
    const lastPath = path?.[path.length - 1]

    // chuyển dấu - thành _
    const convertPath = lastPath?.replace(/-/g, '_')

    // chuyển thành chữ hoa

    return t(convertPath)
  }, [path])

  return (
    <BaseSpace size={0}>
      <HeaderTop justify="space-between" align="middle">
        <BaseCol xl={14}>
          <BaseText
            children={renderPathHeader}
            fontWeight="semibold"
            fontSize="lg"
          />
        </BaseCol>

        <S.ProfileColumn $isTwoColumnsLayout={isTwoColumnsLayout} xl={8}>
          <BaseRow align="middle" justify="end" gutter={16} wrap={false}>
            <BaseCol>
              <NotificationsDropdown />
            </BaseCol>
            <BaseCol>
              <ProfileDropdown />
            </BaseCol>
          </BaseRow>
        </S.ProfileColumn>
      </HeaderTop>

      <div
        style={{
          backgroundColor: '#FCFCFD',
          display: 'flex',
          flex: 1,
          height: '2.5rem',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: `${LAYOUT.mobile.paddingVertical} ${LAYOUT.mobile.paddingHorizontal}`,
          borderTop: '1px solid #F2F4F7',
        }}
      >
        {renderPath}
      </div>
    </BaseSpace>
  )
}

const HeaderTop = styled(BaseRow)`
  padding: 0.25rem ${LAYOUT.mobile.paddingHorizontal};
  height: ${LAYOUT.mobile.headerHeight};
  padding-right: 1rem;
`
