import { SvgDownIcon } from 'app/assets/svg-assets'
import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { IWorkInfo } from 'app/model/user.model'
import { useAppSelector } from 'app/redux/hooks'
import React, { useState } from 'react'
import { ModalChangePassword } from '../ModalChangePassword'
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay'
import * as S from './styles'

export const GenerateWorkInfo = (workInfos?: IWorkInfo[]) => {
  if (!workInfos) return ''
  return workInfos?.map(component => `${component.jobtitlename}`).join(' & ')
}

export const ProfileDropdown: React.FC = () => {
  // const { isTablet } = useResponsive()
  const [open, setOpen] = useState(false)

  const [visibleModalChangePassword, setVisibleModalChangePassword] =
    useState(false)

  const showModal = () => {
    setVisibleModalChangePassword(true)
  }

  const hide = () => {
    setOpen(false)
  }

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }

  const profile = useAppSelector(state => state.profileSlice)?.profile

  const onHideModalChangePassword = () => {
    setVisibleModalChangePassword(false)
  }

  return (
    <>
      <S.BasePopoverCustom
        content={<ProfileOverlay onHide={hide} showModal={showModal} />}
        trigger="click"
        onOpenChange={handleOpenChange}
        open={open}
      >
        <S.ProfileDropdownHeader
          as={BaseRow}
          gutter={6}
          align="middle"
          wrap={false}
        >
          <BaseCol>
            <BaseAvatar src={profile?.avatar} />
          </BaseCol>
          <BaseCol>
          <SvgDownIcon />
          </BaseCol>
        </S.ProfileDropdownHeader>
      </S.BasePopoverCustom>

      <ModalChangePassword
        isVisible={visibleModalChangePassword}
        onHideModal={onHideModalChangePassword}
      />
    </>
  )
}
