import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const SectionBaseWrapper = styled(SectionBase)`
  padding: ${PADDING.xl} !important;
`
export const SectionTitle = styled(BaseText)`
  font-size: 16px !important;
  font-weight: ${FONT_WEIGHT.semibold}!important;
  color: ${convertedVariables.primaryColor};
`
export const SectionDescription = styled(BaseText)`
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.regular}!important;
  color: ${convertedVariables.primaryColor};
  margin-top: 8px;
`
export const ItemLabel = styled(BaseText)`
  font-size: 14px !important;
  font-weight: ${FONT_WEIGHT.regular}!important;
  color: #858c94;
`
export const ItemValue = styled(BaseText)`
  font-size: 14px !important;
  font-weight: ${FONT_WEIGHT.semibold}!important;
  color: ${convertedVariables.primaryColor};
  margin-top: 8px;
`
export const Divider = styled(BaseDivider)`
  margin: 20px 0;
`
export const ListContainer = styled(BaseRow)`
  padding: 20px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  background: #fcfcfd;
  max-height: 400px !important;
  overflow: auto;
  row-gap: 0;
  gap: 20px;
`
export const CollapseWrapper = styled(BaseCollapse)`
  border: none;
  background: white;
  overflow: hidden;

  .ant-collapse-header {
    border-bottom: 1px solid #f4f6f9;
  }
  .ant-collapse-item {
    border-bottom: 0px;
  }
  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 0 !important;
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
  }

  .ant-collapse-header {
    align-items: center !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
`
