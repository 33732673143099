import { IDegreeProfileUser } from 'app/api/profile/model/profile'
import R from 'app/assets/R'
import { SvgMinusIcon, SvgPlusImageIcon } from 'app/assets/svg-assets'
import { BaseRow } from 'app/components/common/BaseRow'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { getDegreeClassification } from 'app/utils/degreeClassification.util'
import { BORDER_RADIUS, DateUtil, FORMAT_DD_MM_YYYY } from 'parkway-web-common'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import {
  DegreeImage,
  Dot,
  ImageCol,
  ImageContainer,
  InfoContainer,
  LabelText,
  Option,
  OptionText,
  Tag,
  ValueText,
  ZoomButton,
  ZoomContainer,
} from './styles'

interface IProps {
  data?: IDegreeProfileUser
}

export const DetailItem = (props: IProps) => {
  const { data } = props

  const { t } = useTranslation()
  const modalRef = useRef<IRefModal>(null)
  const handleOpenModalClick = () => {
    modalRef?.current?.open?.()
  }
  return (
    <ModalComponent
      titleModal={t(R.strings.detail_degree)}
      titleOpenModal={t(R.strings.detail_degree)}
      renderContent={
        <BaseRow>
          <InfoContainer xl={6}>
            <LabelText children={t(R.strings.degree_content_name_create)} />
            <ValueText
              children={data?.degreeDetail?.name || data?.degree?.name}
            />
            <LabelText
              children={t(R.strings.degree_field_degree_classification)}
            />
            <Tag
              className={`tag ${data?.degreeClassification?.key}`}
              align={'middle'}
              $marginBottom={20}
            >
              <Dot />
              {getDegreeClassification(
                data?.degreeClassificationDetail?.key ||
                  data?.degreeClassification?.key,
              )}
            </Tag>
            <LabelText children={t(R.strings.degree_content_start)} />
            <ValueText
              children={DateUtil.formatDate(
                data?.start || '',
                FORMAT_DD_MM_YYYY,
              )}
            />
            <LabelText children={t(R.strings.degree_content_end)} />
            <ValueText
              children={
                data?.end
                  ? DateUtil.formatDate(data?.end, FORMAT_DD_MM_YYYY)
                  : `${t(R.strings.no_expired_date)}`
              }
            />
          </InfoContainer>
          <ImageCol xl={18}>
            <TransformWrapper initialScale={1}>
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                  <ImageContainer>
                    <TransformComponent>
                      <DegreeImage
                        src={data?.document?.url}
                        alt="image"
                        preview={false}
                      />
                    </TransformComponent>
                  </ImageContainer>
                  <ZoomContainer>
                    <ZoomButton onClick={() => zoomIn()}>
                      <SvgPlusImageIcon width={20} height={20} />
                    </ZoomButton>
                    <ZoomButton $marginTop={8} onClick={() => zoomOut()}>
                      <SvgMinusIcon width={20} height={20} />
                    </ZoomButton>
                  </ZoomContainer>
                </>
              )}
            </TransformWrapper>
          </ImageCol>
        </BaseRow>
      }
      confirmKeyI18n={R.strings.detail_degree}
      ref={modalRef}
      borderRadiusButtonOpen={BORDER_RADIUS}
      isShowCancel={false}
      isShowConfirm={false}
      isShowLineBottom={false}
      isShowLineTop={false}
      paddingContent={'0'}
      showClose
      buttonOpenCustom={
        <Option onClick={handleOpenModalClick}>
          <OptionText
            children={t(R.strings.see_detail)}
            fontWeight="medium"
            opacity="0.7"
          />
        </Option>
      }
      widthModal={1200}
    />
  )
}
