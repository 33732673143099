import { requestDetailProfileManagement } from 'app/api/profile'
import { ProfileMessageCode } from 'app/api/profile/constant'
import { IProfileManagement } from 'app/api/profile/model/management'
import { IProfileUser, ISkillProfileUser } from 'app/api/profile/model/profile'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { usePagination } from 'app/hook'
import {
  useGetMyVoucherList,
  useGetReferralVoucherList,
} from 'app/react-query/hook/profile'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { getUserInfo } from 'app/redux/slices/profileSlice'
import 'dayjs/locale/vi'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

export const useModalHook = () => {
  const { id } = useParams()

  const profileSelf = useAppSelector(state => state.profileSlice.profile)
  const dispatch = useAppDispatch()
  const [profile, setProfile] = useState(profileSelf)
  const { t } = useTranslation()
  const [form] = BaseForm.useForm()
  const { flattenDataList } = usePagination()
  const [myVCPageSize, setMyVCPageSize] = useState(10)
  const [myVCCurrentPage, setMyVCCurrentPage] = useState(1)
  const [rVCPageSize, setRVCPageSize] = useState(10)
  const [rVCCurrentPage, setRVCCurrentPage] = useState(1)

  const onChangeMyVCPageSize = (newPageSize: number) => {
    setMyVCPageSize(newPageSize)
  }

  const onChangeMyVCPage = (page: number) => {
    setMyVCCurrentPage(page)
  }

  const onChangeRVCPageSize = (newPageSize: number) => {
    setRVCPageSize(newPageSize)
  }

  const onChangeRVCPage = (page: number) => {
    setRVCCurrentPage(page)
  }

  const { data: dataMyVCInfinity, isLoading: loadingMyVC } =
    useGetMyVoucherList({
      page: myVCCurrentPage,
      pageSize: myVCPageSize,
      pagesize: myVCPageSize,
    })

  const flattenMyVoucherData = useMemo(() => {
    return flattenDataList(dataMyVCInfinity)
  }, [dataMyVCInfinity])

  const { data: dataRVCInfinity, isLoading: loadingRVC } =
    useGetReferralVoucherList({
      page: rVCCurrentPage,
      pageSize: rVCPageSize,
      pagesize: rVCPageSize,
    })
  const flattenReferralVoucherData = useMemo(() => {
    return flattenDataList(dataRVCInfinity)
  }, [dataRVCInfinity])

  useEffect(() => {
    dispatch(getUserInfo())
  }, [])
  useEffect(() => {
    const getDataFn = async () => {
      try {
        if (!id) {
          setProfile(profileSelf)
        } else {
          const res: ResponseType<IProfileUser> =
            await requestDetailProfileManagement(id)

          if (
            isEqual(
              res?.msgcode,
              ProfileMessageCode.ProfileManagement.successGetData,
            )
          ) {
            setProfile(res?.data)
          }
        }
      } catch (error) {
        //none
      }
    }
    getDataFn()
  }, [id])

  const skills = useMemo(() => {
    return groupByIndustry(profile?.skills ?? [])
  }, [profile])

  const listWorkInfo = useMemo(() => {
    return profile?.workinfos?.filter(item => item?.isMain) ?? []
  }, [profile])

  const listWorkInfoAka = useMemo(() => {
    return profile?.workinfos?.filter(item => !item?.isMain) ?? []
  }, [profile])

  return {
    form,
    t,
    skills,
    profile,
    listWorkInfo,
    listWorkInfoAka,
    myVoucherList: flattenMyVoucherData?.data,
    referralVoucherList: flattenReferralVoucherData?.data,
    myVCPageSize,
    myVCCurrentPage,
    rVCCurrentPage,
    rVCPageSize,
    onChangeMyVCPage,
    onChangeMyVCPageSize,
    onChangeRVCPage,
    onChangeRVCPageSize,
    loadingMyVC,
    loadingRVC,
  }
}

function groupByIndustry(
  data: Array<ISkillProfileUser>,
): Array<{ nameType: string; children: Array<ISkillProfileUser> }> {
  const groups = data.reduce((acc, item) => {
    const group = acc.find(group => group.nameType === item.industry?.name)
    if (group) {
      group.children.push(item)
    } else {
      acc.push({
        nameType: item.industry?.name ?? '-',
        children: [item],
      })
    }
    return acc
  }, [] as Array<{ nameType: string; children: Array<ISkillProfileUser> }>)
  return groups
}
