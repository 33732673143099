import { IItemPrice, IPayloadCreateItemPrice } from 'app/api/item-price/model'
import { StatusEnum } from 'app/common/enum'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useCreateItemPrice } from 'app/react-query/hook/item-price'
import { IFormData } from '../type'
import { useLocation, useNavigate } from 'react-router'
import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import dayjs from 'dayjs'

export const useHook = () => {
  const [form] = BaseForm.useForm<IFormData>()
  const navigate = useNavigate()
  const { state } = useLocation()
  const item = state?.item

  const { mutateAsync: mudateCreateItemPriceSync, isLoading } =
    useCreateItemPrice()

  useEffect(() => {
    if (isEmpty(item)) {
      return
    }
    const { areas, startDate, endDate, name, status, isDefault, prices } =
      item as IItemPrice

    form.setFieldsValue({
      name,
      prices,
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      areaIds: areas.map(area => area.id),
      status,
      isDefault,
    })
  }, [])

  const handleSubmit = async (values: Partial<IFormData>) => {
    const {
      areaIds = [],
      prices = [],
      startDate,
      endDate,
      isDefault = false,
      name,
      status = StatusEnum.ACTIVE,
    } = values
    if (!startDate || !endDate || !name || !areaIds.length) {
      return
    }
    const payload: IPayloadCreateItemPrice = {
      name,
      areaIds,
      startDate: dayjs(startDate).startOf('day').toISOString(),
      endDate: dayjs(endDate).endOf('day').toISOString(),
      isDefault,
      status,
      prices: prices.map(price => ({
        itemId: price.itemId,
        price: price.price,
      })),
    }

    const result = await mudateCreateItemPriceSync(payload)
    if (result) {
      navigate(-1)
    }
  }

  return {
    form,
    handleSubmit,
    isLoadingSubmit: isLoading,
  }
}
