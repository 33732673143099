import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelectSearch } from 'app/components/common/selects/SelectSearch'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'

import styled from 'styled-components'

export const BaseRowStyled = styled(BaseRow)`
  align-items: center;
  padding-bottom: 20px;
  gap: 20px;
  justify-content: space-between;
  border-bottom: 1px solid #f0f1f3;

  &:last-child {
    border-bottom: none;
  }
`

export const FormItem = styled(BaseForm.Item)``

export const SelectSearchBaseStyled = styled(BaseSelectSearch)``
export const InfoContainer = styled(BaseCol)`
  padding: 20px;
  border-top: 1px solid #f4f6f9;
  background: #fff;
`
export const LabelText = styled(BaseText)`
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.medium} !important;
  color: #a5abb3 !important;
  margin-bottom: 8px;
`
export const ValueText = styled(BaseText)`
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.semibold} !important;
  color: var(--primary-color);
  margin-bottom: 20px;
`
export const ImageCol = styled(BaseCol)`
  background: #f2f4f7 !important;
`
export const ImageContainer = styled(BaseCol)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ZoomButton = styled.div<{ $marginTop?: number }>`
  cursor: pointer;
  border-radius: 4px;
  background: var(--Neutral-White, #fff);
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => props?.$marginTop ?? 0}px;
`
export const ZoomContainer = styled(BaseCol)`
  margin-right: 25px;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
export const ButtonActionGroup = styled(BaseCol)`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
`
export const Tag = styled(BaseRow)<{ $marginBottom?: number }>`
  background: linear-gradient(137deg, #ffae49 0%, #ff7617 100%);
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.04),
    0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  font-size: 10px !important;
  font-weight: ${FONT_WEIGHT.bold} !important;
  color: var(--white) !important;
  padding: 4px 12px;
  width: fit-content;
  margin-bottom: ${props => props?.$marginBottom ?? 0}px;
`

export const DegreeImage = styled(BaseImage)`
  max-width: 900px;
  max-height: 540px;
`
export const Dot = styled.div`
  width: 4px;
  height: 4px;
  background: var(--white);
  border-radius: 8px;
  margin-right: 4px;
`
export const TagContainer = styled(BaseRow)`
  padding: 4px 12px;
`
export const Option = styled(BaseRow)`
  background-color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding 8px 0px !important;
`

export const DegreeItem = styled(BaseCol)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${PADDING.xxxxs};
`

export const PopoverContent = styled.div`
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
  width: 176px;
  padding: 0 !important;
  background: #fff;
  border-radius: 8px;
`
export const ButtonContainer = styled.div`
  cursor: pointer;
  border: 1px solid #e1e7ea;
  border-radius: 8px;
  padding: 4px;
  display: flex;
  justify-content: center;
`

export const TitleFormList = styled(BaseText)`
  font-size: 14px;
  color: var(--text-primary2-color);
`
export const ClassificationText = styled(BaseText)`
  margin-right: 4px;
`
export const OptionText = styled(BaseText)`
  font-size: 14px;
  color: var(--text-primary2-color);
  margin-left: 8px;
  width: 135px;
`

export const ClassificationWrapper = styled(BaseRow)`
  display: flex;
  gap: 32px;
  align-items: center;

  & span {
    font-size: ${FONT_SIZE.xxs};
    color: var(--text-primary2-color);
  }

  .tag {
    background-color: #f8f8f8;
    border-radius: 100px;
    padding: 2px 1rem;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.semibold};
  }

  .excellent {
    background-color: #fffbed;
    color: #fff;
  }

  .good {
    background-color: #ecfdf2;
    color: #fff;
  }

  .average_good {
    background-color: #e8f6f9;
    color: #fff;
  }

  .average {
    background-color: #e8f6f9;
    color: #fff;
  }

  .below_average {
    background-color: #e8f6f9;
    color: #fff;
  }

  .weak {
    background-color: #ffe6e6;
    color: #fff;
  }
`

export const Link = styled.a`
  color: var(--collapse-background-color);

  &:hover {
    color: var(--collapse-background-color);
    opacity: 0.8;
  }
`
