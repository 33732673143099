import { ISkillProfileUser } from 'app/api/profile/model/profile'
import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { Pagination } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable } from './type'
import { LevelSkillWrapper } from './styles'

interface IPropsUseModalHook {
  listSkill: ISkillProfileUser[]
}

export const useModalHook = ({ listSkill }: IPropsUseModalHook) => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState<Pagination>({
    pageSize: 7,
    current: 1,
  })

  const data = useMemo(() => {
    return listSkill?.map((item, index) => {
      return {
        ...item,
        key: index,
      } as IDataTable
    })
  }, [listSkill])

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<ISkillProfileUser>({
        title: t(R.strings.profile_content_industry),
        key: 'industry',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record: ISkillProfileUser) => {
          console.log('===============================================')
          console.log('record', record)
          console.log('===============================================')
          return (
            <BaseText
              children={record?.industry?.name ?? ''}
              fontWeight="medium"
              opacity="0.7"
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<ISkillProfileUser>({
        title: t(R.strings.skill_group),
        key: 'skill',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: ISkillProfileUser) => {
          return (
            <BaseText
              children={''}
              fontWeight="medium"
              opacity="0.7"
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<ISkillProfileUser>({
        title: t(R.strings._skill),
        key: 'skill',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: ISkillProfileUser) => {
          return (
            <BaseText
              children={record?.skill?.name ?? ''}
              fontWeight="semibold"
              opacity="0.9"
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<ISkillProfileUser>({
        title: t(R.strings.profile_content_level),
        key: 'levelSkill',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record: ISkillProfileUser) => {
          return (
            <LevelSkillWrapper>
              <BaseText
                children={record?.levelSkill?.name ?? ''}
                fontWeight="medium"
                fontSize="xxs"
                textAlign="right"
                opacity="0.9"
              />
            </LevelSkillWrapper>
          )
        },
      }),
    ]
    return option
  }, [t])

  const handleTableChange = (newPagination: Pagination) => {
    setPagination(newPagination)
  }

  return {
    data,
    columns,
    handleTableChange,
    pagination: {
      ...pagination,
      total: listSkill?.length,
    },
  }
}
