/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteFilled, ImportOutlined, InboxOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseUpload } from 'app/components/common/BaseUpload'
import { BaseButtonsForm } from 'app/components/common/forms/BaseButtonsForm'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { _DEV_ } from 'app/constant'
import { FONT_SIZE, formatMoney, normFile, PADDING } from 'parkway-web-common'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ProfileServiceComponent } from '..'
import { IPrice } from '../../type'
import { useItemPriceImport } from 'app/react-query/hook/item-price'

enum EStatus {
  DUPLICATED = 'duplicated',
  NOT_FOUND_ITEM_PRICE = 'not_found_item_price',
  SUCCESS = 'success',
}
interface IProps {
  onCompleted: (services: IPrice[]) => void
}

interface IResponseItemPrice {
  _id?: string
  name: string
  price: number
  status: EStatus
  code?: string
  barCode?: string
}
// const DefineStatusEnum = {
//   [EStatus.DUPLICATED]: {
//     text: R.strings.duplicated_treatment,
//     color: convertedVariables.statesRedColor,
//   },
//   [EStatus.NOT_FOUND_ITEM_PRICE]: {
//     text: R.strings.not_found_treatment,
//     color: convertedVariables.statesRedColor,
//   },
//   [EStatus.SUCCESS]: {
//     text: R.strings.success,
//     color: convertedVariables.statesGreenColor,
//   },
// }

export const ButtonImportModal = (props: IProps) => {
  const { onCompleted } = props
  const refModal = useRef<IRefModal>({})
  const { t } = useTranslation()
  const [fileSelected, setFileSelected] = useState<File | null | undefined>(
    null,
  )

  const {
    mutateAsync: mutateItemPriceImport,
    isLoading,
    data: dataItemPriceImport,
  } = useItemPriceImport()

  const _onConfirmModal = async () => {
    const itemPrices = dataItemPriceImport || []
    if (itemPrices?.length) {
      onCompleted(
        itemPrices
          .map(item => {
            if (!item._id) return null
            return {
              itemId: item._id,
              name: item.name,
              price: item.price,
              code: item?.code,
              barCode: item?.barCode,
            }
          })
          .filter(item => item !== null) as IPrice[],
      )
    }

    refModal?.current?.hide?.()
    setFileSelected(null)
    // setListItemPrice([])
  }

  const onRemoveFile = () => {
    setFileSelected(null)
    // setListItemPrice([])
  }

  const onChangeFile = async (values: { file: any }) => {
    const { file } = values
    const { lastModified, name, type, originFileObj } = file
    const newFile = new File([originFileObj], name, { type, lastModified })
    if (!newFile) return

    setFileSelected(newFile)

    try {
      const formData = new FormData()
      formData.append('file', newFile)

      await mutateItemPriceImport(formData)
    } catch (error) {
      _DEV_ && console.log('error', error)
    }
  }

  const onOpenModal = () => {
    refModal.current?.open?.()
  }

  const dataTreatment = useMemo(() => {
    return (dataItemPriceImport || [])?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [dataItemPriceImport])

  // const successData = useMemo(() => {
  //   // return (dataItemPriceImport || [])?.filter(item =>
  //   //   isEqual(item.status, EStatus.SUCCESS),
  //   // )
  //   return dataItemPriceImport || []
  // }, [dataItemPriceImport])

  // const errorData = useMemo(() => {
  //   // return (dataItemPriceImport || [])?.filter(
  //   //   item => !isEqual(item.status, EStatus.SUCCESS),
  //   // )
  //   return []
  // }, [dataItemPriceImport])

  const handleCancel = () => {
    setFileSelected(null)
    // setListItemPrice([])
  }

  return (
    <ModalComponent
      titleModal={R.strings.import_file}
      widthModal={1000}
      buttonOpenCustom={
        <BaseButton
          icon={<ImportOutlined rev={undefined} />}
          onClick={onOpenModal}
        >
          {t(R.strings.import)}
        </BaseButton>
      }
      ref={refModal}
      handleSubmit={_onConfirmModal}
      handleCancel={handleCancel}
      renderContent={
        <BaseSpace size={8}>
          <DraggerInput
            name="dragger"
            valuePropName="file"
            getValueFromEvent={normFile}
            noStyle
          >
            <BaseUpload.Dragger
              name="files"
              onChange={onChangeFile}
              customRequest={() => undefined}
              showUploadList={false}
              accept=".xls, .xlsx"
            >
              <DraggerWrapper>
                <IconMailBox rev={undefined} size={32} />
              </DraggerWrapper>
            </BaseUpload.Dragger>
          </DraggerInput>
          {fileSelected && (
            <FileNameDisplayWrapper
              gutter={20}
              justify={'start'}
              align={'middle'}
            >
              <BaseCol>
                <BaseText
                  fontSize="xs"
                  fontWeight="medium"
                  colorText="statesGreenColor"
                >
                  {fileSelected?.name}
                </BaseText>
              </BaseCol>
              <BaseCol>
                <BaseButton
                  icon={<DeleteFilled rev={undefined} onClick={onRemoveFile} />}
                  size="small"
                />
              </BaseCol>
            </FileNameDisplayWrapper>
          )}

          {fileSelected ? (
            <BaseSpace size={8}>
              <BaseRow
                align={'middle'}
                justify={'space-between'}
                gutter={[8, 8]}
              >
                <BaseCol>
                  <BaseText
                    children={t(R.strings.list_price_service)}
                    fontWeight="medium"
                    fontSize="xs"
                    opacity="0.7"
                  />
                </BaseCol>
                <BaseCol>
                  {/* <BaseRow gutter={8} align={'middle'}>
                    <BaseCol>
                      <BaseText
                        children={`${successData?.length} ${t(
                          R.strings.success,
                        )}`}
                        fontWeight="medium"
                        colorText="statesGreenColor"
                      />
                    </BaseCol>
                    <BaseCol>
                      <BaseText
                        children={`${errorData?.length} ${t(
                          R.strings.error_vn_text,
                        )}`}
                        fontWeight="medium"
                        colorText="statesRedColor"
                      />
                    </BaseCol>
                  </BaseRow> */}
                </BaseCol>
              </BaseRow>
              <BaseTableManagement
                pagination={{
                  pageSize: 5,
                }}
                columns={[
                  convertColumnTable<IResponseItemPrice>({
                    title: t(R.strings.name),
                    key: 'name',
                    fixed: 'left',
                    classNameWidthColumnOverwrite: 'very-big-column',
                    render: (_, record) => {
                      return (
                        <ItemWrapper>
                          <ProfileServiceComponent
                            itm={{ name: record.name || '' }}
                          />
                        </ItemWrapper>
                      )
                    },
                  }),
                  convertColumnTable<IResponseItemPrice>({
                    title: t(R.strings.unit_column),
                    key: '_id',
                    fixed: 'left',
                    classNameWidthColumnOverwrite: 'small-column',
                    render: () => `Bộ`,
                  }),
                  convertColumnTable<IResponseItemPrice>({
                    title: t(R.strings.sku_column),
                    key: 'code',
                    fixed: 'left',
                    classNameWidthColumnOverwrite: 'small-column',
                    render: value => value,
                  }),
                  convertColumnTable<IResponseItemPrice>({
                    title: t(R.strings.barcode_column),
                    key: 'barCode',
                    fixed: 'left',
                    classNameWidthColumnOverwrite: 'small-column',
                    render: value => (
                      <BaseText
                        fontSize="xxs"
                        fontWeight="medium"
                        colorText="mainColorText"
                      >
                        {value || ''}
                      </BaseText>
                    ),
                  }),
                  convertColumnTable<IResponseItemPrice>({
                    title: t(R.strings.barcode_column),
                    key: 'price',
                    fixed: 'left',
                    classNameWidthColumnOverwrite: 'small-column',
                    render: value => formatMoney(value),
                  }),
                  // convertColumnTable<IResponseItemPrice>({
                  //   key: 'status',
                  //   title: t(R.strings.status),
                  //   render: value => {
                  //     return (
                  //       <BaseText
                  //         children={
                  //           value ? t(DefineStatusEnum[value]?.text) : '-'
                  //         }
                  //         fontWeight="medium"
                  //         style={
                  //           value
                  //             ? {
                  //                 color: DefineStatusEnum[value]?.color,
                  //               }
                  //             : undefined
                  //         }
                  //       />
                  //     )
                  //   },
                  // }),
                ]}
                dataSource={dataTreatment}
                loading={isLoading}
                scroll={{ x: true }}
              />
            </BaseSpace>
          ) : null}
        </BaseSpace>
      }
    />
  )
}

const DraggerInput = styled(BaseButtonsForm.Item)``

const DraggerWrapper = styled.div`
  padding: ${PADDING.xxl} 0px;
  & svg {
    fill: var(--gray);
  }
`

const IconMailBox = styled(InboxOutlined)`
  & svg {
    width: ${FONT_SIZE.xxxl};
    height: ${FONT_SIZE.xxxl};
  }
`

const FileNameDisplayWrapper = styled(BaseRow)`
  color: var(--primary-color);
  padding-top: ${PADDING.xxs};
  & svg {
    fill: red;
  }
`
const ItemWrapper = styled(BaseRow)`
  cursor: pointer;
`
