import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const SectionBaseWrapper = styled.div`
  padding: ${PADDING.xl} !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  background: var(--white);
`
export const Container = styled.div`
  padding-top: 68px;
  border-radius: 8px;
  background: linear-gradient(90deg, #edeef0 0%, #f9fafc 100%);
`
export const AvatarCol = styled(BaseCol)`
  width: 128px !important;
  height: 60px !important;
`
export const NameCol = styled(BaseCol)`
  margin-left: 32px;
`

export const AvatarProfile = styled(BaseAvatar)`
  width: 128px !important;
  height: 128px !important;
  border-radius: 64px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: ${FONT_SIZE.xxxxl};
  font-weight: ${FONT_WEIGHT.semibold};
  position: absolute;
  bottom: 0px;
`

export const TextNameProfile = styled.div`
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: 20px;
`

export const TextJobTitleProfile = styled.div`
  color: #858c94;
  font-weight: ${FONT_WEIGHT.regular};
  font-size: 14px;
  margin-top: 8px;
`

export const StatusChip = styled(BaseRow)`
  background: linear-gradient(317deg, #2f7eff, #659fff);
  padding: 4px 12px;
  color: white;
  border-radius: ${PADDING.xxxl};
  text-align: center;
  width: fit-content;
  color: var(--white);
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: 12px;
`
export const Dot = styled.div`
  width: 4px;
  height: 4px;
  background: var(--white);
  border-radius: 8px;
  margin-right: 4px;
`
