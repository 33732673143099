import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormWorkInfoModalData, IFormWorkInfoModalRule } from './type'
import { StatusWorkInfosType } from '.'
import { StatusOptionsCreateEnum } from 'app/common/enum'

interface IPropsUseModalHook {
  handleSubmit: (values) => void
}

export const useModalHook = ({ handleSubmit }: IPropsUseModalHook) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm()
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState<StatusWorkInfosType | undefined>()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinish = async (values: IFormWorkInfoModalData[]) => {
    handleSubmit({ ...values, status: StatusOptionsCreateEnum.active })
    form.resetFields()
    setVisible(false)
  }

  const rules: IFormWorkInfoModalRule = useMemo(() => {
    return {
      jobTitle: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.profile_content_job_title_main_column),
          }),
        },
      ],
      department: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.department),
          }),
        },
      ],
      start_date: [
        {
          required: true,
          message: t(R.strings.choose_date),
        },
      ],
      end_date: [
        {
          required: true,
          message: t(R.strings.choose_date),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    showModal,
    onFinish,
    handleCancel,
    rules,
    setStatus,
    status,
  }
}
