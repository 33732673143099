import { IProfileUser } from 'app/api/profile/model/profile'
import R from 'app/assets/R'
import { SvgCertificateIcon } from 'app/assets/svg-assets'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { useTranslation } from 'react-i18next'
import {
  Divider,
  SectionBaseWrapper,
  SectionDescription,
  SectionTitle,
  TextDegreeName,
} from './styles'
import { DetailItem } from './DetailItem'

interface IProps {
  profile?: IProfileUser
}

export const Degree = (props: IProps) => {
  const { profile } = props
  const { t } = useTranslation()

  const DegreeList = () => {
    return (
      <BaseSpace>
        {profile?.degrees?.map((item, index) => {
          return (
            <BaseRow key={index} align={'middle'} justify={'space-between'}>
              <SvgCertificateIcon />
              <TextDegreeName>
                {item?.degreeDetail?.name || item?.degree?.name}
              </TextDegreeName>
              <DetailItem data={item} />
            </BaseRow>
          )
        })}
      </BaseSpace>
    )
  }

  if (!profile?.degrees || profile?.degrees?.length <= 0) {
    return null
  }

  return (
    <SectionBaseWrapper>
      <SectionTitle>{t(R.strings.degree_field_degree)}</SectionTitle>
      <SectionDescription>
        {t(R.strings.certification_description)}
      </SectionDescription>
      <Divider />
      <DegreeList />
    </SectionBaseWrapper>
  )
}
