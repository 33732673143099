import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import PricingManagementContainer from 'app/containers/Marketing/PricingManagement'

const PricingManagementPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.pricing_management)}</PageTitle>
      <PricingManagementContainer />
    </>
  )
}
const PricingManagementPage = memo(PricingManagementPageCpn, isEqual)

export default PricingManagementPage
