import R from 'app/assets/R'
import { SvgPencilTimeIcon } from 'app/assets/svg-assets'
import { IRefModal } from 'app/components/common/ModalComponent'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useModalHook } from './hook'
import {
  BaseTableReport,
  HistoryModal,
  Option,
  OptionText,
  TitleText,
} from './styles'

export const UpdateHistoryModal = () => {
  const { t } = useTranslation()
  const {
    data,
    columns,
    pagination,
    handleTableChange,
    visible,
    showModal,
    handleCancel,
  } = useModalHook()

  const modalRef = useRef<IRefModal>(null)
  const onClick = () => {
    modalRef?.current?.open?.()
  }
  return (
    <>
      <Option onClick={showModal}>
        <SvgPencilTimeIcon />
        <OptionText
          children={t(R.strings.update_history)}
          fontWeight="medium"
          opacity="0.7"
        />
      </Option>
      <HistoryModal
        open={visible}
        width={1100}
        footer={<></>}
        onCancel={handleCancel}
      >
        <TitleText children={t(R.strings.update_history)} />
        <BaseTableReport
          columns={columns}
          dataSource={Array(5).fill('')}
          pagination={pagination}
          onChange={handleTableChange}
          rowClassName="row-overwrite-style"
          scroll={{
            x: true,
          }}
        />
      </HistoryModal>
    </>
  )
}
