import { SearchOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useDebounce, usePagination } from 'app/hook'
import { useGetItemList } from 'app/react-query/hook/item'
import { isEqual } from 'lodash'
import { initialPagination, Pagination } from 'parkway-web-common'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ProfileServiceComponent } from '..'
import { IItemManagement } from 'app/api/item/model/management'
import BaseText from 'app/components/common/BaseText'
import { BasePagination } from 'app/components/common/BasePagination'
import { BaseTreeSelect } from 'app/components/common/selects/BaseTreeSelect'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { transformDataSelectTree } from 'app/components/common/selects/BaseTreeSelect/helper'
import { StatusEnum } from 'app/common/enum'
import { useGetCategoryTreatmentList } from 'app/react-query/hook/category'
import { IResponseQueryList } from 'app/react-query/model/common'
import { ICategoryTreatment } from 'app/api/category/model'

export const ButtonModalAddItems = ({
  services,
  onChangeService,
  children,
}: {
  services?: IItemManagement[]
  onChangeService?: (services: IItemManagement[]) => void
  children: JSX.Element
}) => {
  const { t } = useTranslation()
  const modalRef = useRef<IRefModal>({})
  const { flattenDataList } = usePagination()
  const [servicesSelected, setServicesSelected] = useState<IItemManagement[]>(
    [],
  )

  const [pagination, setPagination] = useState<Pagination>({
    ...initialPagination,
    pageSize: 5,
  })

  const [filter, setFilter] = useState<{
    search?: string
    type: string[]
    categoryid?: string
  }>({ type: ['product', 'service'] })

  const searchDebouce = useDebounce(filter.search, 1000)

  const { data: itemListApi, isLoading } = useGetItemList({
    keyword: searchDebouce,
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
    type: filter.type,
    categoryid: filter.categoryid,
  })

  const { data: categoryTreatmentData, isLoading: isLoadingCategoryTreatment } =
    useGetCategoryTreatmentList({ status: StatusEnum.ACTIVE, pagesize: 1000 })

  const categoryTreatments = useMemo(() => {
    const flattenData: IResponseQueryList<ICategoryTreatment[]> =
      flattenDataList(categoryTreatmentData)
    return flattenData?.data ?? []
  }, [categoryTreatmentData])

  const dataItemList = useMemo(() => {
    const res: { data: IItemManagement[]; total: number } =
      flattenDataList(itemListApi)
    return res
  }, [itemListApi])

  const data = useMemo(() => {
    return dataItemList?.data?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [dataItemList?.data])

  const handleOpenModal = () => {
    modalRef.current?.open?.()
  }

  const onChangePagination = (page: number, pageSize: number) => {
    setPagination({ ...pagination, current: page, pageSize })
  }

  const handleSelectService = (record: IItemManagement) => {
    const isSelected = !!servicesSelected?.find(item =>
      isEqual(item._id, record?._id),
    )

    if (isSelected) {
      setServicesSelected(
        servicesSelected.filter(item => !isEqual(item._id, record?._id)),
      )
    } else {
      setServicesSelected([...servicesSelected, record])
    }
  }

  const handleSaveService = () => {
    onChangeService?.(servicesSelected)
    modalRef.current?.hide?.()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setServicesSelected(services ?? [])
    }, 500)
    return () => clearTimeout(timer)
  }, [services])

  const columns = useMemo(() => {
    return [
      convertColumnTable<IItemManagement>({
        title: t(R.strings.name),
        key: 'name',
        fixed: 'left',
        // className: 'name-service-column',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          return (
            <ItemWrapper>
              <ProfileServiceComponent itm={{ name: record.name || '' }} />
            </ItemWrapper>
          )
        },
      }),
      convertColumnTable<IItemManagement>({
        title: t(R.strings.unit_column),
        key: '_id',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'small-column',
        render: () => (
          <BaseText
            fontSize="xxs"
            fontWeight="medium"
            colorText="mainColorText"
          >
            Bộ
          </BaseText>
        ),
      }),
      convertColumnTable<IItemManagement>({
        title: t(R.strings.sku_column),
        key: 'code',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'small-column',
        render: value => (
          <BaseText
            fontSize="xxs"
            fontWeight="medium"
            colorText="mainColorText"
          >
            {value}
          </BaseText>
        ),
      }),
      convertColumnTable<IItemManagement>({
        title: t(R.strings.barcode_column),
        key: 'barCode',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'small-column',
        render: value => (
          <BaseText
            fontSize="xxs"
            fontWeight="medium"
            colorText="mainColorText"
          >
            {value || ''}
          </BaseText>
        ),
      }),
      convertColumnTable<IItemManagement>({
        title: '',
        key: '_id',
        width: '5%',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => {
          const isSelected = !!servicesSelected?.find(item =>
            isEqual(item._id, record?._id),
          )
          return (
            <BaseCheckbox
              checked={isSelected}
              onChange={() => handleSelectService(record)}
            />
          )
        },
      }),
    ]
  }, [t, servicesSelected])

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(prev => {
      return {
        ...prev,
        search: e.target.value,
      }
    })
    setPagination({ ...pagination, current: 1 })
  }

  const onChangeSelectCategory = (categoryid: string) => {
    setFilter(prev => {
      return {
        ...prev,
        categoryid,
      }
    })
  }

  return (
    <ModalComponent
      buttonOpenCustom={<BaseRow onClick={handleOpenModal}>{children}</BaseRow>}
      titleModal={R.strings.add_item_price_service}
      ref={modalRef}
      widthModal={1000}
      handleSubmit={handleSaveService}
      renderContent={
        <BaseForm>
          <RootWrapper>
            <BaseRow gutter={[8, 16]} align={'middle'}>
              <BaseCol span={12}>
                <BaseForm.Item>
                  <TreeSelectStyled
                    onChange={type => onChangeSelectCategory(type as string)}
                    placeholder={t(
                      R.strings.item_management_content_select_category,
                    )}
                    suffixIcon={<SvgTableCloseIcon />}
                    treeData={transformDataSelectTree(categoryTreatments, {
                      title: 'name',
                      value: '_id',
                    })}
                    allowClear
                  />
                </BaseForm.Item>
              </BaseCol>

              <BaseCol span={12}>
                <BaseForm.Item>
                  <BaseInput
                    prefix={<SearchOutlined rev={undefined} />}
                    placeholder={t(R.strings.enter_service_name)}
                    value={filter.search}
                    onChange={onChangeSearch}
                  />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>

            <BaseTableManagement
              columns={columns}
              dataSource={data}
              loading={isLoading || isLoadingCategoryTreatment}
              pagination={false}
              onRow={(record) => {
                return {
                  onClick: () => {
                    handleSelectService(record)
                  },
                }
              }}
              footer={() => (
                <BaseRow align="middle" justify="space-between">
                  <BaseCol>
                    <BaseRow gutter={[8, 16]} align={'middle'}>
                      <BaseCol>
                        <BaseText
                          fontWeight="regular"
                          colorText="mainColorText"
                          fontSize="xs"
                        >
                          {t(R.strings.total_selected)}:
                        </BaseText>
                      </BaseCol>
                      <BaseCol>
                        <BaseText
                          fontWeight="regular"
                          colorText="statesGreenColor"
                          fontSize="md"
                        >
                          {servicesSelected.length}
                        </BaseText>
                      </BaseCol>
                    </BaseRow>
                  </BaseCol>
                  <BaseCol>
                    <BasePagination
                      total={dataItemList?.total ?? 0}
                      current={pagination.current}
                      pageSize={pagination.pageSize}
                      onChange={onChangePagination}
                    />
                  </BaseCol>
                </BaseRow>
              )}
              rowClassName="row-overwrite-style"
              scroll={{ x: true }}
            />
          </RootWrapper>
        </BaseForm>
      }
    />
  )
}

const RootWrapper = styled(BaseSpace)`
  .ant-table-wrapper .ant-table .ant-table-header {
    border-radius: 0px !important;
  }

  .ant-table-thead > tr > th {
    border: 0px !important;
  }

  .row-overwrite-style .ant-table-cell {
    border-left: 0px !important;
    border-right: 0px !important;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0px;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0px !important;
  }
`
const ItemWrapper = styled(BaseRow)`
  cursor: pointer;
`

export const TreeSelectStyled = styled(BaseTreeSelect)`
  .ant-select-selector {
    border: 1px solid ${convertedVariables.neutralBlack2Color} !important;
  }
`
