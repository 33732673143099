import R from 'app/assets/R'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { ButtonAction } from 'app/components/header/components/HeaderPage/styles'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
  title: string
  descriptions: string[]
  onDelete: () => void
  children?: JSX.Element | React.ReactNode
}

export const ButtonModalNotifyDelete = (props: IProps) => {
  const { onDelete, title, descriptions, children } = props
  const { t } = useTranslation()

  const modalRef = useRef<IRefModal>({})

  const handleOpenModal = () => {
    modalRef.current?.open?.()
  }

  const handleCloseModal = () => {
    modalRef.current?.hide?.()
  }

  const handleSaveService = () => {
    // onChangeService?.(servicesSelected)
    modalRef.current?.hide?.()
  }

  const handleDelete = () => {
    onDelete()
    handleCloseModal()
  }

  return (
    <ModalComponent
      buttonOpenCustom={
        <BaseRow onClick={handleOpenModal}>
          {children ? (
            children
          ) : (
            <BaseButton icon={<SvgDeleteDesignIcon />} type="link" />
          )}
        </BaseRow>
      }
      titleModal={title}
      titleAlignModal="center"
      ref={modalRef}
      widthModal={1000}
      handleSubmit={handleSaveService}
      renderContent={
        <BaseRow gutter={[8, 16]}>
          <BaseCol span={24}>
            <BaseRow>
              {descriptions.map(description => (
                <BaseCol span={24}>
                  <BaseText
                    textAlign="center"
                    colorText="mainColorText"
                    fontWeight="medium"
                    fontSize="md"
                  >
                    {description}
                  </BaseText>
                </BaseCol>
              ))}
            </BaseRow>
          </BaseCol>
        </BaseRow>
      }
      footer={
        <BaseRow justify={'center'} gutter={[8, 16]}>
          <BaseCol>
            <ButtonAction onClick={handleCloseModal}>
              {t(R.strings.cancel)}
            </ButtonAction>
          </BaseCol>
          <BaseCol>
            <ButtonSubmit onClick={handleDelete}>
              {t(R.strings.confirm)}
            </ButtonSubmit>
          </BaseCol>
        </BaseRow>
      }
    />
  )
}

const ButtonSubmit = styled(BaseButton)`
  min-width: 120px;
  background-color: var(--other-blue-color);
  color: var(--background-color);
  border-color: var(--other-blue-color);
  &:hover {
    color: var(--background-color) !important;
    border-color: var(--other-blue-color);
  }
`
