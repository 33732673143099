/* eslint-disable */
import { t } from 'i18next'
import { EYesNo, StatusEnum, StatusTableType } from './enum'
import R from 'app/assets/R'
import moment from 'moment'
import { FORMAT_CENTER_YYYY_MM_DD } from 'parkway-web-common'
import { StatusTableEnum } from 'app/common/enum'
import { IMAGE_ACTION, TIMESLOT_STEP } from './config'
enum ExecuteTime {
  min = 15,
  max = 1440,
  step = 15,
  default = 30,
}

const convertVietnameseString = (input: string): string => {
  const withAccents =
    'àáảãạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệìíỉĩịòóỏõọôồốổỗộơờớởỡợùúủũụưừứửữựỳýỷỹỵđ'
  const withoutAccents =
    'aaaaaaaaaaaaaaaaaeeeeeeeeeeeiiiiiooooooooooooooooouuuuuuuuuuuyyyyyd'

  const convertedString = input
    .split('')
    .map(char => {
      const index = withAccents.indexOf(char)
      return index !== -1 ? withoutAccents[index] : char
    })
    .join('')

  return convertedString.toLowerCase()
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const convertYesNoToString = (
  enumType: EYesNo,
): string | EYesNo | undefined => {
  switch (enumType) {
    case EYesNo.no:
      return t(R.strings.no)
    case EYesNo.yes:
      return t(R.strings.yes)
    default:
      return enumType
  }
}

const days = [0, 1, 2, 3, 4, 5, 6]

const convertToDayOfWeek = (number: number): string => {
  const daysOfWeek = [
    t(R.strings.days_of_week_sunday),
    t(R.strings.days_of_week_monday),
    t(R.strings.days_of_week_tuesday),
    t(R.strings.days_of_week_wednesday),
    t(R.strings.days_of_week_thursday),
    t(R.strings.days_of_week_friday),
    t(R.strings.days_of_week_saturday),
  ]

  if (number >= 0 && number < daysOfWeek.length) {
    return daysOfWeek[number]
  } else {
    return 'Invalid day number'
  }
}

const generateRandomString = (length: number): string => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let randomString = ''

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    randomString += characters.charAt(randomIndex)
  }

  return randomString
}

const getRandomArbitrary = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const getTotalWeekNumbersOfMonth = (year: number, month: number) => {
  // NOTE: month start from 0 but using start from 1 for human readable -> so month - 1 for calculate
  let now = moment([year, month - 1])
  // First week
  let firstWeekDays = 7 - now.startOf('month').isoWeekday() + 1
  let weeks = 1
  let rest = now.daysInMonth() - firstWeekDays
  // Middle rows
  let middleRows = Math.floor(rest / 7)
  weeks = weeks + middleRows
  rest = rest - middleRows * 7
  // Last row?
  if (rest > 0) {
    weeks = weeks + 1
  }

  return weeks
}

export const getStartEndDateOfWeek = ({
  date,
  year,
  month,
}: {
  date: string | Date
  year: string
  month: string
}) => {
  const selectedDateUsingMonth = moment(
    `${year}-${+month < 10 ? `0${+month}` : month}-01`,
  ).add(1, 'day')
  const startOfMonth = selectedDateUsingMonth.clone().startOf('month')
  const endOfMonth = selectedDateUsingMonth.clone().endOf('month')

  const minDayOfMonth = moment(startOfMonth, FORMAT_CENTER_YYYY_MM_DD)
  const maxDayOfMonth = moment(endOfMonth, FORMAT_CENTER_YYYY_MM_DD)

  const selectedDate = moment(date, FORMAT_CENTER_YYYY_MM_DD)
  let startOfWeek = selectedDate.clone().startOf('isoWeek')
  let endOfWeek = selectedDate.clone().endOf('isoWeek')

  if (startOfWeek.isBefore(minDayOfMonth)) {
    startOfWeek = minDayOfMonth
  }

  if (endOfWeek.isAfter(maxDayOfMonth)) {
    endOfWeek = maxDayOfMonth
  }

  const endDateOfFirstWeek = selectedDateUsingMonth.clone().endOf('isoWeek')

  let noCurrentWeekOfMonth = 1
  if (selectedDate.isAfter(endDateOfFirstWeek)) {
    const numDateBetweenEndDateOfFirstWeekToCurrentDate = Math.abs(
      endDateOfFirstWeek.diff(selectedDate, 'days'),
    )
    if (!numDateBetweenEndDateOfFirstWeekToCurrentDate) {
      noCurrentWeekOfMonth += 1
    } else {
      const weekOfMonth = numDateBetweenEndDateOfFirstWeekToCurrentDate / 7
      noCurrentWeekOfMonth += Math.floor(weekOfMonth) + 1
    }
  }

  return {
    startOfWeek: startOfWeek.add(1, 'day').toISOString(),
    endOfWeek: endOfWeek.toISOString(),
    noCurrentWeekOfMonth,
  }
}
const convertStatusToFilterStatus = (statusFilter?: StatusEnum): string => {
  switch (statusFilter) {
    case StatusEnum.ACTIVE:
      return StatusTableEnum.active
    // case StatusEnum.BLOCK:
    //   return StatusTableEnum.error
    case StatusEnum.DELETED:
      return StatusTableEnum.deleted
    case StatusEnum.INACTIVE:
      return StatusTableEnum.inactive
    default:
      return StatusTableEnum.active
  }
}

const checkTimeConflict = (startCurrent, endCurrent, startNext, endNext) => {
  const conflict =
    startCurrent.isSameOrAfter(endNext) || endCurrent.isSameOrBefore(startNext)

  return !conflict
}

const checkAppointmentTimeConflict = (
  prevAppointment,
  currentAppointment,
  nextAppointment,
) => {
  const startPrev = moment(`${prevAppointment?.date} ${prevAppointment?.time}`)
  const endPrev = moment(
    `${prevAppointment?.date} ${prevAppointment?.time}`,
  ).add(prevAppointment?.duration || TIMESLOT_STEP, 'minutes')

  const startCurrent = moment(
    `${currentAppointment?.date} ${currentAppointment?.time}`,
  )
  const endCurrent = moment(
    `${currentAppointment?.date} ${currentAppointment?.time}`,
  ).add(currentAppointment?.duration || TIMESLOT_STEP, 'minutes')

  const startNext = moment(`${nextAppointment?.date} ${nextAppointment?.time}`)
  const endNext = moment(
    `${nextAppointment?.date} ${nextAppointment?.time}`,
  ).add(nextAppointment?.duration || TIMESLOT_STEP, 'minutes')

  const isConflictNext = Object.keys(nextAppointment || {}).length
    ? checkTimeConflict(startCurrent, endCurrent, startNext, endNext)
    : false

  const isConflictPrev = Object.keys(prevAppointment || {}).length
    ? checkTimeConflict(startPrev, endPrev, startCurrent, endCurrent)
    : // (startPrev?.toDate() < startCurrent?.toDate() &&
      //   startCurrent?.toDate() < endPrev?.toDate())
      false

  return {
    isConflictNext,
    isConflictPrev,
  }
}

const secondsToTime = time => {
  const date = new Date(0)
  date.setSeconds(time) // specify value for SECONDS here
  return date.toISOString().substring(11, 19)
}

const convertStatusFilterToStatusEnum = (
  statusFilter: any,
): StatusEnum | undefined => {
  switch (statusFilter) {
    case StatusTableEnum.active:
      return StatusEnum.ACTIVE
    // case StatusTableEnum.error:
    //   return StatusEnum.BLOCK
    case StatusTableEnum.deleted:
      return StatusEnum.DELETED
    case StatusTableEnum.inactive:
      return StatusEnum.INACTIVE
    default:
      return undefined
  }
}

const generateExecuteTimeArray = (
  min = ExecuteTime.min,
  max = ExecuteTime.max,
  step = ExecuteTime.step,
): number[] => {
  const array: number[] = []
  for (let i: number = min; i <= max; i += step) {
    array.push(i)
  }
  return array
}

const showExecuteTime = minutes => {
  if (minutes < 60) {
    return `${minutes} ${t(R.strings.minute)}`
  } else {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60
    if (remainingMinutes === 0) {
      return `${hours} ${t(R.strings.hour)}`
    } else {
      return `${hours} ${t(R.strings.hour)} ${remainingMinutes} ${t(
        R.strings.minute,
      )}`
    }
  }
}

const appendIds = ([...stringId]: string[]): string => stringId.join('_')

/**
Returns all possible combinations of the elements in each array.
@param arraysToCombine an array of arrays
@returns an array of arrays, where each sub-array is a combination of the elements in the corresponding original array
*/
const getAllCombinations = (arraysToCombine: any) => {
  const divisors = [] as any
  let combinationsCount = 1
  for (let i = arraysToCombine.length - 1; i >= 0; i--) {
    divisors[i] = divisors[i + 1]
      ? divisors[i + 1] * arraysToCombine[i + 1].length
      : 1
    combinationsCount *= arraysToCombine[i].length || 1
  }

  const getCombination = (n: number, arrays: any, divisors: any) =>
    arrays.reduce((acc: any, arr: any, i: any) => {
      acc.push(arr[Math.floor(n / divisors[i]) % arr.length])
      return acc
    }, [])

  const combinations = [] as any
  for (let i = 0; i < combinationsCount; i++) {
    combinations.push(getCombination(i, arraysToCombine, divisors))
  }
  return combinations
}

function getTextByHtml(htmlString: string) {
  return htmlString.replace(/<[^>]+>/g, '')
}

const MinuteToMillisecond = (minute: number) => minute * 60 * 1000

const addPrefixImage = (path?: string): string | undefined => {
  return path ? `${IMAGE_ACTION.IMAGE_SERVER}/${unescape(path)}` : path
}

function convertArrayStatusFilterToArrayStatusNumber(
  statusFilter?: StatusTableType[],
): number[] {
  let status: number[] = []
  statusFilter?.map(item => {
    switch (item) {
      case 'active':
        status = status.concat(StatusEnum.ACTIVE)
        break
      case 'deleted':
        status = status.concat(StatusEnum.DELETED)
        break
      // case 'error':
      //   status = status.concat(StatusEnum.BLOCK)
      //   break
      case 'inactive':
        status = status.concat(StatusEnum.INACTIVE)
        break
    }
  })
  return status
}

const getDetailPatientUrl = (patientId?: string) => {
  return `https://admin.nhakhoaparkway.com/patients/details/${patientId}#info`
}

const convertToRegionName = (region?: number) => {
  switch (region) {
    case 1:
      return t(R.strings.hochiminh)
    case 2:
      return t(R.strings.hanoi)
    case 3:
      return t(R.strings.province)

    default:
      return ''
  }
}

const removeDuplicates = <T = any>(array: T[],keyId:string) => {
  const uniqueIds = new Set()
  return array.filter(item => {
    if (!uniqueIds.has(item[keyId])) {
      uniqueIds.add(item[keyId])
      return true
    }
    return false
  })
}

export {
  convertArrayStatusFilterToArrayStatusNumber,
  convertStatusToFilterStatus,
  convertStatusFilterToStatusEnum,
  convertVietnameseString,
  capitalizeFirstLetter,
  convertYesNoToString,
  convertToDayOfWeek,
  generateRandomString,
  getRandomArbitrary,
  days,
  checkAppointmentTimeConflict,
  secondsToTime,
  generateExecuteTimeArray,
  showExecuteTime,
  ExecuteTime,
  appendIds,
  getTextByHtml,
  getTotalWeekNumbersOfMonth,
  MinuteToMillisecond,
  getAllCombinations,
  addPrefixImage,
  getDetailPatientUrl,
  convertToRegionName,
  removeDuplicates,
}
