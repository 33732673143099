import R from 'app/assets/R'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import HeaderPage from 'app/components/header/components/HeaderPage'
import styled from 'styled-components'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseFormItem } from 'app/components/common/forms/components/BaseFormItem'
import { useHook } from './hook'
import { ButtonModalNotifyDelete, FormContent, FormInfo } from '../components'
import { BaseRow } from 'app/components/common/BaseRow'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BaseCol } from 'app/components/common/BaseCol'
import { EditFilled } from '@ant-design/icons'
import BaseText from 'app/components/common/BaseText'
import { ButtonAction } from 'app/components/header/components/HeaderPage/styles'
import { SvgTrashWithoutColorIcon } from 'app/assets/svg-assets'
import { BaseDivider } from 'app/components/common/BaseDivider'

const DetailItemPriceContainer = () => {
  const { t } = useTranslation()
  const {
    form,
    onUpdate,
    onExportExcel,
    isFetchingExport,
    onDelete,
    isLoadingDeleteItemPrice,
  } = useHook()

  const { user, isHavePermissionFunctionAndGoBack } =
    useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.ITEM_PRICE_READ,
      R.strings.detail_item_price,
    )
  }, [user])

  return (
    <S.BaseManagementWrapper>
      <BaseForm form={form}>
        <BaseFormItem hiddenLabel noStyle>
          <HeaderPage
            title={''}
            isExport
            onPressExport={onExportExcel}
            isLoadingExport={isFetchingExport}
            rightComponent={
              <BaseRow>
                <BaseCol>
                  <ButtonWrapper
                    align={'middle'}
                    wrap={false}
                    onClick={onUpdate}
                  >
                    <BaseRow gutter={8} align={'middle'}>
                      <BaseCol>
                        <EditFilled rev={undefined} />
                      </BaseCol>
                      <BaseCol>
                        <BaseText
                          fontWeight="medium"
                          colorText="backgroundColor"
                        >
                          {t(R.strings.update_item_price)}
                        </BaseText>
                      </BaseCol>
                    </BaseRow>
                  </ButtonWrapper>
                </BaseCol>
                <BaseRow align={'middle'}>
                  <DeviderWrapper type="vertical" />
                </BaseRow>
                <BaseCol>
                  <ButtonModalNotifyDelete
                    title={t(R.strings.title_delete_item_price)}
                    descriptions={[
                      t(R.strings.notify_delete_item_price_description_1),
                      t(R.strings.notify_delete_item_price_description_2),
                    ]}
                    onDelete={onDelete}
                  >
                    <ButtonAction loading={isLoadingDeleteItemPrice}>
                      <BaseRow justify={'center'} align={'middle'}>
                        <SvgTrashWithoutColorIcon />
                      </BaseRow>
                    </ButtonAction>
                  </ButtonModalNotifyDelete>
                </BaseCol>
              </BaseRow>
            }
          />
        </BaseFormItem>
        <RootContentWrapper>
          <FormInfo isEditable={false} />
          <FormContent form={form} isEditable={false} />
        </RootContentWrapper>
      </BaseForm>
    </S.BaseManagementWrapper>
  )
}

export default DetailItemPriceContainer

const RootContentWrapper = styled(BaseSpace)`
  padding-bottom: ${PADDING.md};
  position: relative;
`

const ButtonWrapper = styled(BaseRow)`
  padding-right: ${PADDING.xxxxs};
  padding-left: ${PADDING.xxxxs};
  height: 100%;
  background-image: linear-gradient(90deg, #fd7b59, #f75428);
  border-radius: ${BORDER_RADIUS};
  cursor: pointer;
  svg {
    fill: ${convertedVariables.backgroundColor};
  }
`

const DeviderWrapper = styled(BaseDivider)`
  height: 70%;
`
