import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { Pagination, moment } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable } from './type'
import { IWorkInfo } from 'app/model/user.model'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { Profile } from './styles'

export const useModalHook = () => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState<Pagination>({
    pageSize: 7,
    current: 1,
  })
  const data = []

  const [visible, setVisible] = useState(false)

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IDataTable>({
        title: t(R.strings.profile_content_job_title),
        key: 'jobtitle',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record: IWorkInfo) => {
          return (
            <BaseText
              children={'Design Lead'}
              fontWeight="semibold"
              opacity="0.7"
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.department),
        key: 'department',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IWorkInfo) => {
          return (
            <BaseText
              children={'IT PW'}
              fontWeight="medium"
              opacity="0.7"
              fontSize="xxs"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.start_date),
        key: 'start_date',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IWorkInfo) => {
          return (
            <BaseText
              children={moment()?.format('DD/MM/YYYY')}
              fontWeight="medium"
              opacity="0.7"
              fontSize="xxs"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.end_date),
        key: 'end_date',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record: IWorkInfo) => {
          return (
            <BaseText
              children={moment()?.format('DD/MM/YYYY')}
              fontWeight="medium"
              fontSize="xxs"
              opacity="0.7"
              textAlign="right"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.status),
        key: 'status',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record: IWorkInfo) => {
          return (
            <BaseText
              children={''}
              fontWeight="medium"
              fontSize="xxs"
              opacity="0.7"
              textAlign="right"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.updated_at),
        key: 'updated_at',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record: IWorkInfo) => {
          return (
            <BaseText
              children={moment()?.format('DD/MM/YYYY')}
              fontWeight="medium"
              fontSize="xxs"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.updated_person),
        key: 'updated_person',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record: IWorkInfo) => {
          return (
            <Profile name="Phan Hồng Vinh" jobTitle="Front-end Developer" />
          )
        },
      }),
    ]
    return option
  }, [t])

  const handleTableChange = (newPagination: Pagination) => {
    setPagination(newPagination)
  }

  return {
    data,
    columns,
    handleTableChange,
    pagination: {
      ...pagination,
    },
    visible,
    showModal,
    handleCancel,
  }
}
