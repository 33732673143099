import { Divider } from 'antd'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelectSearch } from 'app/components/common/selects/SelectSearch'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'

import styled from 'styled-components'

export const BaseRowStyled = styled(BaseRow)`
  align-items: flex-start;
  gap: 20px;
  justify-content: space-between;
  border-bottom: 1px solid #f0f1f3;

  &:last-child {
    border-bottom: none;
  }
`

export const FormItem = styled(BaseForm.Item)``

export const SelectSearchBaseStyled = styled(BaseSelectSearch)``

export const ButtonActionGroup = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`
export const ButtonStyled = styled.a`
  background-color: #fff;
  display: flex;
  padding: 8px;

  &:hover {
    color: #1677ff;
  }
`

export const WorkInfoItem = styled(BaseCol)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 60%;
  max-width: 60%;
`

export const TitleFormList = styled(BaseText)`
  font-size: ${FONT_SIZE.xxs} !important;
  font-weight: ${FONT_WEIGHT.semibold} !important;
  color: var(--text-primary2-color);
`
export const UnitText = styled(BaseText)`
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.semibold} !important;
  color: var(--text-primary2-color);
  margin-left: 8px;
  opacity: 0.7;
`
export const DashDivider = styled(Divider)`
  margin: 0;
`
export const UnitContainer = styled(BaseSpace)`
  margin: 20px 0;
  gap: 10px !important;
`
export const DateText = styled(BaseText)`
  font-size: 10px !important;
  font-weight: ${FONT_WEIGHT.regular} !important;
  color: var(--text-primary2-color);
  opacity: 0.7;
  margin: 0 24px;
`

export const ClassificationWrapper = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;

  & span {
    font-size: ${FONT_SIZE.xxs};
    color: var(--text-primary2-color);
  }

  .tag {
    background-color: #f8f8f8;
    border-radius: 100px;
    padding: 2px 1rem;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.semibold};
  }

  .excellent {
    background-color: #fffbed;
    color: #fa9f34;
  }

  .good {
    background-color: #ecfdf2;
    color: #28ba59;
  }

  .average_good {
    background-color: #e8f6f9;
    color: #629daa;
  }

  .average {
    background-color: #e8f6f9;
    color: #629daa;
  }

  .below_average {
    background-color: #e8f6f9;
    color: #629daa;
  }

  .weak {
    background-color: #ffe6e6;
    color: #ff7373;
  }
`

export const Link = styled.a`
  color: var(--collapse-background-color);

  &:hover {
    color: var(--collapse-background-color);
    opacity: 0.8;
  }
`
export const Option = styled(BaseRow)`
  background-color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding 8px 0px !important;
`
export const OptionText = styled(BaseText)`
  font-size: 14px;
  color: var(--text-primary2-color);
  margin-left: 8px;
  width: 135px;
`
export const ButtonContainer = styled.div`
  cursor: pointer;
  border: 1px solid #e1e7ea;
  border-radius: 8px;
  padding: 4px;
  display: flex;
`
