import { ParamsList } from 'parkway-web-common'

export const ItemPriceKeys = {
  allItemPrices: ['ItemPrices'] as const,
  listItemPrices: () =>
    [...ItemPriceKeys.allItemPrices, 'listItemPrices'] as const,
  listItemPrice: (filter?: ParamsList) =>
    [...ItemPriceKeys.listItemPrices(), { filter }] as const,
  itemPricesTemplate: () => ['ItemPricesTemplate'] as const,
  itemPricesImport: () => ['ItemPricesImport'] as const,
  itemPricesExport: (id: string) => ['ItemPricesExport', { id }] as const,
}
