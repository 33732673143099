import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { LOGOUT_PATH, PROFILE_PATH } from 'app/components/router/route-path'
import { useAppSelector } from 'app/redux/hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as S from './ProfileOverlay.styles'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { getJobTitleName } from 'app/utils/getJobTitleName'

export const ItemProfileOverplay = ({
  icon,
  text,
  onClick,
  color,
}: {
  icon: string
  text: string
  onClick?: () => void
  color?: string
}) => {
  return (
    <S.ItemWrapper
      align={'middle'}
      justify={'start'}
      onClick={onClick}
      gutter={15}
      wrap={false}
    >
      <BaseImage src={icon} preview={false} width={20} height={20} />
      <BaseCol>
        <S.Text $color={color}>{text}</S.Text>
      </BaseCol>
    </S.ItemWrapper>
  )
}

export const ProfileOverlay: React.FC<{
  onHide?: () => void
  showModal?: () => void
}> = ({ onHide, showModal }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const profile = useAppSelector(state => state?.profileSlice)?.profile

  const onPressProfile = () => {
    navigate(PROFILE_PATH)
    onHide?.()
  }

  const onPressLogout = () => {
    navigate(LOGOUT_PATH)
    onHide?.()
  }

  const _onPressChangePassword = () => {
    onHide?.()
    showModal?.()
  }

  return (
    <>
      <S.RootWrapper direction="vertical" size={8}>
        <BaseSpace direction="vertical" size={15}>
          <S.BaseAvatarWrapper alt={profile?.firstname} src={profile?.avatar} />
          <BaseSpace direction="vertical" size={1}>
            <S.TextName>{profile?.name ?? ''}</S.TextName>
            <S.JobTitleName>
              {getJobTitleName(profile?.workinfos)}
            </S.JobTitleName>
          </BaseSpace>
        </BaseSpace>
        <S.ItemsDivider />
        <ItemProfileOverplay
          icon={R.images.ic_user}
          onClick={onPressProfile}
          text={t(R.strings.profile)}
        />

        <ItemProfileOverplay
          icon={R.images.ic_password}
          text={t(R.strings.change_password)}
          onClick={_onPressChangePassword}
        />

        <ItemProfileOverplay
          icon={R.images.ic_logout}
          onClick={onPressLogout}
          text={t(R.strings.logout)}
        />
      </S.RootWrapper>
    </>
  )
}
