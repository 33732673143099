import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseFormItem } from 'app/components/common/forms/components/BaseFormItem'
import { Option } from 'app/components/common/selects/BaseSelect'
import { IUser } from 'app/model/user.model'
import { useTranslation } from 'react-i18next'
import { IProfileProps } from '..'
import { Label } from '../../components'
import { useModalHook } from './hook'
import * as S from './styles'

export const DirectManager = ({ type, managedProfiles }: IProfileProps) => {
  const { t } = useTranslation()

  const {
    officeManagers,
    loadingOfficeManager,
    onPopupOfficeManagerScroll,
    techManagers,
    loadingTechManager,
    onPopupTechManagerScroll,
    responsibilityManagers,
    loadingResponsibilityManager,
    onPopupResponsibilityManagerScroll,
    columnsDS,
    isLoadingDirectSubordinate,
    paginationDS,
    handleTableDSChange,
    onChangeOM,
    onChangeTM,
    onChangeRM,
  } = useModalHook()

  return (
    <S.CollapseWrapper
      defaultActiveKey={['direct_manager']}
      items={[
        {
          key: 'direct_manager',
          label: (
            <S.BaseTitle>
              {t(R.strings.job_title_content_job_title_management)}
            </S.BaseTitle>
          ),
          style: {
            alignItems: 'center',
          },
          children: (
            <BaseSpace>
              <BaseRow gutter={20} align={'top'}>
                <S.LeftCol xl={6}>
                  <Label
                    title={t(R.strings.office_manager)}
                    description={t(R.strings.office_manager_description)}
                    require
                  />
                </S.LeftCol>
                <BaseCol xl={18}>
                  <BaseFormItem name="office_manager" hiddenLabel>
                    <S.BaseSelectWrapper
                      allowClear
                      placeholder={t(R.strings.choose)}
                      loading={loadingOfficeManager}
                      onPopupScroll={onPopupOfficeManagerScroll}
                      filterSort={() => 0}
                      showSearch
                      onChange={id => onChangeOM?.(id as string)}
                      listHeight={400}
                    >
                      {officeManagers?.map((item: IUser, index) => {
                        return (
                          <Option value={item._id} key={index}>
                            <BaseAvatarProfile
                              name={item?.name}
                              src={item?.avatar}
                              jobTitle={item?.jobTitleName}
                            />
                          </Option>
                        )
                      })}
                    </S.BaseSelectWrapper>
                  </BaseFormItem>
                </BaseCol>
              </BaseRow>
              <S.Divider />
              <BaseRow gutter={20} align={'top'}>
                <S.LeftCol xl={6}>
                  <Label
                    title={t(R.strings.tech_manager)}
                    description={t(R.strings.tech_manager_description)}
                    require
                  />
                </S.LeftCol>
                <BaseCol xl={18}>
                  <BaseFormItem name="tech_manager" hiddenLabel>
                    <S.BaseSelectWrapper
                      allowClear
                      placeholder={t(R.strings.choose)}
                      loading={loadingTechManager}
                      onPopupScroll={onPopupTechManagerScroll}
                      filterSort={() => 0}
                      onChange={id => onChangeTM?.(id as string)}
                      listHeight={400}
                      showSearch
                    >
                      {techManagers?.map((item: IUser, index) => {
                        return (
                          <Option value={item._id} key={index}>
                            <BaseAvatarProfile
                              name={item?.name}
                              src={item?.avatar}
                              jobTitle={item?.jobTitleName}
                            />
                          </Option>
                        )
                      })}
                    </S.BaseSelectWrapper>
                  </BaseFormItem>
                </BaseCol>
              </BaseRow>
              <S.Divider />
              <BaseRow gutter={20} align={'top'}>
                <S.LeftCol xl={6}>
                  <Label
                    title={t(R.strings.responsibility_manager)}
                    description={t(
                      R.strings.responsibility_manager_description,
                    )}
                    require
                  />
                </S.LeftCol>
                <BaseCol xl={18}>
                  <BaseFormItem name="responsibility_manager" hiddenLabel>
                    <S.BaseSelectWrapper
                      allowClear
                      placeholder={t(R.strings.choose)}
                      loading={loadingResponsibilityManager}
                      onPopupScroll={onPopupResponsibilityManagerScroll}
                      filterSort={() => 0}
                      showSearch
                      onChange={id => onChangeRM?.(id as string)}
                      listHeight={400}
                    >
                      {responsibilityManagers?.map((item: IUser, index) => {
                        return (
                          <Option value={item._id} key={index}>
                            <BaseAvatarProfile
                              name={item?.name}
                              src={item?.avatar}
                              jobTitle={item?.jobTitleName}
                            />
                          </Option>
                        )
                      })}
                    </S.BaseSelectWrapper>
                  </BaseFormItem>
                </BaseCol>
              </BaseRow>
              {type === 'update' && (
                <>
                  <S.Divider />
                  <BaseRow gutter={20} align={'top'}>
                    <S.LeftCol xl={6}>
                      <Label
                        title={t(R.strings.direct_subordinate)}
                        description={t(
                          R.strings.direct_subordinate_description,
                        )}
                      />
                    </S.LeftCol>
                    <BaseCol xl={18}>
                      <S.BaseTableReport
                        columns={columnsDS}
                        dataSource={managedProfiles?.map((item, index) => ({
                          ...item,
                          key: index,
                        }))}
                        pagination={paginationDS}
                        loading={isLoadingDirectSubordinate}
                        onChange={handleTableDSChange}
                        rowClassName="row-overwrite-style"
                        scroll={{
                          x: true,
                        }}
                      />
                    </BaseCol>
                  </BaseRow>
                  {/* <S.Divider /> */}

                  {/* <BaseRow gutter={20} align={'top'}>
                    <S.LeftCol xl={6}>
                      <Label
                        title={t(R.strings.indirect_subordinate)}
                        description={t(
                          R.strings.indirect_subordinate_description,
                        )}
                      />
                    </S.LeftCol>
                    <BaseCol xl={18}>
                      <S.BaseTableReport
                        columns={columnsIS}
                        dataSource={dataIndirectSubordinate}
                        pagination={paginationIS}
                        loading={isLoadingIndirectSubordinate}
                        onChange={handleTableISChange}
                        rowClassName="row-overwrite-style"
                        scroll={{
                          x: true,
                        }}
                      />
                    </BaseCol>
                  </BaseRow> */}
                </>
              )}
            </BaseSpace>
          ),
        },
      ]}
      expandIconPosition="right"
    />
  )
}
