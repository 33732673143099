import { IWorkInfoUserInfo } from 'app/api/profile/model/profile'
import R from 'app/assets/R'
import {
  SvgHistoryIcon,
  SvgMoreIcon,
  SvgTrashIcon,
} from 'app/assets/svg-assets'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { groupBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { WorkInfoItem } from './components/WorkInfoItem'
import {
  ButtonContainer,
  Divider,
  ListLabel,
  SectionBaseWrapper,
  SectionTitle,
} from './styles'
import { BasePopover } from 'app/components/common/BasePopover'
import { UpdateHistoryModal } from './components/UpdateHistoryModal'
import { PromotionProcessModal } from './components/PromotionProcessModal'

interface IProps {
  listWorkInfo?: IWorkInfoUserInfo[]
  listWorkInfoAka?: IWorkInfoUserInfo[]
}

export const WorkInfo = (props: IProps) => {
  const { listWorkInfo, listWorkInfoAka } = props

  const { t } = useTranslation()
  const WorkInfos = () => {
    return (
      <BaseSpace size={24}>
        <BaseRow align={'middle'}>
          <ListLabel>
            {t(R.strings.profile_content_job_title_main_column)}
          </ListLabel>
        </BaseRow>
        {Object.entries(groupBy(listWorkInfo, 'jobtitleid')).map(
          ([id, items], index) => {
            return <WorkInfoItem data={items} />
          },
        )}
      </BaseSpace>
    )
  }

  const WorkInfosAka = () => {
    return (
      <BaseSpace size={24}>
        <ListLabel>
          {t(R.strings.profile_content_job_title_secondary_column)}
        </ListLabel>
        {Object.entries(groupBy(listWorkInfoAka, 'jobtitleid'))?.map(
          ([id, items], index) => {
            return <WorkInfoItem data={items} />
          },
        )}
      </BaseSpace>
    )
  }

  return (
    <SectionBaseWrapper>
      <BaseRow align="middle">
        <SectionTitle>{t(R.strings.section_job_title)}</SectionTitle>
        <BasePopover
          placement="bottomLeft"
          trigger="click"
          content={
            <BaseSpace size={8}>
              <PromotionProcessModal />
              <UpdateHistoryModal />
            </BaseSpace>
          }
        >
          <ButtonContainer>
            <SvgMoreIcon />
          </ButtonContainer>
        </BasePopover>
      </BaseRow>
      <Divider />
      <WorkInfos />
      <Divider />
      <WorkInfosAka />
    </SectionBaseWrapper>
  )
}
