import { BaseMenu } from 'app/components/common/BaseMenu'
import { BaseRow } from 'app/components/common/BaseRow'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  colors,
} from 'parkway-web-common'
import styled from 'styled-components'

export const Menu = styled(BaseMenu)`
  background: transparent;
  border-right: 0;
  padding: 0 8px;
  a {
    width: 100%;
    display: block;
  }

  .ant-menu-item,
  .ant-menu-submenu {
    font-size: ${FONT_SIZE.xs};
  }

  .ant-menu-item-icon {
    width: 1.25rem;
  }

  .ant-menu-item {
    height: 48px;
  }

  // .ant-menu-submenu-expand-icon,
  // .ant-menu-submenu-arrow,
  // span[role='img'],
  // a,
  // .ant-menu-item,
  // .ant-menu-submenu {
  //   color: var(--text-Slider-secondary-color);
  //   fill: var(--text-Slider-secondary-color);
  // }

  // .ant-menu-item:hover,
  // .ant-menu-submenu-title:hover {
  //   .ant-menu-submenu-expand-icon,
  //   .ant-menu-submenu-arrow,
  //   span[role='img'],
  //   a,
  //   .ant-menu-item-icon,
  //   .ant-menu-title-content {
  //     color: ${'#2E90FA'};
  //     fill: ${'#2E90FA'};
  //     & > svg > path {
  //       stroke: ${'#2E90FA'} !important;
  //     }
  //   }
  // }

  // .ant-menu-submenu-selected {
  //   .ant-menu-submenu-title {
  //     color: var(--text-Slider-primary-color);

  //     .ant-menu-submenu-expand-icon,
  //     .ant-menu-submenu-arrow,
  //     span[role='img'] {
  //       color: ${'#2E90FA'};
  //       fill: ${'#2E90FA'};
  //     }
  //   }
  // }

  .ant-menu-submenu-title {
    padding-left: 0px !important;
    width: 100%;
  }

  .ant-menu-submenu-vertical {
    .ant-menu-submenu-title {
      height: 48px;
    }
  }

  // .ant-menu-item-selected {
  //   background-color: ${'#EFF8FF'} !important;
  //   .ant-menu-submenu-expand-icon,
  //   .ant-menu-submenu-arrow,
  //   span[role='img'],
  //   .ant-menu-item-icon,
  //   a {
  //     color: ${'#2E90FA'};
  //     fill: ${'#2E90FA'};
  //   }
  // }

  // .ant-menu-item-selected:hover {
  //   background-color: ${'#EFF8FF'} !important;
  // }

  .ant-menu-item-active,
  .ant-menu-submenu-active .ant-menu-submenu-title {
    background-color: transparent !important;
  }

  .ant-menu-sub.ant-menu-inline {
    background-color: transparent !important;
  }

  .ant-menu-submenu-arrow {
    color: ${convertedVariables.primaryColor};
  }
`

export const LeftPoint = styled.div<{ $isSelected?: boolean }>`
  transition: 0.5s ease;
  border-top-right-radius: ${BORDER_RADIUS};
  border-bottom-right-radius: ${BORDER_RADIUS};
  height: 17px;
  width: 5px;
  margin-right: 6px;
  background: ${props => (props.$isSelected ? 'transparent' : 'transparent')};
`

export const LeftPointSubMenu = styled.div<{ $isSelected?: boolean }>`
  transition: 0.5s ease;
  border-radius: ${BORDER_RADIUS};
  height: 6px;
  width: 6px;
  margin-left: 11px;
  background: ${props => (props.$isSelected ? 'transparent' : 'transparent')};
`

export const ItemMenuCustomWrapper = styled(BaseRow)<{
  $isSelected: boolean
}>`
  background-color: ${props => (props?.$isSelected ? '#EFF8FF' : colors.white)};
  border-radius: 8px;
  border: 1px solid ${props => (props?.$isSelected ? '#2E90FA' : 'white')};
  height: 48px;
`

export const LabelCustom = styled.span<{
  $isSelected: boolean
}>`
  color: ${props => (props.$isSelected ? '#2E90FA' : colors.primary)};
  font-weight: ${FONT_WEIGHT.medium};
  margin-left: 8px;
  font-size: ${FONT_SIZE.xs};
`
