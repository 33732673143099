import { DefineItemPriceStatus } from 'app/api/item-price/constant'
import R from 'app/assets/R'
import { StatusEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { BaseTreeSelect } from 'app/components/common/selects/BaseTreeSelect'
import { transformDataSelectTree } from 'app/components/common/selects/BaseTreeSelect/helper'
import { BaseDatePickerStyled } from 'app/containers/DentalTreatmentManagement/CreatePatientRecord/styles'
import { usePagination } from 'app/hook'
import { IUnit } from 'app/model/unit.model'
import { useGetAreaList } from 'app/react-query/hook/location'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import dayjs, { Dayjs } from 'dayjs'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { removeVietnameseTones } from 'app/helpers/string.helper'

interface IProps {
  isEditable?: boolean
}
export const FormInfo = (props: IProps) => {
  const { isEditable = true } = props
  const disabled = !isEditable
  const { t } = useTranslation()

  const { data: areaApi, isLoading: isLoadingArea } = useGetAreaList({
    status: StatusEnum.ACTIVE,
    typeid: 'area',
  })

  const { flattenDataList } = usePagination()

  const [keywordSearchArea, setKeywordSearchArea] = useState<string>('')

  const areaList = useMemo(() => {
    const res: IUnit[] = flattenDataList(areaApi)
    return res?.map(item => ({
      ...item,
      childs: item?.childs
        ?.filter(child => !!child?._oldId)
        ?.map(item => ({
          ...item,
          _id: item?._id ?? item?._oldId,
        })),
    }))
  }, [areaApi])

  const onSearch = (value: string) => {
    setKeywordSearchArea(value)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filterTreeNode = (value?: string, treeNode?: any) => {
    return removeVietnameseTones(treeNode?.title)
      .toLowerCase()
      .includes(removeVietnameseTones(value).toLowerCase())
  }

  return (
    <RootWrapper size={0}>
      <HeaderWrapper>
        <BaseText
          children={t(R.strings.information_item_prrice)}
          fontWeight="semibold"
          fontSize="xs"
        />
      </HeaderWrapper>
      <ContentWrapper size={4}>
        <BaseRow gutter={[16, 8]}>
          <BaseCol xl={24}>
            <BaseForm.Item
              name={'name'}
              label={t(R.strings.price_item_name)}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.price_item_name),
                  }),
                },
              ]}
            >
              <BaseInput
                disabled={disabled}
                placeholder={t(R.strings.price_item_name)}
              />
            </BaseForm.Item>
          </BaseCol>

          <BaseCol xl={12}>
            <BaseForm.Item
              name={'areaIds'}
              label={t(R.strings.area)}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.area),
                  }),
                },
              ]}
            >
              <BaseTreeSelect
                placeholder={t(R.strings.area)}
                loading={isLoadingArea}
                treeData={transformDataSelectTree(areaList ?? [], {
                  title: 'name',
                  value: '_id',
                })}
                multiple
                showSearch
                treeCheckable={true}
                onSearch={onSearch}
                searchValue={keywordSearchArea}
                filterTreeNode={filterTreeNode}
                maxTagCount={2}
                allowClear
                disabled={disabled}
              />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol xl={6}>
            <BaseForm.Item
              name={'startDate'}
              label={t(R.strings.start_date)}
              required
              dependencies={['endDate']}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.start_date),
                  }),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const day = value as Dayjs

                    if (!day || !getFieldValue('endDate')) {
                      return Promise.resolve()
                    }
                    if (
                      day.isSame(getFieldValue('endDate')) ||
                      day.isBefore(getFieldValue('endDate'))
                    ) {
                      return Promise.resolve()
                    }

                    return Promise.reject(
                      t(R.strings.start_date_must_be_before_end_date),
                    )
                  },
                }),
              ]}
            >
              <BaseDatePickerStyled
                format={'DD-MM-YYYY'}
                placeholder={t(R.strings.start_date)}
                // onChange={onChangeStart}
                showTime={{ defaultValue: dayjs('00:00', 'HH:mm') }}
                disabled={disabled}
              />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol xl={6}>
            <BaseForm.Item
              name={'endDate'}
              label={t(R.strings.end_date)}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.end_date),
                  }),
                },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const day = value as Dayjs

                    if (!day || !getFieldValue('startDate')) {
                      return Promise.resolve()
                    }
                    if (
                      day.isSame(getFieldValue('startDate')) ||
                      day.isAfter(getFieldValue('startDate'))
                    ) {
                      return Promise.resolve()
                    }

                    return Promise.reject(
                      t(R.strings.end_date_must_be_after_start_date),
                    )
                  },
                }),
              ]}
            >
              <BaseDatePickerStyled
                format={'DD-MM-YYYY'}
                placeholder={t(R.strings.end_date)}
                // onChange={onChangeEnd}
                showTime={{ defaultValue: dayjs('23:59', 'HH:mm') }}
                disabled={disabled}
              />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol xl={12}>
            <BaseForm.Item
              name={'isDefault'}
              label={t(R.strings.default)}
              required
              dependencies={['status']}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.default),
                  }),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const isDraft =
                      getFieldValue('status') === StatusEnum.INACTIVE

                    if (isDraft && value === true) {
                      return Promise.reject(
                        t(R.strings.is_default_should_be_false),
                      )
                    }

                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <BaseSelect
                placeholder={t(R.strings.default)}
                options={[
                  {
                    label: t(R.strings.yes),
                    value: true,
                  },
                  {
                    label: t(R.strings.no),
                    value: false,
                  },
                ]}
                disabled={disabled}
              />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol xl={12}>
            <BaseForm.Item
              name={'status'}
              label={t(R.strings.status)}
              dependencies={[1, 2, 3, 4]}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.status),
                  }),
                },
              ]}
            >
              <BaseSelect
                placeholder={t(R.strings.status)}
                options={Object.entries(DefineItemPriceStatus).map(
                  ([key, values]) => ({
                    label: t(values.keyI18n),
                    value: Number(key),
                  }),
                )}
                disabled={disabled}
              />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>
      </ContentWrapper>
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
`

const HeaderWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
  border-bottom: 0.75px solid ${convertedVariables.neutralBlack9Color};
`

const ContentWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
  background-color: #fcfcfc;
  border-end-end-radius: ${BORDER_RADIUS};
`
