import { IColumnTable, IConvertColumnTable } from './model'

export function convertColumnTable<T>({
  key,
  className,
  classNameWidthColumnOverwrite = 'normal-column',
  onCell,
  render,
  title,
  dataIndex,
}: IConvertColumnTable<T>): IColumnTable<T> {
  return {
    title: title ?? '',
    dataIndex: dataIndex ?? key,
    key: key,
    className: `${classNameWidthColumnOverwrite ?? ''} ${className ?? ''}`,
    onCell,
    render,
  }
}
