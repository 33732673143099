import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const SectionBaseWrapper = styled(SectionBase)`
  padding: ${PADDING.xl} !important;
`
export const SectionTitle = styled(BaseText)`
  font-size: 16px !important;
  font-weight: ${FONT_WEIGHT.semibold}!important;
  color: ${convertedVariables.primaryColor};
`
export const SectionDescription = styled(BaseText)`
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.regular}!important;
  color: ${convertedVariables.primaryColor};
  margin-top: 8px;
`

export const TextDegreeName = styled(BaseText)`
  display: flex;
  flex: 1;
  color: var(--primary-color) !important;
  font-size: 14px !important;
  font-weight: ${FONT_WEIGHT.medium} !important;
  margin: 0 8px;
`

export const TextStartDateDegree = styled(BaseText)`
  color: var(--primary-color) !important;
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.medium} !important;
  opacity: 0.7;
`

export const TextClassification = styled.span`
  border-radius: 200px;
  font-size: 10px !important;
  font-weight: ${FONT_WEIGHT.bold} !important;
  padding: 4px 12px;
`
export const Divider = styled(BaseDivider)`
  margin: 20px 0;
`
export const ClassificationWrapper = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;

  & span {
    font-size: ${FONT_SIZE.xxs};
    color: var(--text-primary2-color);
  }

  .tag {
    background-color: #f8f8f8;
    border-radius: 100px;
    padding: 2px 1rem;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.semibold};
  }

  .excellent {
    background-color: #fffbed;
    color: #fa9f34;
  }

  .good {
    background-color: #ecfdf2;
    color: #28ba59;
  }

  .average_good {
    background-color: #e8f6f9;
    color: #629daa;
  }

  .average {
    background-color: #e8f6f9;
    color: #629daa;
  }

  .below_average {
    background-color: #e8f6f9;
    color: #629daa;
  }

  .weak {
    background-color: #ffe6e6;
    color: #ff7373;
  }
`
export const InfoContainer = styled(BaseCol)`
  padding: 20px;
  border-top: 1px solid #f4f6f9;
  background: #fff;
`
export const LabelText = styled(BaseText)`
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.medium} !important;
  color: #a5abb3 !important;
  margin-bottom: 8px;
`
export const ValueText = styled(BaseText)`
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.semibold} !important;
  color: var(--primary-color);
  margin-bottom: 20px;
`
export const ImageCol = styled(BaseCol)`
  background: #f2f4f7 !important;
`
export const ImageContainer = styled(BaseCol)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ZoomButton = styled.div<{ $marginTop?: number }>`
  cursor: pointer;
  border-radius: 4px;
  background: var(--Neutral-White, #fff);
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => props?.$marginTop ?? 0}px;
`
export const ZoomContainer = styled(BaseCol)`
  margin-right: 25px;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const Tag = styled(BaseRow)<{ $marginBottom?: number }>`
  background: linear-gradient(137deg, #ffae49 0%, #ff7617 100%);
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.04),
    0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  font-size: 10px !important;
  font-weight: ${FONT_WEIGHT.bold} !important;
  color: var(--white) !important;
  padding: 4px 12px;
  width: fit-content;
  margin-bottom: ${props => props?.$marginBottom ?? 0}px;
`
export const OptionText = styled(BaseText)`
  color: #216ae2;
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.regular} !important;
`
export const DegreeImage = styled(BaseImage)`
  max-width: 900px;
  max-height: 540px;
`
export const Dot = styled.div`
  width: 4px;
  height: 4px;
  background: var(--white);
  border-radius: 8px;
  margin-right: 4px;
`
export const TagContainer = styled(BaseRow)`
  padding: 4px 12px;
`
export const Option = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
`
