import { IClinic } from 'app/model/unit.model'
import { Moment } from 'moment'
import { IDoctor } from './SidebarLeft/DoctorList/type'
import { ITreatment } from 'app/containers/Accounting/ItemManagement/model/treatment'

export enum EAppointmentStatus {
  CREATED = 1,
  REQUEST = 2,
  CONFIRM = 3,
  DELETED = 4,
  CANCEL = 5,
}

export enum EScheduleStatus {
  TOTAL = 1,
  SCHEDULE = 2,
  NOT_YET_ARRIVED = 3,
  ARRIVED = 4,
  TREATING = 5,
  LONG_WAITING = 6,
  CANCEL = 7,
  DONE = 8,
}

export enum EInvoiceStatus {
  INVOICE = 'invoiced',
  NOT_INVOICE = 'not_invoiced',
}
export interface IService {
  _id?: string
  name?: string
  price?: number
  total?: number
  tInvoiceStatus?: EInvoiceStatus
  treatmentGroupId?: string
  createdAt?: string
  paymentId?: string
}

export interface ICategoryService {
  _id?: string
  name?: string
  services?: IService[]
}

export interface IPatient {
  _id?: string
  name?: string
  contact?: string
  dob?: string
  phone?: string
}

interface IPerson {
  _id: string
  name: string
  avatar: string
  jobTitle: string
  createdAt: string
  dob?: string
}
export interface IAppointment {
  _id?: string | number | undefined
  patient?: IPatient
  fullName?: string
  phone?: string
  areaId?: string
  clinicId?: string
  services?: string[] | IService[]
  treatments?: string[] | ITreatment[]
  note?: string
  status?: EAppointmentStatus
  doctorId?: string
  callCenterId?: string
  duration?: number
  date?: string | Moment
  time?: string
  categoryService?: ICategoryService
  categoryServiceId?: string
  scheduleStatus?: number
  updatedBy?: string | number | undefined
  fetch?: () => void
  histories?: IAppointment[]
  createdBy?: IPerson
  sms?: number
  createdAt?: string
  reason?: string
  clinic?: IClinic
  doctor?: IDoctor
  patientId?: string
}

export interface IUserAppointment {
  _id?: string
  name?: string
  jobTitle?: string
  createdAt?: string
  avatar?: string
}

export interface ICategoryService {
  _id?: string
  name?: string
  treatments?
}

export interface IListAppointmentStatus {
  status: number
  label: string
}

export interface IListTotal {
  status?: number
  total?: number
  label?: string
  _id?: number
}
