import { BaseLayout } from 'app/components/common/BaseLayout'
import { media } from 'parkway-web-common'
import styled, { css } from 'styled-components'

interface Header {
  $isTwoColumnsLayoutHeader: boolean
}

export const Header = styled(BaseLayout.Header)<Header>`
  line-height: 1.5;
  @media only screen and ${media.md} {
    padding: 0px;
    min-height: 6.5rem !important;
  }

  @media only screen and ${media.md} {
    ${props =>
      props?.$isTwoColumnsLayoutHeader &&
      css`
        padding: 0;
      `}
  }
`
