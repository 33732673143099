import { PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  BORDER_RADIUS,
  initialPagination,
  PADDING,
  Pagination,
} from 'parkway-web-common'
import { Fragment, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ButtonModalAddItems } from '../ButtonModalAddItems'
import { IItemManagement } from 'app/api/item/model/management'
import { BaseCol } from 'app/components/common/BaseCol'
import { useGetItemList } from 'app/react-query/hook/item'
import { usePagination } from 'app/hook'

interface IProps {
  onSelectAll: (services: IItemManagement[]) => void
  onSelectCustom: (services: IItemManagement[]) => void
}
export const ModalNotifySelectItemsLayout = (props: IProps) => {
  const { onSelectAll, onSelectCustom } = props
  const { t } = useTranslation()
  const { flattenDataList } = usePagination()
  const modalNotifyRef = useRef<IRefModal>({})
  const [pagination] = useState<Pagination>({
    ...initialPagination,
    pageSize: 1000,
  })

  const { isLoading, refetch } = useGetItemList(
    {
      page: pagination.current,
      pageSize: pagination.pageSize,
      pagesize: pagination.pageSize,
      type: ['product', 'service'],
    },
    false,
  )

  const handleSelectAll = async () => {
    handleCloseModalNotify()
    const result = await refetch({ active: true })
    const data = flattenDataList(result.data)?.data?.map(
      (item: IItemManagement, index: number) => {
        return {
          ...item,
          key: index + 1,
        }
      },
    )
    onSelectAll(data)
  }

  const handleOpenModalNotify = () => {
    modalNotifyRef.current?.open?.()
  }

  const handleCloseModalNotify = () => {
    modalNotifyRef.current?.hide?.()
  }

  const handleSaveService = () => {
    modalNotifyRef.current?.hide?.()
  }

  const onChangeService = (services: IItemManagement[]) => {
    handleCloseModalNotify()
    onSelectCustom(services)
  }

  return (
    <ModalComponent
      buttonOpenCustom={
        <BaseButton
          loading={isLoading}
          icon={<PlusOutlined rev={undefined} />}
          onClick={handleOpenModalNotify}
        >
          {t(R.strings.add_item_price_service)}
        </BaseButton>
      }
      titleModal={R.strings.add_item_price_service}
      ref={modalNotifyRef}
      widthModal={1000}
      handleSubmit={handleSaveService}
      renderContent={
        <BaseRow gutter={[8, 16]}>
          <BaseCol span={24}>
            <ItemWrapper gutter={[4, 8]} onClick={handleSelectAll}>
              <BaseCol span={24}>
                <BaseText
                  fontWeight="semibold"
                  colorText={'mainColorText'}
                  fontSize="md"
                >
                  {t(R.strings.all_item_price_service_title)}
                </BaseText>
              </BaseCol>
              <BaseCol span={24}>
                <BaseText
                  fontWeight="medium"
                  colorText={'textColorDescription'}
                >
                  {t(R.strings.all_item_price_service_title)}
                </BaseText>
              </BaseCol>
            </ItemWrapper>
          </BaseCol>
          <BaseCol span={24}>
            <ButtonModalAddItems onChangeService={onChangeService}>
              <ItemWrapper gutter={[4, 8]}>
                <BaseCol span={24}>
                  <BaseText
                    fontWeight="semibold"
                    colorText={'mainColorText'}
                    fontSize="md"
                  >
                    {t(R.strings.select_custom_item_price_service_title)}
                  </BaseText>
                </BaseCol>
                <BaseCol span={24}>
                  <BaseText
                    fontWeight="medium"
                    colorText={'textColorDescription'}
                  >
                    {t(R.strings.select_custom_item_price_service_description)}
                  </BaseText>
                </BaseCol>
              </ItemWrapper>
            </ButtonModalAddItems>
          </BaseCol>
        </BaseRow>
      }
      footer={<Fragment />}
    />
  )
}

const ItemWrapper = styled(BaseRow)`
  cursor: pointer;
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack16Color};
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.md};
`
