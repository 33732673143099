import { DownloadOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { _DEV_ } from 'app/constant'
import { useGetItemPriceTemplate } from 'app/react-query/hook/item-price'
import { useTranslation } from 'react-i18next'

export const ButtonDownloadTemplate = () => {
  const { t } = useTranslation()

  const { refetch, isFetching } = useGetItemPriceTemplate(false)

  const handleGetImportTemplate = async () => {
    try {
      const result = await refetch()
      const url = result.data?.Url
      if (url) {
        window.open(url, '_blank', 'noopener,noreferrer')
      }
    } catch (error) {
      _DEV_ && console.error('handleGetImportTemplate', error)
    }
  }

  return (
    <BaseButton
      icon={<DownloadOutlined rev={undefined} />}
      onClick={handleGetImportTemplate}
      loading={isFetching}
    >
      {t(R.strings.download_example_import_file)}
    </BaseButton>
  )
}
