import { IItemPrice, IPayloadUpdateItemPrice } from 'app/api/item-price/model'
import { StatusEnum } from 'app/common/enum'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useUpdateItemPrice } from 'app/react-query/hook/item-price'
import { IFormData } from '../type'
import { useLocation, useNavigate } from 'react-router'
import { useEffect } from 'react'
import dayjs from 'dayjs'
import { MARKETING_PRICING_MANAGEMENT_DETAIL_ITEM_PRICE_PATH } from 'app/components/router/route-path'

export const useHook = () => {
  const [form] = BaseForm.useForm<IFormData>()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { item } = state

  const { mutateAsync: mutateUpdateItemPriceSync, isLoading } =
    useUpdateItemPrice()

  useEffect(() => {
    const { areas, startDate, endDate, name, status, isDefault, prices } =
      item as IItemPrice
    form.setFieldsValue({
      name,
      prices,
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      areaIds: areas.map(area => area.id),
      status,
      isDefault,
    })
  }, [])

  const handleSubmit = async (values: Partial<IFormData>) => {
    const { _id, prices: priceList } = item as IItemPrice
    const {
      areaIds = [],
      prices = [],
      startDate,
      endDate,
      name,
      status = StatusEnum.ACTIVE,
      isDefault,
    } = values
    if (!startDate || !endDate || !name || !areaIds.length) {
      return
    }

    const pushPrices = prices
      .filter(price => {
        return priceList.findIndex(
          priceItem => priceItem.itemId === price.itemId,
        ) == -1
          ? true
          : false
      })
      .map(item => ({ itemId: item.itemId, price: item.price }))

    const removePrices = priceList
      .filter(priceItem => {
        return prices.findIndex(price => price.itemId === priceItem.itemId) ===
          -1
          ? true
          : false
      })
      .map(item => item.itemId)

    const updatePrices = prices
      .filter(price => {
        return priceList.findIndex(
          priceItem =>
            priceItem.itemId === price.itemId &&
            priceItem.price === price.price,
        ) == -1
          ? true
          : false
      })
      .map(item => ({ itemId: item.itemId, price: item.price }))

    const payload: IPayloadUpdateItemPrice = {
      name,
      startDate: dayjs(startDate).startOf('day').toISOString(),
      endDate: dayjs(endDate).endOf('day').toISOString(),
      status,
      pushPrices,
      removePrices,
      updatePrices,
      isDefault,
      areaIds,
    }

    const result = await mutateUpdateItemPriceSync({ id: _id, body: payload })
    if (result) {
      navigate(MARKETING_PRICING_MANAGEMENT_DETAIL_ITEM_PRICE_PATH, {
        state: {
          item: {
            ...item,
            name,
            startDate: payload.startDate,
            endDate: payload.endDate,
            status,
            isDefault: payload.isDefault,
            areas: (payload.areaIds || []).map(area => ({
              id: area,
              name: '',
            })),
            prices,
          },
        },
      })
    }
  }

  const onBack = () => {
    navigate(-1)
  }

  return {
    form,
    handleSubmit,
    isLoadingSubmit: isLoading,
    submit: form.submit,
    onBack,
  }
}
