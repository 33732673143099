import type { UploadProps } from 'antd'
import R from 'app/assets/R'
import {
  SvgRefreshBlueIcon,
  SvgTrashGreyIcon,
  SvgUploadBlueIcon,
} from 'app/assets/svg-assets'
import { DRIVE_ACTION, IMAGE_ACTION } from 'app/common/config'
import { BaseCol } from 'app/components/common/BaseCol'
import { notificationController } from 'app/controllers/notification-controller'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import * as S from './styles'

interface IPropsUploadAvatar {
  image?: string // Replace with the actual type of image
  onSetAvatar?: (avatar: string) => void // Replace with the actual type of onSetAvatar
}

const UploadAvatar = ({ image, onSetAvatar }: IPropsUploadAvatar) => {
  const [imageUrl, setImageUrl] = useState<string>(image || '')

  const defaultFileList = [
    {
      uid: '-1',
      name: '',
      thumbUrl: image || imageUrl || IMAGE_ACTION.THUMBNAIL_AVATAR,
      url: image || imageUrl || IMAGE_ACTION.THUMBNAIL_AVATAR,
    },
  ]

  useEffect(() => {
    setImageUrl(image ?? '')
  }, [image?.length])

  const handleChange: UploadProps['onChange'] = info => {
    const status = info.file.status
    if (status === 'error') {
      notificationController.error({ message: 'Uploading image failed' })

      return false
    }

    if (status === 'done') {
      const image = info.file?.response?.data?.imageUrl
      onSetAvatar && onSetAvatar(image)
      setImageUrl(image)
      return true
    }
    return
  }

  const handleRemoveImage = () => {
    onSetAvatar && onSetAvatar('')
    setImageUrl('')
  }

  return (
    <S.UploadAvatar
      name="file"
      listType="picture-circle"
      maxCount={1}
      defaultFileList={defaultFileList}
      action={DRIVE_ACTION.UPLOAD_IMAGE}
      onChange={handleChange}
      multiple={false}
      itemRender={() => {
        return (
          <div className="avatar-container">
            <img src={imageUrl || IMAGE_ACTION.THUMBNAIL_AVATAR} alt="thumb" />

            <BaseCol>
              {imageUrl && (
                <S.ButtonRemove
                  icon={<SvgTrashGreyIcon />}
                  onClick={() => {
                    handleRemoveImage()
                  }}
                />
              )}
              <S.AvatarDescription $marginTop={imageUrl ? 12 : 44}>
                {t(R.strings.avatar_upload_description)}
              </S.AvatarDescription>
            </BaseCol>
          </div>
        )
      }}
    >
      {imageUrl ? (
        <S.ButtonUpload icon={<SvgRefreshBlueIcon />}>
          {t(R.strings.change_avatar)}
        </S.ButtonUpload>
      ) : (
        <S.ButtonUpload icon={<SvgUploadBlueIcon />}>
          {t(R.strings.upload_image)}
        </S.ButtonUpload>
      )}
    </S.UploadAvatar>
  )
}

export default UploadAvatar
