import { IJobTitle } from 'app/api/jobtitle/model/job-title-management'
import R from 'app/assets/R'
import { SvgPlusIcon, SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import { IUnit } from 'app/model/unit.model'
import { useState } from 'react'
import { useModalHook } from './hook'
import * as S from './styles'
import { IFormWorkInfoModalData } from './type'
import { notificationController } from 'app/controllers/notification-controller'
import moment from 'moment'
import { StatusOptionsCreateEnum } from 'app/common/enum'

export const initValues: IFormWorkInfoModalData = {}

enum StatusWorkInfosOptionsEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
}

export type StatusWorkInfosType = keyof typeof StatusWorkInfosOptionsEnum

export interface IProps {
  jobTitleOptions: IJobTitle[]
  departmentOptions: IUnit[]
  handleSubmit: (value: IFormWorkInfoModalData) => void
}

export const WorkInfoModal = ({
  jobTitleOptions,
  departmentOptions,
  handleSubmit,
}: IProps) => {
  const { form, visible, t, showModal, onFinish, handleCancel, rules } =
    useModalHook({ handleSubmit })

  const handleSubmitFn = () => {
    if (
      !form?.getFieldValue('unitid') ||
      !form?.getFieldValue('jobtitleid') ||
      !form?.getFieldValue('startdate')
    ) {
      notificationController.error({
        message: t(R.strings.error),
        description: t(R.strings.please_choose_require_fields),
      })
      return
    }
    if (
      form?.getFieldValue('enddate')?.$d &&
      moment(form?.getFieldValue('startdate')?.$d)?.isAfter(
        form?.getFieldValue('enddate')?.$d,
      )
    ) {
      notificationController.error({
        message: t(R.strings.error),
        description: t(R.strings.start_date_must_be_before_end_date),
      })
      return
    }
    form.submit()
  }

  return (
    <BaseModalManagement
      titleKeyI18n={R.strings.profile_content_add_work_info_main}
      typeButtonOpen="custom"
      buttonComponent={
        <S.ButtonAction onClick={showModal} icon={<SvgPlusIcon />}>
          {t(R.strings.profile_content_add_work_info_main)}
        </S.ButtonAction>
      }
      content={
        <BaseForm
          layout="vertical"
          onFinish={onFinish}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <S.FormItem
            label={t(R.strings.department)}
            name="unitid"
            rules={rules.department}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(R.strings.select_department)}
              suffixIcon={<SvgTableCloseIcon />}
            >
              {departmentOptions?.map((department, index) => {
                return (
                  <Option value={department._id} key={index}>
                    {department.name}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </S.FormItem>
          <S.FormItem
            label={t(R.strings.profile_content_job_title)}
            name="jobtitleid"
            rules={rules.jobTitle}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(R.strings.profile_content_please_select_job_title)}
              suffixIcon={<SvgTableCloseIcon />}
            >
              {jobTitleOptions?.map((jobTitle, index) => {
                return (
                  <Option value={jobTitle._id} key={index}>
                    {jobTitle.name}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </S.FormItem>
          <BaseRow gutter={[16, 8]} align={'top'}>
            <BaseCol xl={12}>
              <BaseForm.Item
                name={'startdate'}
                required
                label={t(R.strings.start_date)}
              >
                <S.BaseDatePickerStyled
                  format={'DD-MM-YYYY'}
                  placeholder={t(R.strings.start_date)}
                />
              </BaseForm.Item>
            </BaseCol>
            <BaseCol xl={12}>
              <BaseForm.Item name={'enddate'} label={t(R.strings.end_date)}>
                <S.BaseDatePickerStyled
                  format={'DD-MM-YYYY'}
                  placeholder={t(R.strings.end_date)}
                  allowClear
                />
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.profile_content_add_work_info_main}
      confirmKeyI18n={R.strings.confirm}
      handleSubmit={handleSubmitFn}
      visible={visible}
    />
  )
}
