import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable, IFilterDataTableReport } from './type'
import { _DEV_ } from 'app/constant'
import { isEmpty, isEqual } from 'lodash'
import { useDebounce } from 'app/hook'
import { initialPagination, PADDING, Pagination } from 'parkway-web-common'
import R from 'app/assets/R'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { requestGetItemPricesManagement } from 'app/api/item-price'
import {
  DefineItemPriceStatus,
  ItemPriceMessageCode,
} from 'app/api/item-price/constant'
import { IItemPrice } from 'app/api/item-price/model'
import BaseText from 'app/components/common/BaseText'
import { DateUtil } from 'parkway-web-common'
import styled from 'styled-components'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { IColumnTable } from 'app/components/tables/common-table/model'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseButton } from 'app/components/common/BaseButton'
import { MoreOutlined } from '@ant-design/icons'
import { Row } from 'antd'
import { convertColumnTable } from 'app/components/tables/common-table/func'
import { useNavigate } from 'react-router'
import {
  MARKETING_PRICING_MANAGEMENT_CREATE_ITEM_PRICE_PATH,
  MARKETING_PRICING_MANAGEMENT_DETAIL_ITEM_PRICE_PATH,
  MARKETING_PRICING_MANAGEMENT_UPDATE_ITEM_PRICE_PATH,
} from 'app/components/router/route-path'
import { StatusEnum } from 'app/common/enum'
import {
  useDeleteItemPrice,
  useUpdateItemPrice,
} from 'app/react-query/hook/item-price'

export const useHookTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [usersSelected, setServicesSelected] = useState<IItemPrice[]>([])
  const [loading, setIsLoading] = useState<boolean>(false)
  const [pagination, setPagination] = useState<Pagination>(initialPagination)
  const [total, setTotal] = useState<number>(0)
  const [filter, setFilter] = useState<IFilterDataTableReport>({})
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])
  const [openMore, setOpenMore] = useState<{
    visible: boolean
    position?: number
  }>({ visible: false })

  const { user, isHavePermissionFunction } = useVerifyPermissionAndRedirect()
  const { isLoading: isLoadingUpdateItemPrice } = useUpdateItemPrice()
  const {
    mutateAsync: mutateDeleteItemPriceSync,
    isLoading: isLoadingDeleteItemPrice,
  } = useDeleteItemPrice()

  const handleExpand = (expanded: boolean, newKey?: number): void => {
    if (!newKey) return
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, newKey])
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== newKey))
    }
  }

  const data = useMemo((): IDataTable[] => {
    return usersSelected?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [usersSelected])

  const params: IFilterDataTableReport = useMemo((): IFilterDataTableReport => {
    return {
      keyword: isEmpty(filter?.keyword) ? undefined : filter?.keyword,
      page: pagination.current,
      pagesize: pagination.pageSize,
      status: filter?.status,
    }
  }, [pagination, filter])

  const paramsDebounce = useDebounce(params, 500)

  const getData = async (): Promise<void> => {
    try {
      setIsLoading(true)

      const res = await requestGetItemPricesManagement(paramsDebounce)

      if (
        isEqual(
          res?.msgcode,
          ItemPriceMessageCode.ItemPriceManagement.successGetData,
        )
      ) {
        setServicesSelected(res.data || [])
        setTotal(res?.metadata?.totalItem ?? 0)
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect((): void => {
    getData()
  }, [paramsDebounce])

  const onChangeFilter = (filter?: IFilterDataTableReport): void => {
    setFilter(prev => ({
      ...prev,
      ...filter,
    }))

    setPagination(prev => ({ ...prev, current: 1 }))
  }

  const isHavePermission = useMemo(() => {
    const isHavePermissionItemPriceCreate = isHavePermissionFunction(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.ITEM_PRICE_CREATE,
    )

    const isHavePermissionItemPriceUpdate = isHavePermissionFunction(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.ITEM_PRICE_UPDATE,
    )

    const isHavePermissionItemPriceRead = isHavePermissionFunction(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.ITEM_PRICE_READ,
    )

    const isHavePermissionItemPriceDelete = isHavePermissionFunction(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.ITEM_PRICE_DELETE,
    )

    return {
      isHavePermissionItemPriceCreate,
      isHavePermissionItemPriceUpdate,
      isHavePermissionItemPriceRead,
      isHavePermissionItemPriceDelete,
    }
  }, [user])

  const columns = useMemo((): IColumnTable<IDataTable>[] => {
    return [
      convertColumnTable<IDataTable>({
        title: t(R.strings.name),
        key: 'name',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (value, record) => {
          return (
            <RenderValueTable
              value={value}
              type="Link"
              onClick={() => {
                if (!isHavePermission.isHavePermissionItemPriceRead) {
                  return
                }
                onDetail(record)
              }}
            />
          )
        },
      }),

      convertColumnTable<IDataTable>({
        title: t(R.strings.area_apply),
        key: 'areas',
        classNameWidthColumnOverwrite: 'big-column',
        render: values => {
          return (
            <BaseText
              children={(values || [])
                .map((value: { id: string; name: string }) => value.name)
                .join(', ')}
              opacity="0.7"
              fontWeight="medium"
            />
          )
        },
      }),

      convertColumnTable<IDataTable>({
        title: t(R.strings.default),
        key: 'isDefault',
        classNameWidthColumnOverwrite: 'medium-column',
        render: value => {
          return (
            <BaseText
              children={t(value ? R.strings.yes : R.strings.no)}
              opacity="0.7"
              fontWeight="medium"
            />
          )
        },
      }),

      convertColumnTable<IDataTable>({
        title: t(R.strings.start_date),
        key: 'startDate',
        classNameWidthColumnOverwrite: 'medium-column',
        className: 'header-column-center',
        render: value => {
          return (
            <BaseText
              children={DateUtil.formatDDMMYYY(value)}
              opacity="0.7"
              fontWeight="medium"
              textAlign="center"
            />
          )
        },
      }),

      convertColumnTable<IDataTable>({
        title: t(R.strings.end_date),
        key: 'endDate',
        classNameWidthColumnOverwrite: 'medium-column',
        className: 'header-column-center',
        render: value => {
          return (
            <BaseText
              children={DateUtil.formatDDMMYYY(value)}
              opacity="0.7"
              fontWeight="medium"
              textAlign="center"
            />
          )
        },
      }),

      convertColumnTable<IDataTable>({
        title: t(R.strings.status),
        key: 'status',
        className: 'header-column-right header-status',
        render: (value, record, index) => {
          return (
            <Row justify={'end'}>
              <Row align={'middle'}>
                <BaseStatusWrapper
                  $backgroundColor={
                    DefineItemPriceStatus[value].backgroundColor
                  }
                >
                  <BaseText
                    children={t(DefineItemPriceStatus[value].keyI18n)}
                    fontWeight="medium"
                    colorText={DefineItemPriceStatus[value].color}
                    textAlign="center"
                  />
                </BaseStatusWrapper>
              </Row>
              {(isHavePermission.isHavePermissionItemPriceCreate ||
                isHavePermission.isHavePermissionItemPriceDelete ||
                isHavePermission.isHavePermissionItemPriceRead ||
                isHavePermission.isHavePermissionItemPriceUpdate) && (
                <BasePopover
                  trigger="click"
                  open={openMore.visible && openMore.position === index}
                  onOpenChange={visible => handleOpenChangeMore(visible, index)}
                  content={
                    <BaseSpace>
                      {(() => {
                        if (!isHavePermission?.isHavePermissionItemPriceRead) {
                          return null
                        }
                        return (
                          <BaseButtonWrapper
                            type="text"
                            onClick={() => onDetail(record)}
                          >
                            {t(R.strings.see_detail)}
                          </BaseButtonWrapper>
                        )
                      })()}

                      {(() => {
                        if (
                          !isHavePermission?.isHavePermissionItemPriceUpdate
                        ) {
                          return null
                        }
                        return (
                          <BaseButtonWrapper
                            type="text"
                            onClick={() => onUpdate(record)}
                          >
                            {t(R.strings.update)}
                          </BaseButtonWrapper>
                        )
                      })()}

                      {(() => {
                        if (
                          !isHavePermission?.isHavePermissionItemPriceRead ||
                          !isHavePermission.isHavePermissionItemPriceCreate ||
                          value === StatusEnum.INACTIVE
                        ) {
                          return null
                        }
                        return (
                          <BaseButtonWrapper
                            type="text"
                            onClick={() => onCopyPriceToDraft(record)}
                          >
                            {t(R.strings.copy_item_price)}
                          </BaseButtonWrapper>
                        )
                      })()}

                      {(() => {
                        if (
                          !isHavePermission?.isHavePermissionItemPriceDelete
                        ) {
                          return null
                        }
                        return (
                          <BaseButtonWrapper
                            type="text"
                            onClick={() => onDelete(record._id)}
                          >
                            {t(R.strings.delete)}
                          </BaseButtonWrapper>
                        )
                      })()}
                    </BaseSpace>
                  }
                >
                  <Row align={'middle'}>
                    <BaseButton
                      type="text"
                      icon={<MoreOutlined rev={undefined} />}
                    />
                  </Row>
                </BasePopover>
              )}
            </Row>
          )
        },
      }),
    ]
  }, [t, usersSelected, expandedRowKeys, openMore.visible, openMore.position])

  const handleTableChange = (pagination: Pagination): void => {
    setPagination(pagination)
  }

  const onDetail = (item: IItemPrice) => {
    navigate(MARKETING_PRICING_MANAGEMENT_DETAIL_ITEM_PRICE_PATH, {
      state: { item },
    })
  }

  const onUpdate = (item: IItemPrice) => {
    navigate(MARKETING_PRICING_MANAGEMENT_UPDATE_ITEM_PRICE_PATH, {
      state: { item },
    })
  }

  const onCopyPriceToDraft = (item: IItemPrice) => {
    navigate(MARKETING_PRICING_MANAGEMENT_CREATE_ITEM_PRICE_PATH, {
      state: { item: { ...item, status: StatusEnum.INACTIVE } },
    })
  }

  const onDelete = async (id: string) => {
    handleOpenChangeMore(false)
    await mutateDeleteItemPriceSync({
      id,
    })
    await getData()
  }

  const handleOpenChangeMore = (visible: boolean, position?: number) => {
    setOpenMore({ visible, position })
  }

  return {
    data,
    columns,
    loading: loading || isLoadingUpdateItemPrice || isLoadingDeleteItemPrice,
    pagination: {
      ...pagination,
      total,
    },
    handleTableChange,
    onChangeFilter,
    filter,
    expandedRowKeys,
    handleExpand,
  }
}

const BaseStatusWrapper = styled.div<{
  $backgroundColor: string
}>`
  padding: 4px 12px;
  border-radius: ${PADDING.xl};
  background-image: ${props => {
    return props.$backgroundColor
  }};
  width: fit-content;
`
const BaseButtonWrapper = styled(BaseButton)`
  width: 100%;
  justify-content: flex-start;
`
