// Message code define
export const AccountingMessageCode = {
  CostPlan: {
    successImport: '20003',
    successGetData: '20001',
  },
  OrthodonticWithoutPaid: {
    getLocationSuccess: '80000',
    getPatientWithoutPaid: '20000',
    getSuccess: '80000',
  },
  PatientScheduleWithoutPaid: {
    getLocationSuccess: '80000',
  },
  ExportBill: {
    getTreatmentSuccess: '20000',
    getDataSuccess: '20000',
    createSuccess: '20000',
    updateSuccess: '20000',
    successExportExcel: '20000',
  },
}

// Endpoint
export const AccountingEndPoint = {
  ExportBill: {
    getBusiness: 'crm/v1/tax/taxId',
    requestCreateExportBill: 'crm/v1/invoice-request',
    requestGetDataExportBill: 'crm/v1/invoice-request',
    requestGetLocationLegal: 'olddb/v1/clinic/group',
    updateStatus: 'crm/v1/invoice-request/status',
    update: 'crm/v1/invoice-request',
    getUsedTreatment: 'olddb/v1/payment/get-treatments-by-codes',
    getCountTabs: 'crm/v1/invoice-request/tabs',
    checkValidPaymentCode: 'crm/v1/invoice-request/check-valid',
    exportExcel: 'crm/v1/invoice-request/export-excel',
  },
  CostPlan: {
    requestImportFile: 'report/v1/costplan/import',
    requestGetData: 'report/v1/costplan/getGroupByClinic',
  },
  PatientDebtContract: {
    requestGetData: 'accounting/v1/patients/patient-debt',
    ExportPatientDebt: 'accounting/v1/patients/export-patient-debt',
  },

  BracesTray: {
    requestGetData: 'accounting/v1/bracesTray',
    ExportBracesTray: 'accounting/v1/bracesTray/export-customer-start',
  },

  DailyInvoice: {
    requestGetData: 'accounting/v1/dailyInvoice/internal',
    Export: 'accounting/v1/dailyInvoice/internal/export',
    sync: 'accounting/v1/dailyInvoice/sync-daily-invoices',
  },
  PatientScheduleWithoutPaid: {
    requestGetData: 'report/v1/patient-without-paid/patient',
    locations: 'report/v1/patient-without-paid/summary',
    doctors: 'report/v1/patient-without-paid/summary',
    total: 'report/v1/patient-without-paid/total',
  },

  OrthodonticWithoutPaid: {
    requestGetData: 'accounting/v1/schedule-without-paid',
    Export: 'accounting/v1/schedule-without-paid/export',
    locations: 'accounting/v1/schedule-without-paid/locations',
    doctors: 'accounting/v1/schedule-without-paid/doctors',
    total: 'accounting/v1/schedule-without-paid/total',
    patientWithoutPaid: 'report/v1/patient-without-paid-schedule',
  },

  PatientDebt: {
    requestGetData: 'accounting/v1/patient-debt',
    Export: 'accounting/v1/patient-debt/export',
  },

  PatientOutdated: {
    requestGetData: 'accounting/v1/patient-outdated',
  },
}
// const Key import
export const KeyAccountingImport = {
  All: {
    periodType: 'periodtype',
    importType: 'importtype',
    file: 'file',
  },
}
