import styled from 'styled-components'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import BaseText from 'app/components/common/BaseText'
import { SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { BaseFormItem } from 'app/components/common/forms/components/BaseFormItem'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import R from 'app/assets/R'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { ButtonImportModal } from '../ButtonImportModal'
import { ModalNotifySelectItemsLayout } from '../ModalNotifySelectItems'
import { IItemManagement } from 'app/api/item/model/management'
import { randomMoney } from 'app/components/tables/common-table/constant'
import { convertColumnTable } from 'app/components/tables/common-table/func'
import { useMemo, useState } from 'react'
import { ProfileServiceComponent } from '../ProfileService'
import { ButtonModalNotifyDelete } from '../ButtonModalNotifyDelete'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { BaseFormInstance } from 'app/components/common/forms/BaseForm'
import { removeDuplicates } from 'app/common/helpers'
import { ButtonDownloadTemplate } from '..'
import { IFormData, IPrice } from '../../type'

interface IProps {
  form: BaseFormInstance<IFormData>
  isEditable?: boolean
}

export const FormContent = (props: IProps) => {
  const { form, isEditable = true } = props
  const disabled = !isEditable
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState<string>('')
  const [pageCurrent, setPageCurrent] = useState<number>(1)

  const onSelectCustom = (services: IItemManagement[]) => {
    const pricesField = form.getFieldValue('prices') || []
    const prices: IPrice[] = services.map(service => ({
      itemId: String(service._id),
      price: randomMoney(),
      name: String(service.name),
      code: String(service.code),
      barCode: service.barCode,
    }))

    form.setFieldValue(
      'prices',
      removeDuplicates([...pricesField, ...prices], 'itemId'),
    )
  }

  const onCompletedImport = (items: IPrice[]) => {
    const pricesField = form.getFieldValue('prices') || []
    const prices: IPrice[] = items.map(item => ({
      itemId: item.itemId,
      price: item.price,
      name: item.name,
      code: item.code,
      barCode: item.barCode,
    }))

    form.setFieldValue(
      'prices',
      removeDuplicates([...pricesField, ...prices], 'itemId'),
    )
  }

  const onDeleteItem = (id: string) => {
    const prices = form.getFieldValue('prices')
    form.setFieldsValue({
      prices: prices.filter((price: IPrice) => price.itemId !== id),
    })
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<IPrice>({
        title: t(R.strings.service),
        key: 'name',
        fixed: 'left',
        render: value => {
          return <ProfileServiceComponent itm={{ name: value }} />
        },
      }),
      convertColumnTable<IPrice>({
        title: t(R.strings.sku_column),
        key: 'code',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'small-column',
        render: value => (
          <BaseText
            fontSize="xxs"
            fontWeight="medium"
            colorText="mainColorText"
          >
            {value}
          </BaseText>
        ),
      }),
      convertColumnTable<IPrice>({
        title: t(R.strings.barcode_column),
        key: 'barCode',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'small-column',
        render: value => (
          <BaseText
            fontSize="xxs"
            fontWeight="medium"
            colorText="mainColorText"
          >
            {value || ''}
          </BaseText>
        ),
      }),
      convertColumnTable<IPrice>({
        title: t(R.strings.list_price),
        key: 'price',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record, _index) => {
          const indexValue = _index + (pageCurrent - 1) * 10
          return (
            <BaseFormItem
              noStyle
              hiddenLabel
              shouldUpdate={(prevProps: IFormData, nextProps: IFormData) =>
                prevProps.prices[indexValue].price !==
                nextProps.prices[indexValue].price
              }
            >
              {({ getFieldValue, setFieldValue }) => {
                const price = getFieldValue('prices')[indexValue].price
                return (
                  <InputNumberWrapper
                    style={{ width: '100%' }}
                    value={price}
                    onChange={e => {
                      const updatedPrice = Number(e)
                      setFieldValue(
                        ['prices', indexValue, 'price'],
                        updatedPrice,
                      )
                    }}
                    disabled={disabled}
                  />
                )
              }}
            </BaseFormItem>
          )
        },
      }),
      convertColumnTable<IPrice>({
        title: t(R.strings.unit_column),
        key: 'itemId',
        width: '10%',
        classNameWidthColumnOverwrite: 'number-column',
        render: () => (
          <BaseText
            textAlign="right"
            fontSize="xxs"
            fontWeight="medium"
            colorText="mainColorText"
          >
            Bộ
          </BaseText>
        ),
      }),
      ...(isEditable
        ? [
            convertColumnTable<IPrice>({
              title: '',
              key: 'itemId',
              width: '3%',
              classNameWidthColumnOverwrite: 'number-column',
              render: (value, record) => {
                return (
                  <ButtonModalNotifyDelete
                    onDelete={() => onDeleteItem(value)}
                    title={t(R.strings.delete_item_price_service)}
                    descriptions={[
                      `${t(R.strings.delete_item_price_description1)} ${
                        record.name
                      }`,
                      t(R.strings.delete_item_price_description2),
                    ]}
                  />
                )
              },
            }),
          ]
        : []),
    ]
  }, [t, pageCurrent])

  return (
    <RootWrapper size={4}>
      <HeaderWrapper size={8}>
        <BaseRow justify={'space-between'} gutter={[16, 8]} align={'middle'}>
          <BaseCol>
            <BaseText
              children={t(R.strings.item_price_detail)}
              fontWeight="semibold"
              fontSize="xs"
            />
          </BaseCol>
          {isEditable && (
            <BaseCol>
              <BaseRow gutter={[16, 8]} align={'middle'}>
                <BaseCol>
                  <ButtonDownloadTemplate />
                </BaseCol>
                <BaseCol>
                  <ButtonImportModal onCompleted={onCompletedImport} />
                </BaseCol>
                <BaseCol>
                  <ModalNotifySelectItemsLayout
                    onSelectAll={onSelectCustom}
                    onSelectCustom={onSelectCustom}
                  />
                </BaseCol>
              </BaseRow>
            </BaseCol>
          )}
        </BaseRow>

        <BaseRow>
          <BaseCol xl={8}>
            <BaseFormItem hiddenLabel>
              <BaseInput
                placeholder={t(R.strings.enter_service_name)}
                prefix={<SearchOutlined rev={undefined} />}
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
              />
            </BaseFormItem>
          </BaseCol>
        </BaseRow>
      </HeaderWrapper>
      <BaseFormItem name="prices" noStyle />
      <BaseFormItem
        noStyle
        shouldUpdate={(prevProps, nextProps) =>
          JSON.stringify(prevProps?.prices || {}) !==
          JSON.stringify(nextProps?.prices || {})
        }
      >
        {({ getFieldValue }) => (
          <BaseTableManagement
            columns={columns}
            dataSource={((getFieldValue('prices') ?? []) as IPrice[]).filter(
              e =>
                e.name.toLocaleLowerCase().search(searchValue.toLowerCase()) !==
                -1,
            )}
            rowClassName="row-overwrite-style"
            onChange={e => setPageCurrent(Number(e.current || 0))}
            scroll={{ x: true }}
          />
        )}
      </BaseFormItem>
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};

  .ant-table-wrapper .ant-table .ant-table-header {
    border-radius: 0px !important;

    .ant-table-cell {
      border-left: 0px !important;
      border-right: 0px !important;
    }
  }

  .ant-table-thead > tr > th {
    border: 0px !important;
  }

  .row-overwrite-style .ant-table-cell {
    border-left: 0px !important;
    border-right: 0px !important;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0px;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0px !important;
  }

  .name-service-column {
    width: 500px;
  }

  .price-column {
    width: 100px;
  }
`

const HeaderWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
`

export const InputNumberWrapper = styled(InputNumber)`
  width: 100%;
`
