import { colors, useResponsive } from 'parkway-web-common'
import React, { useMemo } from 'react'
import { Overlay } from '../../../../common/Overlay'
import { SliderLogo } from '../SiderLogo'
import SliderMenu from '../SiderMenu'
import * as S from './styles'

interface MainSliderProps {
  isCollapsed: boolean
  setCollapsed: (isCollapsed: boolean) => void
}

const MainSlider: React.FC<MainSliderProps> = ({
  isCollapsed,
  setCollapsed,
  ...props
}) => {
  const { mobileOnly, tabletOnly } = useResponsive()

  const isCollapsible = useMemo(
    () => mobileOnly && tabletOnly,
    [mobileOnly, tabletOnly],
  )

  const toggleSlider = () => setCollapsed(!isCollapsed)

  return (
    <>
      <S.Slider
        trigger={null}
        collapsed={isCollapsed}
        collapsedWidth={80}
        collapsible={isCollapsible}
        width={240}
        style={{ backgroundColor: colors.white }}
        {...props}
      >
        <SliderLogo
          isSliderCollapsed={isCollapsed}
          toggleSlider={toggleSlider}
        />
        <S.SliderContent>
          <SliderMenu setCollapsed={setCollapsed} isCollapsed={isCollapsed} />
        </S.SliderContent>
        <S.CollapseButton
          shape="circle"
          size="small"
          $isCollapsed={isCollapsed}
          icon={<S.IconCollapsed $isCollapsed={isCollapsed} />}
          onClick={toggleSlider}
        />
      </S.Slider>
      {mobileOnly && <Overlay onClick={toggleSlider} show={!isCollapsed} />}
    </>
  )
}

export default MainSlider
