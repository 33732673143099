import { BaseForm } from 'app/components/common/forms/BaseForm'
import { IFormData } from '../type'
import { useLocation, useNavigate } from 'react-router'
import { useEffect } from 'react'
import { IItemPrice } from 'app/api/item-price/model'
import dayjs from 'dayjs'
import { notificationController } from 'app/controllers/notification-controller'
import {
  MARKETING_PRICING_MANAGEMENT_PATH,
  MARKETING_PRICING_MANAGEMENT_UPDATE_ITEM_PRICE_PATH,
} from 'app/components/router/route-path'
import {
  useDeleteItemPrice,
  useItemPriceExport,
} from 'app/react-query/hook/item-price'

export const useHook = () => {
  const [form] = BaseForm.useForm<IFormData>()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { item } = state

  const { refetch, isFetching: isFetchingExport } = useItemPriceExport(
    item['_id'],
    false,
  )

  const {
    mutateAsync: mutateDeleteItemPriceSync,
    isLoading: isLoadingDeleteItemPrice,
    isSuccess: isSuccessDeleteItemPrice,
  } = useDeleteItemPrice()

  useEffect(() => {
    if (!isSuccessDeleteItemPrice || isLoadingDeleteItemPrice) return

    navigate(MARKETING_PRICING_MANAGEMENT_PATH)
  }, [isLoadingDeleteItemPrice, isSuccessDeleteItemPrice])

  useEffect(() => {
    const { areas, startDate, endDate, name, status, isDefault, prices } =
      item as IItemPrice
    form.setFieldsValue({
      name,
      prices,
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      areaIds: areas.map(area => area.id),
      status,
      isDefault,
    })
  }, [])

  const onUpdate = () => {
    navigate(MARKETING_PRICING_MANAGEMENT_UPDATE_ITEM_PRICE_PATH, {
      state: { item },
    })
  }

  const onExportExcel = async () => {
    try {
      const result = await refetch()
      const url = result.data?.url
      if (url) {
        window.open(url, '_blank', 'noopener,noreferrer')
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error)
      notificationController.error(error)
    }
  }

  const onDelete = async () => {
    await mutateDeleteItemPriceSync({ id: item['_id'] })
  }

  if (!item) {
    navigate(-1)
  }

  return {
    form,
    onUpdate,
    onExportExcel,
    isFetchingExport,
    isLoadingDeleteItemPrice,
    onDelete,
  }
}
