import { IDegreeClassification } from 'app/api/degreeclassification/model/management'
import { IIndustry } from 'app/api/skill/model/management'
import R from 'app/assets/R'
import { SvgPlusIcon, SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import BaseUploadDragger from 'app/components/common/forms/BaseUploadDragger'
import { Option } from 'app/components/common/selects/BaseSelect'
import { getDegreeClassification } from 'app/utils/degreeClassification.util'
import { Dayjs } from 'dayjs'
import { useState } from 'react'
import { useModalHook } from './hook'
import * as S from './styles'
import { IFormDegreeModalData } from './type'

export const initValues: IFormDegreeModalData = {}

enum StatusDegreeOptionsEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
}

export type StatusDegreeType = keyof typeof StatusDegreeOptionsEnum

export interface IPropsUpdateSkill {
  degreeOptions: IIndustry[]
  degreeClassificationOptions: IDegreeClassification[]
  handleSubmit: (value: IFormDegreeModalData) => boolean
}

export const DegreeModal = ({
  degreeOptions,
  degreeClassificationOptions,
  handleSubmit,
}: IPropsUpdateSkill) => {
  const {
    form,
    visible,
    t,
    showModal,
    onFinish,
    handleCancel,
    rules,
    onChangeStartDate,
    onChangeEndDate,
    onSetImage,
  } = useModalHook({ handleSubmit })

  const [showEnd, setShowEnd] = useState<boolean>(true)

  return (
    <BaseModalManagement
      titleKeyI18n={R.strings.profile_content_add_degree}
      typeButtonOpen="custom"
      buttonComponent={
        <S.ButtonSkillAction onClick={showModal} icon={<SvgPlusIcon />}>
          {t(R.strings.profile_content_add_degree)}
        </S.ButtonSkillAction>
      }
      content={
        <BaseForm
          layout="vertical"
          onFinish={onFinish}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <S.FormItem
            label={t(R.strings.profile_content_please_select_degree)}
            name="degree"
            rules={rules.degree}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(R.strings.profile_content_please_select_degree)}
              suffixIcon={<SvgTableCloseIcon />}
            >
              {degreeOptions?.map((degree, index) => {
                return (
                  <Option value={degree._id} key={index}>
                    {degree.name}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </S.FormItem>
          <S.FormItem
            label={t(
              R.strings.profile_content_please_select_degree_classification,
            )}
            name="degreeClassification"
            rules={rules.degree_classification}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(
                R.strings.profile_content_please_select_degree_classification,
              )}
              suffixIcon={<SvgTableCloseIcon />}
            >
              {degreeClassificationOptions?.map(
                (degreeClassification, index) => {
                  return (
                    <Option value={degreeClassification._id} key={index}>
                      {getDegreeClassification(degreeClassification.key)}
                    </Option>
                  )
                },
              )}
            </S.SelectBaseStyled>
          </S.FormItem>
          <BaseRow gutter={20} align={'top'} justify={'space-between'}>
            <BaseCol xl={24}>
              <S.Label
                label={t(R.strings.degree_content_start)}
                required
              ></S.Label>
            </BaseCol>
            <BaseCol xl={24}>
              <S.FormItem hiddenLabel name="start" rules={rules.start}>
                <S.DatePicker
                  placeholder={t(R.strings.degree_content_start)}
                  onChange={day => onChangeStartDate(day as Dayjs)}
                />
              </S.FormItem>
            </BaseCol>
            <BaseCol xl={24}>
              <S.Label
                label={t(R.strings.degree_content_end)}
                required
              ></S.Label>
            </BaseCol>
            <BaseCol xl={24} style={{ display: 'flex', height: 62 }}>
              <BaseCol xl={5}>
                <S.Checkbox
                  checked={showEnd}
                  onChange={e => {
                    setShowEnd(e.target.checked)
                    onChangeEndDate(undefined)
                  }}
                >
                  {t(R.strings.degree_content_no_end)}
                </S.Checkbox>
              </BaseCol>
              {!showEnd && (
                <BaseCol xl={19}>
                  <S.FormItem hiddenLabel name="end" rules={rules.end}>
                    <S.DatePicker
                      placeholder={t(R.strings.choose_date)}
                      onChange={day => onChangeEndDate(day as Dayjs)}
                    />
                  </S.FormItem>
                </BaseCol>
              )}
            </BaseCol>
          </BaseRow>

          <BaseUploadDragger
            name="document"
            label={t(R.strings.document)}
            description={t(R.strings.degree_content_placeholder_upload_image)}
            rules={rules?.document}
            onSetImage={onSetImage}
          />
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.degree_content_add}
      confirmKeyI18n={R.strings.degree_content_add}
      handleSubmit={form.submit}
      visible={visible}
    />
  )
}
