import R from 'app/assets/R'
import { StatusEnum } from 'app/common/enum'

// Message code define
export const ItemPriceMessageCode = {
  ItemPriceManagement: {
    successGetData: '20000',
    successPutData: '20000',
    successDeleteData: '20000',
    successPostData: '20000',
  },
}

// Endpoint
export const ItemEndPoint = {
  ItemPriceManagement: {
    request: 'treatment/v1/item-price',
  },
}

export const DefineItemPriceStatus = {
  // Đang áp dụng : status 1
  [StatusEnum.ACTIVE]: {
    keyI18n: R.strings.appling,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
    color: 'backgroundColor',
  },
  // Nháp : status 2
  [StatusEnum.INACTIVE]: {
    keyI18n: R.strings.draft,
    backgroundColor: 'linear-gradient(90deg, #F8F8F8, #F8F8F8)',
    color: 'subText',
  },
}

