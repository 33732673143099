import R from 'app/assets/R'
import { DASHBOARD_PATH } from 'app/components/router/route-path'
import React from 'react'
import * as S from './MainSider/styles'
import './MainSider/styles.css'
interface SliderLogoProps {
  isSliderCollapsed: boolean
  toggleSlider: () => void
}
export const SliderLogo: React.FC<SliderLogoProps> = ({
  isSliderCollapsed,
}) => {
  return (
    <S.SliderLogoDiv $isCollapse={isSliderCollapsed}>
      <S.SliderLogoLink to={DASHBOARD_PATH}>
        {/* {isSliderCollapsed ? (
          <img
            src={R.images.ic_logo_parkway_collapsed}
            alt="Parkway"
            width={30}
          />
        ) : (
          <img src={R.images.ic_logo_parkway} alt="Parkway" width={100} />
        )} */}
        <img
          src={R.images.ic_logo_parkway_collapsed}
          alt="Parkway"
          className={`logo ${isSliderCollapsed ? 'show' : 'hide'}`}
          width={30}
        />
        <img
          src={R.images.ic_logo_parkway}
          alt="Parkway"
          className={`logo ${!isSliderCollapsed ? 'show' : 'hide'}`}
          width={100}
        />
      </S.SliderLogoLink>
    </S.SliderLogoDiv>
  )
}
