import R from 'app/assets/R'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import styled from 'styled-components'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { PADDING } from 'parkway-web-common'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useHook } from './hook'
import { FooterLayout, FormContent, FormInfo } from '../components'

const CreateItemPriceContainer = () => {
  const { t } = useTranslation()
  const { form, handleSubmit, isLoadingSubmit } = useHook()

  const { user, isHavePermissionFunctionAndGoBack } =
    useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.ITEM_PRICE_CREATE,
      R.strings.add_item_price,
    )
  }, [user])

  return (
    <S.BaseManagementWrapper>
      <BaseForm form={form} onFinish={handleSubmit}>
        <RootContentWrapper>
          <FormInfo />
          <FormContent form={form} />
          <FooterLayout
            isLoadingSubmit={isLoadingSubmit}
            titleSubmit={t(R.strings.create_item_price)}
          />
        </RootContentWrapper>
      </BaseForm>
    </S.BaseManagementWrapper>
  )
}

export default CreateItemPriceContainer

const RootContentWrapper = styled(BaseSpace)`
  margin-top: ${PADDING.md};
  padding-bottom: ${PADDING.md};
  position: relative;
`
