import { AuthMessageCode, PriceItemMessageCode } from 'app/api/auth/constant'
import R from 'app/assets/R'

export const MSG_CODE_TO_MESSAGE_KEY = {
  [AuthMessageCode.AUTH_WRONG_USERNAME_PASSWORD]:
    R.strings.wrong_username_or_password,
  [PriceItemMessageCode.CONFLICTS_PRICE_ITEM_ANOTHER]:
    R.strings.conflics_start_date_end_date_item_price_another,
  [PriceItemMessageCode.ALREADY_IS_DEFAULT_ITEM_PRICE_PRICE]:
    R.strings.already_an_item_is_default,
}
