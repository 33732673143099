import { IProfileUser } from 'app/api/profile/model/profile'
import R from 'app/assets/R'
import { SvgDownIcon } from 'app/assets/svg-assets'
import { BasePagination } from 'app/components/common/BasePagination'
import { BaseRow } from 'app/components/common/BaseRow'
import { initPagination } from 'app/constant'
import { getJobTitleName } from 'app/utils/getJobTitleName'
import { Pagination } from 'parkway-web-common'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PaginationRow } from '../../styles'
import {
  CollapseWrapper,
  ListContainer,
  NameText,
  Profile,
  ProfileContainer,
  SectionTitle,
  TitleContainer,
  TotalText,
} from './styles'

interface IProps {
  managedProfiles?: IProfileUser[]
}

export const DirectSubordinate = (props: IProps) => {
  const { managedProfiles = [] } = props
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [pagination, setPagination] = useState<Pagination>(initPagination)
  const onClick = () => {
    setOpen(prev => !prev)
  }
  const renderItem = (item: IProfileUser, index) => {
    return (
      <ProfileContainer>
        <Profile
          name={item?.name}
          jobTitle={getJobTitleName(item?.workinfos)}
          src={item?.avatar}
          maxWidthJobTitle="100%"
        />
      </ProfileContainer>
    )
  }
  if (managedProfiles?.length <= 0) {
    return null
  }
  return (
    <CollapseWrapper
      // defaultActiveKey={['referralList']}
      items={[
        {
          key: 'referralList',
          label: <SectionTitle>{t(R.strings.direct_subordinate)}</SectionTitle>,
          style: {
            alignItems: 'center',
          },
          onClick: onClick,
          children: (
            <>
              <TitleContainer>
                <NameText>{t(R.strings.name)}</NameText>
              </TitleContainer>
              <ListContainer size={12}>
                {managedProfiles?.map(renderItem)}
              </ListContainer>

              <BaseRow align={'middle'}>
                <TotalText
                  children={`Tổng ${managedProfiles?.length} nhân viên`}
                />
                <PaginationRow justify={'end'}>
                  <BasePagination
                    defaultCurrent={1}
                    pageSize={pagination?.pageSize}
                    total={pagination?.total}
                    onChange={(page: number, pageSize?: number) => {
                      if (page && pageSize) {
                        setPagination({
                          pageSize,
                          current: page,
                        })
                      }
                    }}
                    pageSizeOptions={[10, 20, 50, 100]}
                  />
                </PaginationRow>
              </BaseRow>
            </>
          ),
        },
      ]}
      expandIconPosition="right"
      expandIcon={() => (
        <SvgDownIcon style={open ? { transform: 'rotate(180deg)' } : {}} />
      )}
    />
  )
}
