import { IWorkInfoUserInfo } from 'app/api/profile/model/profile'
import { uniqBy } from 'lodash'

export const getJobTitleName = (workInfos?: IWorkInfoUserInfo[]) => {
  if (!workInfos || workInfos?.length < 1) {
    return ''
  }
  return uniqBy(workInfos, 'jobtitleid')
    ?.map(item => `${item?.jobtitle?.name || item?.jobTitle?.name}`)
    .join(' & ')
}
