import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const SectionBaseWrapper = styled(SectionBase)`
  padding: ${PADDING.xl} !important;
`
export const SectionTitle = styled(BaseText)`
  flex: 1;
  font-size: 16px !important;
  font-weight: ${FONT_WEIGHT.semibold}!important;
  color: ${convertedVariables.primaryColor};
`
export const SectionDescription = styled(BaseText)`
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.regular}!important;
  color: ${convertedVariables.primaryColor};
  margin-top: 8px;
`
export const ItemLabel = styled(BaseText)`
  font-size: 14px !important;
  font-weight: ${FONT_WEIGHT.regular}!important;
  color: #858c94;
`
export const ItemValue = styled(BaseText)`
  font-size: 14px !important;
  font-weight: ${FONT_WEIGHT.semibold}!important;
  color: ${convertedVariables.primaryColor};
  margin-top: 8px;
`
export const Divider = styled(BaseDivider)`
  margin: 20px 0;
`
export const ButtonContainer = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:8px;
  border: 1px solid #E1E7EA;
  cursor:pointer;
}
`
export const WorkInfoItem = styled(BaseCol)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${PADDING.xxxxs};
  flex: 0 0 60%;
  max-width: 60%;
`

export const TitleFormList = styled(BaseText)`
  font-size: ${FONT_SIZE.xxs} !important;
  font-weight: ${FONT_WEIGHT.semibold} !important;
  color: var(--text-primary2-color);
`
export const DateText = styled(BaseText)`
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.medium} !important;
  color: var(--text-primary2-color);
  opacity: 0.7;
`
export const ListLabel = styled(BaseText)`
  font-size: 14px !important;
  font-weight: ${FONT_WEIGHT.semibold} !important;
  color: var(--text-primary2-color);
  flex: 1;
`
export const StatusTextWrapper = styled.div`
  margin-left: 20px;

  .active {
    font-size: 10px !important;
    font-weight: ${FONT_WEIGHT.bold} !important;
    color: var(--white);
    background: linear-gradient(317deg, #2f7eff, #659fff);
    padding: 4px 12px;
    border-radius: 100px;
  }
  .de-active {
    font-size: 10px !important;
    font-weight: ${FONT_WEIGHT.semibold} !important;
    color: var(--text-primary2-color);
    background: ${convertedVariables.neutralBlack16Color};
    padding: 4px 12px;
    border-radius: 100px;
  }
`

export const ClassificationWrapper = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;

  & span {
    font-size: ${FONT_SIZE.xxs};
    color: var(--text-primary2-color);
  }

  .tag {
    background-color: #f8f8f8;
    border-radius: 100px;
    padding: 2px 1rem;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.semibold};
  }

  .excellent {
    background-color: #fffbed;
    color: #fa9f34;
  }

  .good {
    background-color: #ecfdf2;
    color: #28ba59;
  }

  .average_good {
    background-color: #e8f6f9;
    color: #629daa;
  }

  .average {
    background-color: #e8f6f9;
    color: #629daa;
  }

  .below_average {
    background-color: #e8f6f9;
    color: #629daa;
  }

  .weak {
    background-color: #ffe6e6;
    color: #ff7373;
  }
`
