import R from 'app/assets/R'
import { FunctionPermissionEnum } from 'app/common/permission-module'
import { IGroupReport } from 'app/components/common/BaseLayoutReport/type'
import {
  ACCOUNTING_BRACES_TRAY,
  ACCOUNTING_DAILY_INVOICE_PATH,
  ACCOUNTING_ITEM_MANAGEMENT_PATH,
  ACCOUNTING_ORTHODONTIC_WITHOUT_PAID_PATH,
  ACCOUNTING_PATIENT_OUTDATED,
  ACCOUNTING_REPORT_24_PATH,
  ACCOUNTING_SCHEDULE_WITHOUT_PAID,
  ACCOUNTING_SOLD_SELLING_EXPENSES,
  ACCOUNTING_TREATMENT_MANAGEMENT_PATH,
  MANAGE_CUSTOMER_DEBT_CONTRACT_PATH,
  MANAGE_CUSTOMER_DEBT_PATH,
  ACCOUNTING_EXPORT_BILL_PATH,
  REPORT_018_FULL_PATH,
} from 'app/components/router/route-path'
import { DateUtil } from 'parkway-web-common'

export const data: IGroupReport[] = [
  {
    id: 5,
    titleI18nKey: R.strings.request_export_bill_management,
    reports: [
      {
        id: 26,
        label: '',
        descriptionI18nKey: R.strings.list_export_bill_request,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: ACCOUNTING_EXPORT_BILL_PATH,
        permission: FunctionPermissionEnum.REQUEST_EXPORT_BILL,
        isHideLabel: true,
      },
    ],
  },
  {
    id: 3,
    titleI18nKey: R.strings.statistical,
    reports: [
      {
        id: 22,
        label: '',
        descriptionI18nKey: R.strings.list_braces_tray,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: ACCOUNTING_BRACES_TRAY,
        isHideLabel: true,
        permission: FunctionPermissionEnum.READ_BRACES_TRAY,
      },
      {
        id: 23,
        label: '',
        descriptionI18nKey: R.strings.patient_outdated,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: ACCOUNTING_PATIENT_OUTDATED,
        isHideLabel: true,
        permission: FunctionPermissionEnum.READ_PATIENT_OUTDATED,
      },
      {
        id: 4,
        descriptionI18nKey: R.strings.daily_invoice,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: ACCOUNTING_DAILY_INVOICE_PATH,
        isHideLabel: true,
        permission: FunctionPermissionEnum.READ_DAILY_INVOICE,
      },
      {
        id: 6,
        descriptionI18nKey: R.strings.orthodontic_patient_list_zero_payment,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: ACCOUNTING_SCHEDULE_WITHOUT_PAID,
        isHideLabel: true,
        permission: FunctionPermissionEnum.READ_ORTHODONTIC_WITHOUT_PAID,
      },
      {
        id: 6,
        descriptionI18nKey: R.strings.orthodontic_patient_scheduling_density,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: ACCOUNTING_ORTHODONTIC_WITHOUT_PAID_PATH,
        isHideLabel: true,
        permission: FunctionPermissionEnum.READ_ORTHODONTIC_WITHOUT_PAID,
      },
    ],
  },
  {
    id: 2,
    titleI18nKey: R.strings.report_group_debt_management,
    reports: [
      {
        id: 21,
        label: '',
        descriptionI18nKey: R.strings.manage_customer_debt,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MANAGE_CUSTOMER_DEBT_PATH,
        permission: FunctionPermissionEnum.READ_CUSTOMER_DEBT_MANAGEMENT,
        isHideLabel: true,
      },
      {
        id: 25,
        label: '',
        descriptionI18nKey: R.strings.manage_customer_debt_contract,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MANAGE_CUSTOMER_DEBT_CONTRACT_PATH,
        permission: FunctionPermissionEnum.READ_CUSTOMER_DEBT_MANAGEMENT,
        isHideLabel: true,
      },
    ],
  },
  {
    id: 31,
    titleI18nKey: R.strings.item_management,
    reports: [
      {
        id: 34,
        descriptionI18nKey: R.strings.item_management,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: ACCOUNTING_ITEM_MANAGEMENT_PATH,
        isHideLabel: true,
        permission: FunctionPermissionEnum.ITEM_READ,
      },
      {
        id: 35,
        descriptionI18nKey: R.strings.treatment_management,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: ACCOUNTING_TREATMENT_MANAGEMENT_PATH,
        isHideLabel: true,
      },
    ],
  },
  {
    id: 1,
    titleI18nKey: R.strings.report_group_report,
    reports: [
      {
        id: 11,
        label: '001',
        descriptionI18nKey: R.strings.cost_of_goods_sold_and_selling_expenses,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: ACCOUNTING_SOLD_SELLING_EXPENSES,
        permission: FunctionPermissionEnum.REPORT_EXPORT_REPORT_12,
      },
      {
        id: 42,
        label: '018',
        descriptionI18nKey: R.strings.report_18_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_018_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_18,
      },
      {
        id: 43,
        label: '024',
        descriptionI18nKey: R.strings.report_24,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: ACCOUNTING_REPORT_24_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_24,
      },
    ],
  },
]
