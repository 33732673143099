import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseButton } from 'app/components/common/BaseButton'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import { useNavigate } from 'react-router'
import { BaseFormItem } from 'app/components/common/forms/components/BaseFormItem'

interface IProps {
  isLoadingSubmit: boolean
  titleSubmit: string
}

export const FooterLayout = (props: IProps) => {
  const { isLoadingSubmit, titleSubmit } = props
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate?.(-1)
  }

  return (
    <BaseRow justify={'end'} align={'middle'}>
      <BaseCol>
        <BaseRow gutter={[16, 8]} align={'middle'}>
          <BaseCol>
            <BaseButton onClick={handleGoBack}>
              {t(R.strings.cancel)}
            </BaseButton>
          </BaseCol>
          <BaseCol>
            <BaseFormItem noStyle shouldUpdate>
              {({ getFieldsError, isFieldsTouched, submit }) => {
                isFieldsTouched(true)
                const hasErrors = getFieldsError().some(
                  ({ errors }) => errors.length > 0,
                )

                const disabled = hasErrors

                return (
                  <BaseButton
                    onClick={submit}
                    disabled={disabled}
                    loading={isLoadingSubmit}
                  >
                    {titleSubmit}
                  </BaseButton>
                )
              }}
            </BaseFormItem>
          </BaseCol>
        </BaseRow>
      </BaseCol>
    </BaseRow>
  )
}
