import R from 'app/assets/R'

import { CheckboxChangeEvent } from 'antd/es/checkbox'
import {
  requestGetInfoByTaxCode,
  updateBill,
  updateBillStatus,
} from 'app/api/accounting'
import { AccountingMessageCode } from 'app/api/accounting/constant'
import {
  EBillStatus,
  IFilterGetExportBill,
  IGetExportBill,
  IPayloadUpdateExportBill,
} from 'app/api/accounting/model/export-bill'
import { IPatient } from 'app/api/patient/model/patient'
import {
  SvgCircleCheckIcon,
  SvgEditIcon,
  SvgMoreIcon,
  SvgTrashIcon,
} from 'app/assets/svg-assets'
import { getDetailPatientUrl } from 'app/common/helpers'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { initPagination } from 'app/constant'
import { IService } from 'app/containers/CustomerCare/AppointmentPage/type'
import { notificationController } from 'app/controllers/notification-controller'
import { useDebounce, usePagination } from 'app/hook'
import { useGetDataExportBill } from 'app/react-query/hook/accounting'
import { isEqual, uniq, uniqBy } from 'lodash'
import { DateUtil, Pagination, formatMoney, moment } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ButtonContainer,
  ListItemText,
  MenuButton,
  MenuText,
  TableText,
} from '../../styles'
import { IDataTable } from '../../type'
import { IFormData, IFormRule } from './type'

export const useTable = ({
  reloadTable,
  setReloadTable,
}: {
  reloadTable: number
  setReloadTable: React.Dispatch<React.SetStateAction<number>>
}) => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState(initPagination)

  const [filter, setFilter] = useState<IFilterGetExportBill>({})

  const { flattenDataList } = usePagination()
  const [selectedServices, setSelectedServices] = useState<IService[]>([])
  const [usedServices, setUsedServices] = useState<IService[]>([])
  const filterDebounce = useDebounce<IFilterGetExportBill>(filter, 500)

  const [form] = BaseForm.useForm()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [taxCode, setTaxCode] = useState('')
  const [paymentId, setPaymentId] = useState('')
  const [customerInfo, setCustomerInfo] = useState<IPatient>({})
  const {
    data: dataInfinity,
    isLoading: isLoadingTable,
    refetch: refetchData,
  } = useGetDataExportBill({
    keyword: filterDebounce?.keyword ?? '',
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
    fromDate: filterDebounce?.fromDate,
    toDate: filterDebounce?.toDate,
    clinicIds: filterDebounce?.clinicIds,
    clinicLegalNameIds: filterDebounce?.clinicLegalNameIds,
    status: EBillStatus?.NEED_UPDATE,
  })

  const updateStatus = async ({
    id,
    status,
  }: {
    id: string
    status: EBillStatus
  }) => {
    try {
      const response = await updateBillStatus({ id, status })
      if (response.msgcode === AccountingMessageCode.ExportBill.updateSuccess) {
        notificationController.success({
          message: t(R.strings.update_successfully),
        })
        refetchData()
        setReloadTable(prev => prev + 1)
      } else {
        notificationController.error({
          message: t(R.strings.error),
          description: response?.message,
        })
      }
    } catch (error) {
      notificationController.error({
        message: t(R.strings.error),
        description: t(R.strings.with_error_please_contact_with_admin),
      })
    }
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IDataTable>({
        title: t(R.strings.request_export_bill_date),
        key: 'request_export_bill_date',
        className: 'export-bill-date-column',

        render: (_, record: IGetExportBill) => {
          return (
            <TableText
              children={
                record?.createdAt
                  ? moment(record?.createdAt).format('HH:mm DD/MM/YYYY')
                  : '-'
              }
              fontWeight="medium"
              opacity="0.9"
              textAlign="center"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.payment_code),
        key: 'payment_code',
        className: 'payment-code-column',
        render: (_, record: IGetExportBill) => {
          return (
            <BaseCol>
              {record?.paymentDetails?.map((i, index) => (
                <ListItemText
                  $marginTop={index !== 0 ? 8 : 0}
                  key={`${index}`}
                  children={`${i?.code}`}
                  fontWeight="medium"
                  opacity="0.9"
                  textAlign="center"
                />
              ))}
            </BaseCol>
          )
        },
      }),
      {
        title: t(R.strings.customer_info),
        children: [
          convertColumnTable<IDataTable>({
            title: t(R.strings.full_name),
            key: 'full_name',
            className: 'patient-name-column',
            render: (_, record: IGetExportBill) => {
              return (
                <RenderValueTable
                  value={
                    record?.paymentDetails
                      ? record?.paymentDetails[0]?.patientName
                      : '-'
                  }
                  type="OtherLink"
                  hrefOtherLink={getDetailPatientUrl(
                    record?.paymentDetails
                      ? record?.paymentDetails[0]?.patientId
                      : '',
                  )}
                  styleText={{ fontSize: 14 }}
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings._patient_code),
            key: '_patient_code',
            className: 'patient-code-column',

            render: (_, record: IGetExportBill) => {
              return (
                <TableText
                  children={
                    record?.paymentDetails
                      ? record?.paymentDetails[0]?.patientCode
                      : '-'
                  }
                  fontWeight="medium"
                  opacity="0.9"
                  textAlign="center"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.branch),
            key: 'branch',
            className: 'branch-column',
            render: (_, record: IGetExportBill) => {
              return (
                <BaseCol>
                  {uniqBy(record?.paymentDetails, 'clinicName')?.map(
                    (i, index) => (
                      <ListItemText
                        $marginTop={index !== 0 ? 8 : 0}
                        key={`${index}`}
                        children={`${i?.clinicName}`}
                        fontWeight="medium"
                        opacity="0.9"
                      />
                    ),
                  )}
                </BaseCol>
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.company_by_branch),
            key: 'company_by_branch',
            className: 'company-by-branch-column',
            render: (_, record: IGetExportBill) => {
              return (
                <BaseCol>
                  {uniqBy(record?.paymentDetails, 'clinicLegalName')?.map(
                    (i, index) => (
                      <ListItemText
                        $marginTop={index !== 0 ? 8 : 0}
                        key={`${index}`}
                        children={`${i?.clinicLegalName}`}
                        fontWeight="medium"
                        opacity="0.9"
                      />
                    ),
                  )}
                </BaseCol>
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.address),
            key: 'address',
            className: 'address-column',
            render: (_, record: IGetExportBill) => {
              return (
                <BaseCol>
                  {uniqBy(record?.paymentDetails, 'patientAddress')?.map(
                    (i, index) => (
                      <ListItemText
                        $marginTop={index !== 0 ? 8 : 0}
                        key={`${index}`}
                        children={`${i?.patientAddress}`}
                        fontWeight="medium"
                        opacity="0.9"
                      />
                    ),
                  )}
                </BaseCol>
              )
            },
          }),
        ],
      },
      {
        title: t(R.strings.export_bill_info),
        children: [
          convertColumnTable<IDataTable>({
            title: t(R.strings.service_use),
            key: 'service_use',
            className: 'service-used-column',
            render: (_, record: IGetExportBill) => {
              return (
                <BaseCol>
                  {record?.usedServices?.map((i, index) => (
                    <ListItemText
                      $marginTop={index !== 0 ? 8 : 0}
                      key={`${index}`}
                      children={`- ${i?.name}`}
                      fontWeight="medium"
                      opacity="0.9"
                    />
                  ))}
                </BaseCol>
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.service_use_date),
            key: 'service_use_date',
            className: 'date-column',
            render: (_, record: IGetExportBill) => {
              const createdAtList = uniq(
                record?.paymentDetails?.map(i => i?.createdAt),
              )
              return (
                <BaseCol>
                  {createdAtList?.map((i, index) => (
                    <ListItemText
                      $marginTop={index !== 0 ? 8 : 0}
                      key={`${index}`}
                      children={i ? DateUtil.formatDDMMYYY(i) : ''}
                      fontWeight="medium"
                      opacity="0.9"
                      textAlign="center"
                    />
                  ))}
                </BaseCol>
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.total_amount_receivable),
            key: 'total_amount_receivable',
            className: 'money-column',
            render: (_, record: IGetExportBill) => {
              const price =
                record?.usedServices?.reduce(
                  (sum, item) => (item?.total ? sum + item?.total : sum),
                  0,
                ) || 0
              return (
                <TableText
                  children={price > 0 ? formatMoney(price, 'đ') : '0đ'}
                  fontWeight="medium"
                  opacity="0.9"
                  textAlign="right"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.tax_code),
            key: 'tax_code',
            className: 'tax-code-column',
            render: (_, record: IGetExportBill) => {
              return (
                <TableText
                  children={record?.taxId}
                  fontWeight="medium"
                  opacity="0.9"
                  textAlign="center"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.company_name_customer_name),
            key: 'company_name_customer_name',
            className: 'company-name-column',
            render: (_, record: IGetExportBill) => {
              return (
                <TableText
                  children={record?.companyName}
                  fontWeight="medium"
                  opacity="0.9"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.address),
            key: 'address_customer',
            className: 'address-column',
            render: (_, record: IGetExportBill) => {
              return (
                <TableText
                  children={record?.address}
                  fontWeight="medium"
                  opacity="0.9"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.email_receive_bill),
            key: 'email_receive_bill',
            className: 'email-column',
            render: (_, record: IGetExportBill) => {
              return (
                <TableText
                  children={record?.email}
                  fontWeight="medium"
                  opacity="0.9"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.service_request_export_bill),
            key: 'service_request_export_bill',
            className: 'service-used-column',
            render: (_, record: IGetExportBill) => {
              return (
                <BaseCol>
                  {record?.selectedServices?.map((i, index) => (
                    <TableText
                      key={`${index}`}
                      children={`- ${i?.name}`}
                      fontWeight="medium"
                      opacity="0.9"
                    />
                  ))}
                </BaseCol>
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.total_amount_export_bill),
            key: 'total_amount_export_bill',
            className: 'money-column',
            render: (_, record: IGetExportBill) => {
              const price =
                record?.selectedServices?.reduce(
                  (sum, item) => (item?.price ? sum + item?.price : sum),
                  0,
                ) || 0
              return (
                <TableText
                  children={price > 0 ? formatMoney(price, 'đ') : '0đ'}
                  fontWeight="medium"
                  opacity="0.9"
                  textAlign="right"
                />
              )
            },
          }),
        ],
      },
      convertColumnTable<IDataTable>({
        key: 'more',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record: IGetExportBill) => {
          const onCompleteClick = async () => {
            updateStatus({
              id: record?._id || '',
              status: EBillStatus.COMPLETED,
            })
          }

          const onCancelClick = async () => {
            updateStatus({
              id: record?._id || '',
              status: EBillStatus.CANCELLED,
            })
          }

          const onUpdateClick = () => {
            setPaymentId(record?._id || '')
            setTaxCode(record?.taxId || '')
            form?.setFieldValue('taxId', record?.taxId)
            form?.setFieldValue('companyName', record?.companyName)
            form?.setFieldValue('address', record?.address)
            form?.setFieldValue('email', record?.email)
            setUsedServices(record?.selectedServices || [])
            setSelectedServices(record?.selectedServices || [])
            showModal()
          }

          return (
            <BasePopover
              placement="bottomLeft"
              trigger="click"
              content={
                <BaseSpace>
                  <MenuButton onClick={onCompleteClick}>
                    <BaseRow align={'middle'}>
                      <SvgCircleCheckIcon width={20} height={20} />
                      <MenuText
                        children={t(R.strings.complete)}
                        fontSize="xs"
                        fontWeight="medium"
                        opacity="0.7"
                      />
                    </BaseRow>
                  </MenuButton>
                  <MenuButton onClick={onUpdateClick}>
                    <BaseRow align={'middle'}>
                      <SvgEditIcon width={20} height={20} />
                      <MenuText
                        children={t(R.strings.update)}
                        fontSize="xs"
                        fontWeight="medium"
                        opacity="0.7"
                      />
                    </BaseRow>
                  </MenuButton>
                  <MenuButton onClick={onCancelClick}>
                    <BaseRow align={'middle'}>
                      <SvgTrashIcon width={20} height={20} />
                      <MenuText
                        children={t(R.strings.cancel)}
                        fontSize="xs"
                        fontWeight="medium"
                        opacity="0.7"
                      />
                    </BaseRow>
                  </MenuButton>
                </BaseSpace>
              }
            >
              <ButtonContainer>
                <SvgMoreIcon />
              </ButtonContainer>
            </BasePopover>
          )
        },
      }),
    ]
    return option
  }, [t])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const handleChangeFilter = (newFilter: IFilterGetExportBill) => {
    setFilter(newFilter)
    setPagination({ ...pagination, current: 1 })
  }

  const dataFlatten = useMemo(() => {
    const res = flattenDataList(dataInfinity)
    return res
  }, [dataInfinity])

  const data = useMemo(() => {
    return dataFlatten?.data?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      } as IDataTable
    })
  }, [dataFlatten])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const onTaxCodeChange = (e: any) => {
    setTaxCode(e?.target?.value)
  }
  const onCheckAllPress = (e: CheckboxChangeEvent) => {
    const checked = e?.target?.checked //  checkbox = true

    if (checked) {
      setSelectedServices(usedServices)
    } else {
      setSelectedServices([])
    }
  }
  const onCheckBoxPress = (e: CheckboxChangeEvent, item: IService) => {
    const checked = e?.target?.checked // checkbox = true

    if (checked) {
      setSelectedServices([...selectedServices, item])
    } else {
      setSelectedServices(selectedServices?.filter(el => el?._id !== item?._id))
    }
  }

  useEffect(() => {
    if (reloadTable > 0) {
      refetchData()
    }
  }, [reloadTable])

  const handleSubmit = async (values: IFormData) => {
    setIsLoading(true)
    try {
      const body: IPayloadUpdateExportBill = {
        taxId: values?.taxId || '',
        companyName: values?.companyName || '',
        address: values?.address || '',
        email: values?.email || '',
        selectedServices:
          selectedServices?.map(
            i =>
              ({
                treatmentId: i?._id,
                treatmentGroupId: i?.treatmentGroupId,
                paymentId: i?.paymentId,
              } as IService),
          ) || [],
        status: EBillStatus?.PENDING,
      }

      const response = await updateBill({ id: paymentId, body })

      if (
        isEqual(
          response?.msgcode,
          AccountingMessageCode.ExportBill.createSuccess,
        )
      ) {
        setIsLoading(false)
        handleCancel()
        notificationController.success({
          message: t(R.strings.update_successfully),
        })
        setReloadTable(prev => prev + 1)
      } else {
        notificationController.error({
          message: t(R.strings.error),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const getInfoFn = async () => {
    try {
      if (!taxCode) {
        form?.setFieldValue('companyName', '')
        form?.setFieldValue('address', '')
        return
      }

      setIsLoading(true)
      const response = await requestGetInfoByTaxCode(taxCode)
      if (response?.data) {
        setCustomerInfo(response?.data)
        form?.setFieldValue('companyName', response?.data?.name || '')
        form?.setFieldValue('address', response?.data?.address || '')
      }
    } catch (error) {
      //error
    } finally {
      setIsLoading(false)
    }
  }

  const onTaxCodeBlur = () => {
    getInfoFn()
  }

  const rules: IFormRule = useMemo(() => {
    return {
      taxCode: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.tax_id),
          }),
        },
      ],
      customerName: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.company_name_customer_name),
          }),
        },
      ],
      address: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.address),
          }),
        },
      ],
      email: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.email),
          }),
        },
      ],
    }
  }, [t])

  return {
    data,
    columns,
    filter,
    isLoading,
    setIsLoading,
    handleTableChange,
    pagination: {
      ...pagination,
      total: dataFlatten?.total ?? 0,
    },
    handleChangeFilter,
    form,
    visible,
    t,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    taxCode,
    onTaxCodeChange,
    customerInfo,
    selectedServices,
    onCheckBoxPress,
    onCheckAllPress,
    usedServices,
    isLoadingTable,
    onTaxCodeBlur,
  }
}
