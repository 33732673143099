import { IWorkInfoUserInfo } from 'app/api/profile/model/profile'
import { SvgBuildingIcon } from 'app/assets/svg-assets'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import {
  DateUtil,
  FONT_SIZE,
  FONT_WEIGHT,
  FORMAT_DD_MM_YYYY,
} from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

import styled from 'styled-components'

interface IProps {
  data: IWorkInfoUserInfo[]
}
export const WorkInfoItem = (props: IProps) => {
  const { data } = props

  const { t } = useTranslation()

  return (
    <ContainerWrapper>
      <JobTitle>{data[0]?.jobtitle?.name || data[0]?.jobTitle?.name}</JobTitle>
      {data?.map((item, index) => {
        const expiredDate = `${DateUtil.formatDate(
          item?.startdate || '',
          FORMAT_DD_MM_YYYY,
        )} - ${DateUtil.formatDate(item?.enddate || '', FORMAT_DD_MM_YYYY)}`
        const expiredDateNoEnd = `${DateUtil.formatDate(
          item?.startdate || '',
          FORMAT_DD_MM_YYYY,
        )} - ${t(R.strings.no_expired_date)}`
        return (
          <Unit $lastItem={index === data?.length - 1} align={'middle'}>
            <SvgBuildingIcon />
            <UnitText children={item?.unit?.name} />
            {item?.enddate ? (
              <DateText children={expiredDate} />
            ) : (
              <DateText children={expiredDateNoEnd} />
            )}
          </Unit>
        )
      })}
    </ContainerWrapper>
  )
}

const ContainerWrapper = styled.div``
const JobTitle = styled.div`
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.semibold}!important;
  color: #216ae2;
  background: var(--States-Blue-Light, #eff6ff);
  padding: 16px;
  border-radius: 8px 8px 0px 0px;
`
const UnitText = styled(BaseText)`
  flex: 1;
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.medium}!important;
  color: #6d7580;
  margin-left: 8px;
`
const DateText = styled(BaseText)`
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.regular}!important;
  color: #6d7580;
  margin-left: 8px;
`
const Unit = styled(BaseRow)<{ $lastItem?: boolean }>`
  background: var(--States-Blue-Light, #fcfcfd);
  padding: 16px;
  border-right: 0.5px solid #ebeef2;
  border-bottom: 0.5px solid #ebeef2;
  border-left: 0.5px solid #ebeef2;
  ${({ $lastItem }) => {
    if ($lastItem) {
      return `border-radius: 0px 0px 8px 8px;`
    }
  }}
`
