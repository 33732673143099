import { DeleteOutlined, RedoOutlined } from '@ant-design/icons'
import { BaseButton } from 'app/components/common/BaseButton'
import { Button } from 'app/components/common/BaseButton/styles'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputPassword } from 'app/components/common/inputs/InputPassword'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { BaseSelectSearch } from 'app/components/common/selects/SelectSearch'
import { BaseTableWrapper } from 'app/components/tables/BaseTableReport/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const BaseTableManagementWrapper = styled(BaseTableWrapper)`
  .ant-table-thead > tr > th {
    border: 0.5px solid var(--neutral-black-2-color) !important;
    text-align: left !important;
    font-size: 12px !important;
    font-weight: ${FONT_WEIGHT.bold};
  }
  & .ant-pagination-item {
    border-radius: ${BORDER_RADIUS};
    min-width: 1.75rem;
    height: 1.75rem;
    line-height: 1.75rem;
  }
`

export const BaseWrapper = styled.div`
  background-color: var(--states-orange-color);
  color: var(--background-color);
`

export const BaseActionWrapper = styled.div`
  padding: 0px ${PADDING.md} 300px ${PADDING.md};
  display: flex;
  gap: 1rem;
  flex-direction: column;
`

export const ButtonCreateAction = styled(BaseButton)`
  background-color: var(--states-orange-color);
  color: var(--background-color);
  transition: all 0.5s ease;
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: var(--background-color);
    color: var(--states-orange-color);
    border-color: var(--states-orange-color);
    transition: all 0.5s ease;
  }
`

export const ButtonActionGroup = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`

export const ListWorkInfo = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`

export const HeaderWorkInfo = styled.div`
  gap: 10px;
  display: flex;
  justify-content: space-between;
`

export const WrapperWorkInfo = styled.div`
  background-color: #f9fafb;
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
`

export const WrapperButton = styled(Button)`
  margin-bottom: 1rem;
  margin-top: 1rem;
`

export const ButtonSubmit = styled(BaseButton)`
  min-width: 120px;
  background-color: var(--other-blue-color);
  color: var(--background-color);
  border-color: var(--other-blue-color);
  &:hover {
    color: var(--background-color) !important;
    border-color: var(--other-blue-color);
  }
`
export const ButtonCancel = styled(BaseButton)`
  min-width: 120px;
`

export const BaseFormWrapper = styled(BaseForm)`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`

export const ButtonStyled = styled.a`
  color: var(--states-red-color);
  width: 32px;
  height: 32px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const RefreshButton = styled(RedoOutlined)`
  width: 32px;
  height: 32px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LabelWrapper = styled(BaseRow)`
  color: var(--main-2-color);
`

export const RequireDot = styled.div`
  color: var(--states-red-color);
`
export const DashLine = styled(BaseDivider)``

export const TitleForm = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.bold};
`

export const TitleFormList = styled.h3`
  margin-bottom: 1rem;
`

export const FormItem = styled(BaseForm.Item)``

export const FormInput = styled(BaseInput)``

export const FormInputPassword = styled(InputPassword)``

export const SelectBaseStyled = styled(BaseSelect)``

export const SelectSearchBaseStyled = styled(BaseSelectSearch)``

export const DegreeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const BtnCustom = styled(Button)`
  justify-content: flex-start;
`

export const DatePicker = styled(BaseDatePicker)`
  min-width: 160px;
`
export const DeleteButton = styled(DeleteOutlined)`
  color: var(--states-red-color);
  width: 32px;
  height: 32px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CollapseWrapper = styled(BaseCollapse)`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};

  .ant-collapse-item {
    border-bottom: 0px;
  }

  .ant-collapse-content {
    border-top: 1px solid ${convertedVariables.neutralBlack9Color};
    background-color: var(--white);
    .ant-collapse-content-box {
      padding: 0px !important;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;

      .ant-space-item {
        .ant-row {
          margin: 0 !important;
        }
      }
    }
    padding: 24px 0;
  }

  .ant-collapse-header {
    padding: 16px 20px !important;
    align-items: center !important;
    background-color: var(--white);
    border-radius: 8px !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .header-content {
    padding: ${PADDING.md};
    background-color: #f9fafb;
  }

  .list-item-content-wrapper {
    padding: ${PADDING.md};
    background-color: ${convertedVariables.backgroundColor};
  }
`
export const BaseTitle = styled(BaseText)`
  font-size: ${FONT_SIZE.md}!important;
  font-weight: ${FONT_WEIGHT.bold}!important;
`
export const BaseSelectWrapper = styled(BaseSelect)`
  width: 405px !important;
  height: 32px !important;

  .ant-select-selector {
    .ant-select-selection-placeholder {
      font-size: 14px !important;
      font-weight: ${FONT_WEIGHT.medium}!important;
    }
  }
`

export const LeftCol = styled(BaseCol)``

export const Picker = styled(BaseDatePicker)`
  width: 100%;

  &:hover {
    border: 1px solid ${convertedVariables.neutralBlack9Color};
  }

  .ant-picker-input > input {
    font-size: 14px;
    font-weight: ${FONT_WEIGHT.regular};
    color: ${convertedVariables.primaryColor};
  }
`
