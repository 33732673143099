import R from 'app/assets/R'
import { SvgBriefcaseIcon, SvgBuildingIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { IRefModal } from 'app/components/common/ModalComponent'
import { IWorkInfo } from 'app/model/user.model'
import moment from 'moment'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useModalHook } from './hook'
import {
  BorderCol,
  ContentContainer,
  Dot,
  ExpiredDate,
  JobTitleCol,
  JobTitleText,
  Option,
  OptionText,
  PromotionModal,
  TitleText,
  UnitContainer,
  UnitText,
  VerticalDivider,
} from './styles'

export const PromotionProcessModal = () => {
  const { t } = useTranslation()
  const {
    data,
    columns,
    pagination,
    handleTableChange,
    visible,
    showModal,
    handleCancel,
  } = useModalHook()

  const modalRef = useRef<IRefModal>(null)
  const onClick = () => {
    modalRef?.current?.open?.()
  }
  const renderJobTitle = (item: IWorkInfo, index) => {
    const expiredDate = item?.enddate
      ? `${moment(item?.startdate)?.format('DD/MM/YYYY')} - ${moment(
          item?.enddate,
        )?.format('DD/MM/YYYY')}`
      : `${moment(item?.startdate)?.format('DD/MM/YYYY')} - ${t(
          R.strings.no_expired_date,
        )}`

    return (
      <BaseRow key={`jobtitle-${index}`}>
        <BaseCol>
          <ExpiredDate children={expiredDate} />
        </BaseCol>
        <BaseCol>
          <UnitContainer>
            <JobTitleText children={item?.jobtitle?.name} />
            <BaseRow align={'middle'}>
              <SvgBuildingIcon />
              <UnitText children={item?.unit?.name} />
            </BaseRow>
          </UnitContainer>
        </BaseCol>
      </BaseRow>
    )
  }
  const renderProcess = (item, index) => {
    const color = index === 0 ? '#629DAA' : '#858C94'
    return (
      <BaseRow key={`process-${index}`}>
        <BorderCol>
          <Dot color={color} />
          <VerticalDivider color={color} />
        </BorderCol>
        <BaseCol>
          <JobTitleCol size={20}>{item?.data?.map(renderJobTitle)}</JobTitleCol>
        </BaseCol>
      </BaseRow>
    )
  }
  return (
    <>
      <Option onClick={showModal}>
        <SvgBriefcaseIcon />
        <OptionText
          children={t(R.strings.process_promotion)}
          fontWeight="medium"
          opacity="0.7"
        />
      </Option>
      <PromotionModal
        open={visible}
        width={1100}
        footer={<></>}
        onCancel={handleCancel}
      >
        <TitleText children={t(R.strings.process_promotion)} />
        <ContentContainer>{data?.map(renderProcess)}</ContentContainer>
      </PromotionModal>
    </>
  )
}
