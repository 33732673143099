import R from 'app/assets/R'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import HeaderPage from 'app/components/header/components/HeaderPage'
import styled from 'styled-components'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { PADDING } from 'parkway-web-common'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseFormItem } from 'app/components/common/forms/components/BaseFormItem'
import { useHook } from './hook'
import { FormContent, FormInfo } from '../components'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseButton } from 'app/components/common/BaseButton'

const UpdateItemPriceContainer = () => {
  const { t } = useTranslation()
  const { form, handleSubmit, isLoadingSubmit, submit, onBack } = useHook()

  const { user, isHavePermissionFunctionAndGoBack } =
    useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.ITEM_PRICE_UPDATE,
      R.strings.update_item_price,
    )
  }, [user])

  return (
    <S.BaseManagementWrapper>
      <BaseForm form={form} onFinish={handleSubmit}>
        <BaseFormItem hiddenLabel noStyle>
          <HeaderPage
            title={''}
            rightComponent={
              <BaseRow gutter={[8, 16]}>
                <BaseCol>
                  <ButtonCancel onClick={onBack}>
                    {t(R.strings.cancel)}
                  </ButtonCancel>
                </BaseCol>

                <BaseCol>
                  <ButtonSubmit onClick={submit} loading={isLoadingSubmit}>
                    {t(R.strings.update)}
                  </ButtonSubmit>
                </BaseCol>
              </BaseRow>
            }
          />
        </BaseFormItem>
        <RootContentWrapper>
          <FormInfo />
          <FormContent form={form} />
        </RootContentWrapper>
      </BaseForm>
    </S.BaseManagementWrapper>
  )
}

export default UpdateItemPriceContainer

const RootContentWrapper = styled(BaseSpace)`
  padding-bottom: ${PADDING.md};
  position: relative;
`

export const ButtonCancel = styled(BaseButton)`
  min-width: 120px;
`

const ButtonSubmit = styled(BaseButton)`
  min-width: 120px;
  background-color: var(--other-blue-color);
  color: var(--background-color);
  border-color: var(--other-blue-color);
  &:hover {
    color: var(--background-color) !important;
    border-color: var(--other-blue-color);
  }
`
