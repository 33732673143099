import { useTranslation } from 'react-i18next'
import { IFilterDataTableReport } from '../../type'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { DefineItemPriceStatus } from 'app/api/item-price/constant'
import { StatusEnum } from 'app/common/enum'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useMemo } from 'react'
import { BaseButton } from 'app/components/common/BaseButton'
import { useNavigate } from 'react-router'
import { MARKETING_PRICING_MANAGEMENT_CREATE_ITEM_PRICE_PATH } from 'app/components/router/route-path'

interface IProps {
  filter: IFilterDataTableReport
  onChangeFilter?: (newFilter: IFilterDataTableReport) => void
}

export const FilterLayout = ({
  filter,
  onChangeFilter,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onChangeKeyword = (keyword?: string): void => {
    onChangeFilter?.({
      ...filter,
      keyword,
    })
  }

  const onCreatePriceItem = () => {
    navigate(MARKETING_PRICING_MANAGEMENT_CREATE_ITEM_PRICE_PATH)
  }

  const { user, isHavePermissionFunction } = useVerifyPermissionAndRedirect()

  const isHavePermission = useMemo(() => {
    const isHavePermissionItemPriceCreate = isHavePermissionFunction(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.ITEM_PRICE_CREATE,
    )

    return {
      isHavePermissionItemPriceCreate,
    }
  }, [user])

  const onChangeStatus = (status: StatusEnum): void => {
    onChangeFilter?.({
      ...filter,
      status,
    })
  }

  return (
    <BaseForm>
      <RootWrapper align={'middle'} justify={'space-between'}>
        <BaseCol xl={11}>
          <BaseRow gutter={[16, 16]} align={'middle'}>
            <BaseCol xl={14}>
              <BaseFormItem hiddenLabel>
                <BaseInput
                  prefix={<SearchOutlined rev={undefined} />}
                  value={filter?.keyword}
                  placeholder={t(R.strings.enter_keyword_search)}
                  onChange={e => onChangeKeyword(e?.target?.value)}
                  allowClear
                />
              </BaseFormItem>
            </BaseCol>
            <BaseCol xl={10}>
              <BaseFormItem hiddenLabel>
                <BaseSelectWrapper
                  allowClear
                  $prefix={t(R.strings.status)}
                  value={filter?.status}
                  placeholder={t(R.strings.all)}
                  options={Object.entries(DefineItemPriceStatus).map(
                    ([key, value]) => ({
                      label: t(value.keyI18n),
                      value: Number(key),
                    }),
                  )}
                  maxTagCount={0}
                  $prefixWidth={80}
                  onChange={value => onChangeStatus(value as StatusEnum)}
                />
              </BaseFormItem>
            </BaseCol>
          </BaseRow>
        </BaseCol>

        <BaseCol>
          <BaseRow align={'middle'}>
            <BaseCol xl={24}>
              {isHavePermission.isHavePermissionItemPriceCreate && (
                <ButtonCreateAction onClick={onCreatePriceItem}>
                  <BaseRow gutter={8}>
                    <BaseCol>
                      <PlusOutlined rev={undefined} />
                    </BaseCol>
                    <BaseCol>{t(R.strings.add_item_price)}</BaseCol>
                  </BaseRow>
                </ButtonCreateAction>
              )}
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseRow)`
  width: 100%;
  padding-top: ${PADDING.xxxxs};
`
const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
const BaseSelectWrapper = styled(BaseSelect)<{
  $prefix: string
  $prefixWidth?: number
}>`
  width: 100%;
  .ant-select-selector::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    width: ${props => props?.$prefixWidth ?? 80}px;
    align-items: center;
    display: flex;
  }

  .ant-select-selection-placeholder {
    padding-left: ${props =>
      props.$prefixWidth ? `${props?.$prefixWidth}px` : 0};
  }
`

const ButtonCreateAction = styled(BaseButton)`
  background: var(--orange-gradient-color);
  color: var(--background-color);
  transition: all 0.5s ease;
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: var(--orange-gradient-color);
    color: var(--text-sider-secondary-color);
    border-color: var(--states-orange-color);
    transition: all 0.5s ease;
  }
`
