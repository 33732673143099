/* eslint-disable no-useless-escape */
import R from 'app/assets/R'
import { ENV_CONFIG } from 'app/common/config'
import { GenderEnum, Pagination, Priority } from 'parkway-web-common'

export type CategoryType = 'apps'

interface Category {
  name: CategoryType
  title: string
}

export const categoriesList: Category[] = [
  {
    name: 'apps',
    title: 'common',
  },
]

export interface Component {
  name: string
  title: string
  url: string
  categories: CategoryType[]
  keywords: string[]
}

export const configComponents: Component[] = [
  {
    name: 'Home',
    title: 'medical-dashboard.protein',
    url: `/home`,
    categories: ['apps'],
    keywords: ['home', 'apps', 'statistics'],
  },
]

export const defineColorByPriority = (priority: Priority): string => {
  switch (priority) {
    case Priority.INFO:
      return 'var(--primary-color)'
    case Priority.LOW:
      return 'var(--success-color)'
    case Priority.MEDIUM:
      return 'var(--warning-color)'
    case Priority.HIGH:
      return 'var(--error-color)'
    default:
      return 'var(--success-color)'
  }
}

export const URL_DOWNLOAD_EXAMPLE = {
  MarketingMonthPlan:
    'https://static.nhakhoaparkway.com/templates/Mau_import_moi_marketing_plan_ver_01.xlsx',
  MarketingWeekPlan:
    'https://static.nhakhoaparkway.com/templates/Mau_import_moi_marketing_plan_ver_01.xlsx',
  CostMonthPlan:
    'https://static.nhakhoaparkway.com/templates/CostMonthPlan.xlsx',
  CostQuarterPlan:
    'https://static.nhakhoaparkway.com/templates/CostQuarterPlan.xlsx',
  PNLMonthPlan:
    'https://static.nhakhoaparkway.com/templates/CostMonthPlan.xlsx',
  PNLWeekPlan: 'https://static.nhakhoaparkway.com/templates/CostWeekPlan.xlsx',
  CustomerCarePlan:
    'https://static.nhakhoaparkway.com/templates/file_mau_import_ke_hoach_cham_soc_khach_hang.xlsx',
  CheckInPlan:
    'https://static.nhakhoaparkway.com/templates/mau_import_check_in_plan_customer.xlsx',
  CheckInServicePlan:
    'https://static.nhakhoaparkway.com/templates/mau_import_check_in_theo_dich_vu_va_khu_vuc.xlsx',
}

export enum TreatmentGroupEnum {
  NIEU_RANG_INVISALIGN = 'NIỀNG RĂNG INVISALIGN',
  NIEU_RANG_MAC_CAI = 'NIỀNG RĂNG MẮC CÀI',
  CHINH_NHA_ZENYUM = 'CHỈNH NHA ZENYUM',
  NHA_TRE_EM = 'NHA TRẺ EM',
  CHINH_NHA_CLEARCORREC = 'CHỈNH NHA CLEARCORRECT',
  CHINH_NHA_DU_PHONG = 'CHỈNH NHA DỰ PHÒNG',
  TRONG_RANG_IMPLANT = 'TRỒNG RĂNG IMPLANT',
  KHI_CU_HO_TRO_CHINH_NHA = 'KHÍ CỤ HỖ TRỢ CHỈNH NHA',
  DIEU_TRI_LOI_NHA_CHU = 'ĐIỀU TRỊ LỢI, NHA CHU',
  HAN_TRAM_RANG = 'HÀN TRÁM RĂNG',
  DIEU_TRI_TUY = 'ĐIỀU TRỊ TỦY',
  KHAM_TU_VAN = 'KHÁM, TƯ VẤN',
  RANG_GIA_THAO_LAP = 'RĂNG GIẢ THÁO LẮP',
  HANG_HOA_SAN_PHAM = 'HÀNG HOÁ SẢN PHẨM',
  NHO_RANG = 'NHỔ RĂNG',
  RANG_SU_THAM_MY = 'RĂNG SỨ THẨM MỸ',
  PHUC_HINH_RANG = 'PHỤC HÌNH RĂNG',
  DICH_VU_KHAC = 'DỊCH VỤ KHÁC',
}

export const FORMAT_DD_MM_YYYY_HH_MM_SS = 'DD/MM/YYYY HH:mm:ss'
export const FORMAT_DD_MM_YYYY_HH_MM = 'DD/MM/YYYY HH:mm'
export const FORMAT_HH_MM = 'HH:mm'

export const ListTotal = [
  {
    background: '#ECFDF2',
    border: '#28BA59',
    label: 'Lịch hẹn',
    total: 0,
    status: 2,
  },
  {
    background: '#EFF6FF',
    border: '#5585F4',
    label: 'Đã đến',
    total: 0,
    status: 4,
  },
  {
    background: '#E8F6F9',
    border: '#629DAA',
    label: 'Đang thực hiện',
    total: 0,
    status: 5,
  },
  {
    background: '#FEF2F2',
    border: '#EA2727',
    label: 'Chờ lâu',
    total: 0,
    status: 6,
  },
  {
    background: '#FFFBED',
    border: '#FA9F34',
    label: 'Chưa tới',
    total: 0,
    status: 3,
  },
  {
    background: '#FDE9FF',
    border: '#FD44B3',
    label: 'Hủy lịch hẹn',
    total: 0,
    status: 7,
  },
]

export const AppointmentStatusDone = {
  background: '#F3F4F6',
  border: '#DADEE3',
  status: 8,
  label: 'Đã hoàn thành',
  total: 0,
}

export const AppointmentStatusCancel = {
  background: '#FDE9FF',
  border: '#FD44B3',
  label: 'Hủy lịch hẹn',
  total: 0,
  status: 7,
}

export const ListAppointmentStatus = [
  {
    label: 'Lịch hẹn',
    status: 2,
  },
  {
    label: 'Chưa tới',
    status: 3,
  },
  {
    label: 'Đã đến',
    status: 4,
  },
  {
    label: 'Đang thực hiện',
    status: 5,
  },
  {
    label: 'Đã hoàn thành',
    status: 8,
  },
]

export const ListBookingStatus = [
  {
    label: R.strings.confirm,
    status: 3,
    background: '#ECFDF2',
    border: '#28BA59',
  },
  {
    label: R.strings.recall,
    status: 6,
    background: '#FEF2F2',
    border: '#FA9F34',
  },
  {
    label: R.strings.created_new,
    status: 2,
    background: '#F8F8F8',
    border: '#002244',
  },
  {
    background: '#FDE9FF',
    border: '#FD44B3',
    label: R.strings.cancel_booking,
    status: 5,
  },
]

export const ALL_KEY = 'all'

export const firebaseConfig = {
  apiKey: ENV_CONFIG.FIREBASE_API_KEY,
  authDomain: ENV_CONFIG.FIREBASE_AUTH_DOMAIN,
  projectId: ENV_CONFIG.FIREBASE_PROJECT_ID,
  storageBucket: ENV_CONFIG.FIREBASE_STORAGE_BUDGET,
  messagingSenderId: ENV_CONFIG.FIREBASE_MESSAGE_SENDER_ID,
  appId: ENV_CONFIG.FIREBASE_APP_ID,
  measurementId: ENV_CONFIG.FIREBASE_MEASUREMENT_ID,
}

export const GET_GENDER_I18N_KEY = {
  [GenderEnum.F]: R.strings.female,
  [GenderEnum.M]: R.strings.male,
  [GenderEnum.Other]: R.strings.other,
}
export const GenderList = [
  {
    id: GenderEnum.M,
    value: R.strings.male,
  },
  {
    id: GenderEnum.F,
    value: R.strings.female,
  },
  {
    id: GenderEnum.Other,
    value: R.strings.other,
  },
]

export const _DEV_ = process.env?.NODE_ENV === 'development'

export const APPOINTMENT = 'APPOINTMENT'

export const REGEX_VOUCHER_CODE = /^[A-NP-Z1-9]+$/

export const REGEX_REMOVE_SPECIAL_CHARACTERS = /[()!@#$%]/g

export const MAX_CASE_ONE_SLOT_VOUCHER = 34

export const MAX_COUNT_VOUCHER = 20000

export const initPagination: Pagination = {
  current: 1,
  pageSize: 100,
}
