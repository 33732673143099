import { IProfileUser } from 'app/api/profile/model/profile'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { useTranslation } from 'react-i18next'
import {
  CollapseWrapper,
  ListContainer,
  SectionBaseWrapper,
  SectionDescription,
  SectionTitle,
} from './styles'
import { IDiscountVoucher } from 'app/api/marketing/discount-voucher/model'
import { VoucherCard } from '../VoucherCard'
import { PaginationRow } from '../../styles'
import { BasePagination } from 'app/components/common/BasePagination'
import { useState } from 'react'
import { SvgDownIcon } from 'app/assets/svg-assets'
import { initPagination } from 'app/constant'
import { Pagination } from 'parkway-web-common'

interface IProps {
  data: IDiscountVoucher[]
}

export const MyVoucherList = (props: IProps) => {
  const { data } = props

  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [pagination, setPagination] = useState<Pagination>(initPagination)

  const VoucherList = () => {
    const renderItem = (item: IDiscountVoucher, index: number) => {
      return (
        <VoucherCard
          key={`my-voucher-${index}`}
          data={item}
          codeTitle={t(R.strings.voucher_code)}
        />
      )
    }
    return <ListContainer>{data?.map(renderItem)}</ListContainer>
  }

  const onClick = () => {
    setOpen(prev => !prev)
  }

  return (
    <CollapseWrapper
      // defaultActiveKey={['referralList']}
      items={[
        {
          key: 'referralList',
          label: <SectionTitle>{t(R.strings.voucher)}</SectionTitle>,
          style: {
            alignItems: 'center',
          },
          onClick: onClick,
          children: (
            <>
              <VoucherList />
              <PaginationRow justify={'end'}>
                <BasePagination
                  defaultCurrent={1}
                  pageSize={pagination?.pageSize}
                  total={data?.length}
                  onChange={(page: number, pageSize?: number) => {
                    // if (page && pageSize) {
                    //   onChangePageSize(pageSize)
                    //   onChangePage(page)
                    // }
                  }}
                  pageSizeOptions={[10, 20, 50, 100]}
                />
              </PaginationRow>
            </>
          ),
        },
      ]}
      expandIconPosition="right"
      expandIcon={() => (
        <SvgDownIcon style={open ? { transform: 'rotate(180deg)' } : {}} />
      )}
    />
  )
}
